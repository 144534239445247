import { useEffect, useMemo, useState } from 'react';
import { Heading, Button, Columns, Content, Modal } from 'react-bulma-components';
import QRCode from 'qrcode.react';
import TotpInput from '../../input/totpInput';
import { axios } from '../../../services/axios';
import { logService } from '../../../services/LogService';
import Validator from '../../../services/Validator';
import ContentLoader from 'react-content-loader';
import { profileService } from '../../../services/profileService';
import SecretCodeContainer from './secretCodeContainer';
import SimpleMessages from '../../common/simple-messages/simpleMessages';

import './mfaPage.scss';
import { COMMON_ERROR_MESSAGE, errorMessageMap, ERRORS_CODE } from '../../../services/Constant';

import googlePlayBadge from '../../../assets/images/google-play-badge.png';
import appStorePlayBadge from '../../../assets/images/app-store-badge.svg';

const MfaPage = () => {
    const [setupStep, setSetupStep] = useState(0);
    const [totp, setTotp] = useState('');
    const [showSecret, setShowSecret] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingRecoveryCode, setLoadingRecoveryCode] = useState(false);
    const [errors, setErrors] = useState([]);

    const [mfaInfo, setMfaInfo] = useState();
    const mfaUpdate = (info) => setMfaInfo(info['mfa']);

    const [showModal, setShowModal] = useState();

    useEffect(() => {
        mfaUpdate(profileService.getInfo());
        profileService.registerUpdateEvent(mfaUpdate);
        return () => profileService.unregisterUpdateEvent(mfaUpdate);
    }, []);

    const callbackMfaUpdate = (updatedMfa) => profileService.updateInfo('mfa', updatedMfa);

    const onSetupClick = useMemo(() => () => {
        setLoading(true);
        setErrors([]);
        (async () => {
            let code, recoveryCodes;
            let error;
            try {
                const response = await axios.get('/mfa/totp/setup');
                if (response?.data) {
                    code = response.data.code;
                    recoveryCodes = response.data.recoveryCodes;
                }
            } catch (err) {
                logService.error(err);
                error = err;
            }

            if (!code) {
                setErrors([{
                    error,
                    messages: [COMMON_ERROR_MESSAGE, errorMessageMap(error?.response?.data?.error?.code)]
                }]);
            } else {
                callbackMfaUpdate({ ...mfaInfo, code, recoveryCodes });
            }
            setLoading(false);
        })();
    }, [mfaInfo]);

    const onSubmit = useMemo(() => (totp) => {
        setLoading(true);
        setErrors([]);
        (async () => {
            try {
                const response = await axios.post('/mfa/totp/verifyToken', { code: totp });
                if (response?.data) {
                    callbackMfaUpdate({ ...mfaInfo, preferred: 'TOTP' });
                    // Refresh info
                    profileService.removeInfo();
                    await profileService.loadInfo();
                } else {
                    setErrors([{ messages: ['Your TOTP is invalid.'] }]);
                }
            } catch (err) {
                if (err?.response?.data?.error?.code === ERRORS_CODE.enableSoftwareTokenMFAException.code) {
                    setErrors([{ messages: ['Your TOTP is invalid.'] }]);
                } else {
                    setErrors([{ error: err, messages: [COMMON_ERROR_MESSAGE, errorMessageMap(err?.response?.data?.error?.code)] }]);
                }
                logService.error(err);
            }
            setLoading(false);
        })();
    }, [mfaInfo]);

    const onDisableClick = useMemo(() => () => {
        setLoading(true);
        setErrors([]);
        (async () => {
            let success, error;
            try {
                const response = await axios.get('/mfa/totp/disable');
                if (response?.data?.success) {
                    success = true;
                }
            } catch (err) {
                logService.error(err);
                error = err;
            }

            if (!success) {
                setErrors([{ error, messages: [COMMON_ERROR_MESSAGE, errorMessageMap(error?.response?.data?.error?.code)] }]);
            } else {
                setSetupStep(0);
                setTotp('');
                setShowSecret(false);
                callbackMfaUpdate({ ...mfaInfo, preferred: '', code: '', recoveryCodes: undefined });
            }
            setLoading(false);
            // Refresh info
            profileService.removeInfo();
            await profileService.loadInfo();
        })();
    }, [mfaInfo]);

    const mfaRecoveryCodeDom = useMemo(() => <div className='mfaOutlineBox'>
        <Heading subtitle renderAs={'h3'} size={5}>Your recovery codes</Heading>
        <SecretCodeContainer codes={mfaInfo?.recoveryCodes || []} buttonText={'Download codes'} actionText={'Downloaded!'} isDownload={true}/>
        <p>
            Please save your recovery codes in a safe place that you can easily access, in the event you lost your TOTP-generating app.
            Recovery code is a backup method allows you to log in to your account in that case (Your MFA will be deactivated).
        </p>
    </div>, [mfaInfo]);

    const mfaSetupDom = useMemo(() => {
        if (!mfaInfo?.code) {
            return <>
                <Heading subtitle renderAs={'h2'} size={6} className={mfaInfo?.isMfaForced ? 'required' : ''}>
                    {mfaInfo?.isMfaForced ? 'Your domain administrator has made MFA authentication setup mandatory for your account. Please configure MFA now.' : 'Your MFA hasn\'t been activated yet.'}
                </Heading>
                <div className='mfaOutlineBox'>
                    <Heading subtitle renderAs={'h3'} size={5}>How does MFA work?</Heading>
                    <p>
                        You are challenged to complete authentication using a time-based one-time (TOTP) password
                        after your user name and password have been verified.
                        The UI shows a second page for you to enter the TOTP password after you submit your user name and password.
                    </p>
                    <Heading subtitle renderAs={'h3'} size={5}>If I lost my TOTP-generating app?</Heading>
                    <p>
                        Before setting up your TOTP, you receive 3 set of recovery codes.
                        When you submit your user name and password, you will have an option to use your recovery code.
                        Your MFA will be disabled after successfully verifying your code.
                    </p>
                </div>
                <SimpleMessages messageList={errors}/>
                <div>
                    <Button onClick={onSetupClick} color='info' disabled={loading} loading={loading}>Setup TOTP</Button>
                </div>
            </>;
        }

        const str = 'otpauth://totp/' + mfaInfo.email + '?secret=' + mfaInfo.code + '&issuer=OPSWAT.SSO';
        if (setupStep === 0) {
            return <>
                <Heading subtitle renderAs={'h2'} size={6}>Step 1: Save your recovery codes (you can skip this step, your codes are also available after setting up your TOTP).</Heading>
                {mfaRecoveryCodeDom}
                <div className='qrCodeContainer'>
                    <Button color='info' onClick={() => setSetupStep(1)}>Next step</Button>
                </div>
            </>;
        } else if (setupStep === 1) {
            return <>
                <Heading subtitle renderAs={'h2'} size={6}>Step 2: Scan the below QR code by using any TOTP-generating app or enter <a href='/' onClick={(e) => {
                    e.preventDefault();
                    setShowSecret(!showSecret);
                }}>this text code</a> instead.</Heading>
                <div className='qrCodeContainer'>
                    {showSecret && <SecretCodeContainer codes={[mfaInfo.code]}/>}
                    <Columns className='qrSetup' breakpoint='desktop' variableGap={{ mobile: 0, desktop: 8 }}>
                        <Columns.Column>
                            <QRCode fgColor='#3D4A68' size={160} value={str}/>
                        </Columns.Column>
                        <Columns.Column>
                            <p className='getApp'>Get Google Authenticator App</p>
                            <a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2' target='_blank' rel='noopener noreferrer'>
                                <img src={googlePlayBadge} alt='Get on Google play'/>
                            </a>
                            <a href='https://apps.apple.com/us/app/google-authenticator/id388497605?itsct=apps_box&itscg=30200' target='_blank' rel='noopener noreferrer'>
                                <img className='appStore' src={appStorePlayBadge} alt='Get on Google play'/>
                            </a>
                        </Columns.Column>
                    </Columns>
                    <Button onClick={() => setSetupStep(0)}>Back</Button>
                    <Button color='info' onClick={() => setSetupStep(2)}>Next step</Button>
                </div>
            </>;
        }
        return <>
            <Heading subtitle renderAs={'h2'} size={6}>Step 3: Input your TOTP</Heading>
            <SimpleMessages messageList={errors}/>
            <div className='qrCodeContainer'>
                <TotpInput
                    value={totp}
                    onChange={(evt) => {
                        const newTotp = evt.target.value.replace(/[\s_]/g, '');
                        setTotp(newTotp);
                        if (Validator.isValidTotp(newTotp) && newTotp !== totp) {
                            onSubmit(newTotp);
                        }
                    }}
                    disabled={loading}
                />
                <Button onClick={() => setSetupStep(1)} disabled={loading}>Back</Button>
                <Button color='info' onClick={() => onSubmit(totp)} disabled={!Validator.isValidTotp(totp) || loading} loading={loading}>Submit</Button>
            </div>
        </>;


    }, [mfaInfo, onSetupClick, loading, mfaRecoveryCodeDom, errors, setupStep, showSecret, onSubmit, totp]);

    const resetRecoveryCode = useMemo(() => () => {
        setLoadingRecoveryCode(true);
        (async () => {
            let recoveryCodes, error;
            try {
                const response = await axios.get('/mfa/totp/reset-recovery');
                recoveryCodes = response?.data?.recoveryCodes;
            } catch (err) {
                logService.error(err);
                error = err;
            }

            if (!recoveryCodes) {
                setErrors([{ error, messages: [COMMON_ERROR_MESSAGE, errorMessageMap(error?.response?.data?.error?.code)] }]);
            } else {
                callbackMfaUpdate({ ...mfaInfo, recoveryCodes });
            }
            setLoadingRecoveryCode(false);
        })();
    }, [mfaInfo]);

    const mfaPreferredDom = useMemo(() => {
        return <>
            <Heading subtitle renderAs={'h2'} size={6}>Your MFA is activated.</Heading>
            {mfaRecoveryCodeDom}
            <SimpleMessages messageList={errors}/>
            <div>
                <Button
                    onClick={() => setShowModal({
                        action: onDisableClick,
                        content: 'Are you sure you want to disable your MFA?',
                        color: 'danger'
                    })}
                    color='danger'
                    disabled={loading || loadingRecoveryCode}
                    loading={loading}
                >
                    Disable MFA
                </Button>
                <Button
                    onClick={() => setShowModal({
                        action: () => resetRecoveryCode(),
                        content: 'Are you sure you want to reset your current recovery codes?',
                        color: 'info'
                    })}
                    color='info'
                    disabled={loading || loadingRecoveryCode}
                    loading={loadingRecoveryCode}
                >
                    {!mfaInfo?.recoveryCodes ? 'Show Recovery Code' : 'Reset Recovery Code'}
                </Button>
            </div>
        </>;
    }, [mfaInfo, onDisableClick, mfaRecoveryCodeDom, errors, loading, loadingRecoveryCode, resetRecoveryCode]);

    const dom = useMemo(() => {
        if (!mfaInfo) {
            return <ContentLoader
                height={150}
                width='100%'
                speed={1}
            >
                <rect x="0" y="10" rx="10" ry="10" width={`${20 + Math.random() * 80}%`} height="20"/>
                <rect x="0" y="50" rx="10" ry="10" width={`${20 + Math.random() * 80}%`} height="20"/>
                <rect x="0" y="90" rx="10" ry="10" width={`${20 + Math.random() * 80}%`} height="20"/>
                <rect x="0" y="130" rx="10" ry="10" width={`${20 + Math.random() * 80}%`} height="20"/>
            </ContentLoader>;
        }

        if (!mfaInfo.preferred) {
            // If no MFA setting yet
            return mfaSetupDom;
        }
        return mfaPreferredDom;


    }, [mfaInfo, mfaSetupDom, mfaPreferredDom]);

    const modalConfirm = useMemo(() => {
        return <Modal className='mfaModal' show={!!showModal} closeOnBlur={true} onClose={() => setShowModal(undefined)}>
            <Content>
                <Modal.Card>
                    <Modal.Card.Head onClose={() => setShowModal(undefined)}>
                        <Modal.Card.Title>
                        </Modal.Card.Title>
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <Content>
                            {showModal?.content}
                        </Content>
                    </Modal.Card.Body>
                    <Modal.Card.Foot>
                        <Button onClick={() => {
                            showModal.action();
                            setShowModal(undefined);
                        }} color={showModal?.color}>
                            Yes
                        </Button>
                        <Button onClick={() => setShowModal(undefined)}>
                            No
                        </Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </Content>
        </Modal>;
    }, [showModal]);

    return <div className='mfaPage'>
        <Heading renderAs={'h1'} weight={'normal'}>Multi-factor authentication</Heading>
        {dom}
        {modalConfirm}
    </div>;
};

export default MfaPage;
