import { useMemo } from 'react';
import classname from 'classnames';
import SimpleMessages from '../../common/simple-messages/simpleMessages';
import HomeLayout from '../homeLayout';
import PropTypes from 'prop-types';

import './actionLayout.scss';

const ActionLayout = ({ className, children, app, errors, pageTitle, message, question }) => {
    const header = useMemo(() => {
        return <>
            {message.title && <p className='messageTitle'>
                {message.title}
            </p>}
            {message.content && <p className='messageContent'>
                {message.content}
            </p>}
        </>;

    }, [message]);

    const footer = useMemo(() => {
        if (question) {
            return <>
                <div className='questionContainer'>
                    {question}
                </div>
            </>;
        }
    }, [question]);

    return <HomeLayout className={classname('actionLayout', className)} app={app} pageTitle={pageTitle}>
        <div className='action-wrapper'>
            <SimpleMessages messageList={errors}/>
            {header}
            {children}
            {footer}
        </div>
    </HomeLayout>;
};

export default ActionLayout;

ActionLayout.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    app: PropTypes.string,
    errors: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    pageTitle: PropTypes.string,
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    question: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
};
