import { Fragment, useState, useMemo, useEffect } from 'react';
import { organizationService } from '../../../services/organizationService';
import { ROLES } from '../../../services/Constant';
import { Button, Form, Heading, Table } from 'react-bulma-components';
import className from 'classnames';
import ContentLoader from 'react-content-loader';
import SimpleModal from '../../common/simple-modal/simpleModal';
import SimpleMessages from '../../common/simple-messages/simpleMessages';
import AddEntry from '../../common/add-entry/addEntry';
import Validator from '../../../services/Validator';
import PropTypes from 'prop-types';

import './inviteTable.scss';

const LIMIT = 20;

const InviteTable = ({ orgId, userId, isAdmin }) => {

    const { Control, Input, Select } = Form;

    const [loading, setLoading] = useState({});
    const [errors, setErrors] = useState();
    const [success, setSuccess] = useState();

    const [invitingUsers, setInvitingUsers] = useState(undefined);
    const [nextInvitingUserToken, setNextInvitingUserToken] = useState(undefined);

    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [newUserInput, setNewUserInput] = useState({ email: '', role: ROLES.user.id });
    const [isValidEmail, setIsValidEmail] = useState();

    const [deleteUser, setDeleteUser] = useState(undefined);

    useEffect(() => {
        let discard = false;
        if (orgId && userId) {
            setInvitingUsers(undefined);
            setNextInvitingUserToken(undefined);
            setLoading({});

            (async () => {
                const data = await organizationService.getUsers(orgId, userId, undefined, true, LIMIT);
                if (!discard && data) {
                    setInvitingUsers(data.users);
                    setLoading(undefined);
                    setNextInvitingUserToken(data.nextPageToken);
                }
            })();
        }

        return function () {
            discard = true;
        };
    }, [userId, orgId]);

    const loadMore = (orgId, userId, token) => {
        setLoading({ loadMore: true });

        (async () => {
            const data = await organizationService.getUsers(orgId, userId, undefined, true, LIMIT, token);
            if (data) {
                setInvitingUsers((users) => (users ? users.concat(data.users) : data.users));
                setLoading(undefined);
                setNextInvitingUserToken(data.nextPageToken);
            }
        })();
    };

    const modalAddUser = useMemo(() => {
        if (!showAddUserModal) {
            return null;
        }

        const handleChange = (e) => {
            const enteredEmail = e.target.value;
            setNewUserInput({ ...newUserInput, email: enteredEmail });
            setIsValidEmail(Validator.isEmail(enteredEmail));
        };

        const addHandler = () => {
            if (isValidEmail) {
                (async () => {
                    const result = await organizationService.inviteUserToOrganization(orgId, userId, newUserInput);
                    if (result.error) {
                        // Add fail
                        setErrors(result.messages);
                    } else {
                        setSuccess([`Sent invitation to ${newUserInput.email} successfully!`]);
                        setInvitingUsers((users) => [result.data].concat(users || []));
                    }
                    setLoading(undefined);
                })();
                setShowAddUserModal(false);
                setLoading({ adding: newUserInput.email });
            }
        };

        const radios = Object.entries(ROLES).map(([key, obj]) =>
            <option key={key} value={obj.id} name={'role'}>
                {obj.label}
            </option>);

        return <AddEntry
            inputsDom={<>
                <Control className='is-half'>
                    <Input
                        className={className({ 'invalidInput': !isValidEmail && newUserInput.email })}
                        value={newUserInput.email}
                        onChange={handleChange}
                        onKeyPress={(e) => e.key === 'Enter' ? addHandler() : undefined}
                        placeholder={'user@example.com'}
                        autoFocus={true}/>
                </Control>

                <Control className='is-half'>
                    <Select onChange={(e) => setNewUserInput({ ...newUserInput, role: e.target.value })} value={newUserInput.role}>
                        {radios}
                    </Select>
                </Control>
            </>}
            buttonsDom={
                <>
                    <Button color='info' disabled={!isValidEmail} onClick={addHandler}>
                        Add
                    </Button>
                    <Button onClick={() => setShowAddUserModal(false)}>
                        Cancel
                    </Button>
                </>
            }
        />;
    }, [orgId, userId, showAddUserModal, newUserInput, isValidEmail]);

    const tableContentDom = useMemo(() => {
        let dom;
        if (invitingUsers) {
            dom = <>
                {invitingUsers.map((user, index) => <Fragment key={index}>
                    <tr>
                        <td>{`${user.firstName} ${user.lastName}`}</td>
                        <td className='hidden-sm'>{user.email}</td>
                        <td className='hidden-xs'>
                            {(Array.isArray(user.roles) ? user.roles.map((role) => Object.keys(ROLES).find((key) => ROLES[key].id === role)).filter((key) => key).map((key) => ROLES[key].label).join(', ') : '')}
                        </td>
                        <td>
                            {!loading && isAdmin && <>
                                <a href='/' className='negative floatRight' onClick={(e) => {
                                    e.preventDefault();
                                    setDeleteUser(user);
                                    setErrors();
                                    setSuccess();
                                }}>
                                    <i className='fas fa-trash'/>
                                </a>
                            </>}
                            {loading && loading.deleting === user.userId && <span className='negative floatRight'>Deleting...</span>}
                        </td>
                    </tr>
                </Fragment>)}
                {!invitingUsers.length && <tr>
                    <td colSpan={4}>
                        No pending user. Click on "Add user" button to invite new user to your organization.
                    </td>
                </tr>}
                {nextInvitingUserToken && <tr>
                    <td colSpan={4} className='loadMoreRow'>
                        <Button className='loadMore' disabled={!!loading} loading={loading?.loadMore} onClick={() => loadMore(orgId, userId, nextInvitingUserToken)}>Load more</Button>
                    </td>
                </tr>}
            </>;
        } else {
            dom = Array(5).fill('').map((v, index) => <Fragment key={index}>
                <tr>
                    <td colSpan={4}>
                        <ContentLoader height={20} width='100%' speed={1}>
                            <rect x='0' y='0' rx='10' ry='10' width={`${40 + 60 * Math.random()}%`} height='20'/>
                        </ContentLoader>
                    </td>
                </tr>
            </Fragment>);
        }
        return dom;
    }, [invitingUsers, orgId, userId, loading, isAdmin, nextInvitingUserToken]);

    const modalDeleteUser = useMemo(() => {
        const deleteHandler = () => {
            (async () => {
                const result = await organizationService.removeInviteUser(userId, deleteUser);

                if (result.error) {
                    // Update fail
                    setErrors(result.messages);
                } else {
                    // Update to remove inviting user
                    setInvitingUsers((invitingUsers) => invitingUsers && invitingUsers.filter((user) => user.userId !== deleteUser.userId));
                }
                setLoading(undefined);
            })();
            setDeleteUser(undefined);
            setLoading({ deleting: deleteUser.userId });
        };

        return <SimpleModal
            className='deleteUserModal'
            isShow={deleteUser}
            onClose={() => setDeleteUser(undefined)}
            title="Remove user's invitation"
            body={<>
                Do you want to remove <span className='highlightDelete'>{deleteUser?.email}</span> invitation?
            </>}
            footer={<>
                <Button color='danger' onClick={deleteHandler}>
                    Yes
                </Button>
                <Button onClick={() => setDeleteUser(undefined)}>
                    No
                </Button>
            </>}
        />;
    }, [deleteUser, userId]);

    const addUserDom = useMemo(() => {
        if (isAdmin) {
            const addHandler = () => {
                setNewUserInput({ email: '', role: ROLES.user.id });
                setErrors();
                setSuccess();
                setShowAddUserModal((showAddUserModal) => !showAddUserModal);
            };

            return <Button className='positive' color='info' disabled={!!loading} loading={!!loading?.adding} onClick={addHandler}>
                <i className='fas fa-plus'/> Add User
            </Button>;
        }
    }, [isAdmin, loading]);

    return <div className='inviteTable'>
        <Heading renderAs={'h1'} weight={'normal'}>Pending Invitations</Heading>
        <SimpleMessages messageList={errors || success} type={errors ? 'danger' : 'success'}/>
        <Table className='orgTable'>
            <tbody>
                <tr>
                    <td>Name</td>
                    <td className='hidden-sm'>Email</td>
                    <td className='hidden-xs'>Role</td>
                    <td className='buttonBox'>
                        {addUserDom}
                    </td>
                </tr>
                {modalAddUser}
                {tableContentDom}
            </tbody>
        </Table>
        {modalDeleteUser}
    </div>;
};

export default InviteTable;

InviteTable.propTypes = {
    orgId: PropTypes.string,
    userId: PropTypes.string,
    isAdmin: PropTypes.bool
};
