export default {
    parse: (queryString) => {
        const query = {};
        const pairs = queryString ? (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&') : [];
        pairs.forEach((pair) => {
            const split = pair.split('=');
            query[decodeURIComponent(split[0])] = decodeURIComponent(split[1] || '');
        });
        return query;
    },
    toString: (query) => {
        const queryString = Object.keys(query || {}).filter((key) => query[key]).map((key) => (key + '=' + encodeURIComponent(query[key]))).join('&');
        return queryString ? ('?' + queryString) : '';
    }
};
