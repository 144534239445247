import { useMemo } from 'react';
import { Columns, Button } from 'react-bulma-components';
import FreshestLayout from '../../components/layout/freshest-layout/freshestLayout';
import { navigate } from '@reach/router';
import img404 from '../../assets/images/404.svg';

import './404.scss';

const Page404 = () => {
    const handleClick = (e) => {
        e && e.preventDefault();
        navigate('/');
    };

    const content = useMemo(() => {
        return <Columns className='is-centered is-mobile'>
            <Columns.Column className='is-full-mobile is-three-quarters-desktop'>
                <div>
                    <img src={img404} alt='404'/>
                </div>
                <h1>The page you are looking for doesn’t exist</h1>
                <p>Please check the URL or head back to the homepage. If you can’t find what you are looking for, feel free to <a href='https://www.opswat.com/contact'>get in contact</a> with us</p>
                <Button
                    fullwidth={false}
                    color='primary'
                    onClick={handleClick}
                    tabIndex="2"
                >
                    Back home
                </Button>
            </Columns.Column>
        </Columns>;
    }, []);

    const dom = useMemo(() => {
        return <FreshestLayout
            className='unknown'
            pageTitle="OPSWAT | 404"
            userInfo={null}
            menuItems={[]}
            page={'404'}
            content={<div className="unknown--content">
                {content}
            </div>}
        />;

    }, [content]);

    return <>{dom}</>;
};

export default Page404;
