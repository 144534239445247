import { useState } from 'react';
import { Columns } from 'react-bulma-components';
import copy from 'copy-to-clipboard';
import downloadJs from 'downloadjs';
import PropTypes from 'prop-types';

import './secretCodeContainer.scss';

const SecretCodeContainer = ({ codes, buttonText = 'Copy text code', actionText = 'Copied!', isDownload = false }) => {
    const [doneAction, setDoneAction] = useState(false);

    return <Columns className='secretCodeContainer'>
        <Columns.Column>
            {codes.map((code) => <p key={code}>{code}</p>)}
        </Columns.Column>
        <Columns.Column narrow>
            {!doneAction && <a href='/' onClick={(e) => {
                e.preventDefault();
                const content = codes.join('\n');
                if (isDownload) {
                    downloadJs(content, 'opswat-recovery-codes.txt', 'text/plain');
                } else {
                    copy(content);
                }
                setDoneAction(true);
                setTimeout(() => setDoneAction(false), 3000);
            }}>{buttonText}</a>}
            {doneAction && <span>{actionText}</span>}
        </Columns.Column>
    </Columns>;
};

export default SecretCodeContainer;

SecretCodeContainer.propTypes = {
    codes: PropTypes.array,
    buttonText: PropTypes.string,
    actionText: PropTypes.string,
    isDownload: PropTypes.bool
};
