// check https://opswat.atlassian.net/wiki/spaces/CSSO/pages/1751844674/Error+messages
// For Error messages
/* eslint-disable quotes */
class OrganizationErrors {
    updateOrgErrors(code) {
        switch (code) {
            case 40005:
                return 'There was an error updating the organization. Please refresh the page and try again. If the problem persists.';
            case 40001:
                return 'There was an error updating the organization. The update contains invalid data.';
            case 40402:
                return 'There was an error updating the organization. We couldn’t find the organization.';
            case 50003:
                return 'There was an error updating the organization.';
            default:
                return 'There was an unexpected error while processing the organization update request. Please try to refresh the page and try again.';
        }
    }

    inviteUserErrors(code, newUser) {
        switch (code) {
            case 40005:
                return 'We encountered an error sending the invitation. The invitation contains invalid data format.';
            case 40002:
                return 'We encountered an error sending the invitation. The invitation contains invalid data.';
            case 40401:
                return `The user ${newUser.email} has not registered for an OPSWAT account. Only existing users can be invited to organizations. Verify the email input or make sure the user you are trying to invite is has an active account.`;
            case 40009:
                return `There was an error inviting ${newUser.email} to the organization. The assigned role is invalid or does not exist.`;
            case 40006:
                return `Error: ${newUser.email} already has a pending invitation. A new invitation can be sent after the pending invitation expires.`;
            case 40007:
                return `The user ${newUser.email} is already a member of an organization. An user can be part of one organization only.`;
            case 40010:
                return `The user ${newUser.email} isn't confirmed yet. Only confirmed users can be invited to organizations.`;
            case 40402:
                return 'We encountered an error sending the invitation. We couldn’t find the organization.';
            case 50002:
                return `There was an error sending the invitation email to ${newUser.email}.`;
            case 50008:
                return 'We encountered an error sending the invitation. The user cannot be added to the requested organization.';
            default:
                return 'There was an unexpected error while processing the invite request. Please try to refresh the page and try again.';
        }
    }

    acceptInviteErrors(code) {
        switch (code) {
            case 40403:
                return 'Unable to accept the invitation. Please make sure that you have the correct invitation URL, or that you didn’t accept the invitation already. If you didn’t accept the invitation and the URL is correct, please request a new invitation.';
            case 40401:
                return 'Unable accept the invitation. The user with which you are trying to join doesn’t exist in our database. Please make sure you have an active account with the email that received this invitation.';
            case 50009:
                return 'Unable accept the invitation. Please make sure you have the correct invitation URL.';
            default:
                return 'There was an unexpected error while accepting invitation. Please verify with the organization administrator if the invitation is still valid.';
        }
    }

    removeUserErrors(code, removeUser) {
        switch (code) {
            case 50007:
                return `We encountered an error trying to remove ${removeUser.email}. Please try again and contact support if the error persists.`;
            default:
                return `There was an unexpected error while removing the user from the organization. Please try to refresh the page and try again.`;
        }
    }

    removeInviteUserErrors(code) {
        switch (code) {
            case 40403:
                return 'Unable to remove the invitation. the invitation has expired or not existing.';
            default:
                return `There was an unexpected error while removing the invitation. Please try to refresh the page and try again.`;
        }
    }

    updateUserErrors(code, updateUser) {
        switch (code) {
            case 40401:
                return `The user ${updateUser.email} doesn’t exist in our database. Please refresh the page and try again.`;
            case 40005:
                return 'We encountered an error updating the user. Please refresh the page and try again.';
            case 40009:
                return `There was an error updating ${updateUser.email}. The assigned role is invalid or does not exist.`;
            case 50010:
                return `There was an unexpected error while updating the user. Please refresh the page and try again.`;
            case 500014:
                return `Error when writing user.`;
            default:
                return `There was an unexpected error while updating the user from the organization. Please try to refresh the page and try again.`;
        }
    }
    createOrganizationErrors(code) {
        switch (code) {
            case 500001:
                return 'Error when creating organization';
            case 400001:
                return 'Invalid organization data';
            default:
                return `There was an unexpected error while creating the organization. Please try to refresh the page and try again.`;
        }
    }

    // default errors
    postOauth2ClientErrors(code) {
        if (code.includes(409)) {
            return 'The client already exists.';
        }
        return `There was an unexpected error while adding the oauth2 client. Please try to refresh the page and try again.`;
    }

    // default errors
    listOauth2ClientErrors() {
        return `There was an unexpected error while retrieving the oauth2 clients. Please try to refresh the page and try again.`;
    }

    // default errors
    deleteOauth2ClientErrors() {
        return `There was an unexpected error while deleting the oauth2 client. Please try to refresh the page and try again.`;
    }

    getUserErrors() {
        return `There was an unexpected error while retrieving user data. Please try to refresh the page and try again.`;
    }
}

export default new OrganizationErrors();
