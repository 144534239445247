import { useEffect, useMemo, useState } from 'react';
import CardLayout from '../../components/layout/card-layout/cardLayout';
import Input from '../../components/input/input';
import { Button } from 'react-bulma-components';
import { axios } from '../../services/axios';
import QueryParametersParser from '../../services/QueryParametersParser';
import { logService } from '../../services/LogService';
import { navigate } from '@reach/router';
import { COMMON_ERROR_MESSAGE, errorMessageMap, ERRORS_CODE, NETWORK_ERROR_MESSAGE } from '../../services/Constant';
import Validator from '../../services/Validator';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';

const CHANGE_PASSWORD_FIELDS = [
    {
        label: 'Old Password', name: 'oldPassword', type: 'password', isRequired: true, validation: (value) => {
            const errors = [];
            if (!value) {
                errors.push('Must be not empty');
            }

            return errors;
        }
    },
    {
        label: 'Password', name: 'password', type: 'password', isRequired: true, validation: (value) => Validator.checkPassword(value)
    },
    {
        label: 'Confirm Password', name: 'passwordConfirmation', type: 'password', isRequired: true, validation: (value, passwordValue) => {
            const errors = [];
            if (value !== passwordValue) {
                errors.push('Passwords do not match.');
            }

            return errors;
        }
    }
];

const ChangePassword = ({ location }) => {
    const [fieldData, setFieldData] = useState({
        oldPassword: '',
        password: '',
        passwordConfirmation: '',
    });
    const [fieldErrors, setFieldErrors] = useState({
        oldPassword: [],
        password: [],
        passwordConfirmation: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [successEmail, setSuccessEmail] = useState(undefined);
    const [cookies] = useCookies(['__opswat-login']);

    const query = useMemo(() => {
        if ( location?.state?.email ) {
            return { email: location.state.email };
        } else if ( location?.search ) {
            return QueryParametersParser.parse(location?.search);
        } else if ( cookies?.['__opswat-login']) {
            return { email: cookies['__opswat-login'] };
        }
        return undefined;
    }, [location, cookies]);

    const onChange = (evt) => {
        const value = evt.target.value;
        const currentInput = CHANGE_PASSWORD_FIELDS.filter((field) => field.name === evt.target.name)[0];
        const passwordConfirmationInput = CHANGE_PASSWORD_FIELDS.filter((field) => field.name === 'passwordConfirmation')[0];
        const isPasswordConfirmation = evt.target.name === 'passwordConfirmation';
        const currentInputErrors = !isPasswordConfirmation ? currentInput?.validation(value) : passwordConfirmationInput?.validation(value, fieldData['password']);
        const passwordConfirmationErrors = evt.target.name === 'password' && fieldData['passwordConfirmation'].length ? passwordConfirmationInput?.validation(fieldData['passwordConfirmation'], value) : isPasswordConfirmation ? currentInputErrors : fieldErrors['passwordConfirmation'];

        if (evt.target.type !== 'checkbox') {
            setFieldErrors({ ...fieldErrors, [evt.target.name]: currentInputErrors, 'passwordConfirmation': passwordConfirmationErrors });
        }

        if (evt.key === 'Enter' && currentInputErrors.length === 0 && passwordConfirmationErrors.length === 0) {
            onSubmit();
        }

        setFieldData({ ...fieldData, [evt.target.name]: value });
    };

    const isValid = useMemo(() => {
        const isError = Object.entries(fieldErrors).filter((error) => error[1]?.length !== 0);
        if (!isError.length) {
            const { oldPassword, password, passwordConfirmation, } = fieldData;
            return password && passwordConfirmation && (query?.code || oldPassword);
        }

        return false;
    }, [fieldData, fieldErrors, query]);

    const onSubmit = (e) => {
        e && e.preventDefault();
        if (!isValid || !query?.email) {
            return;
        }

        setIsLoading(true);
        setErrors([]);
        (async () => {
            try {
                let request;
                if (!query.code) {
                    // If change user by current login email
                    request = await axios.post('/change-password', { password: fieldData['password'], oldPassword: fieldData['oldPassword'] });
                } else {
                    // If user change password by using reset code
                    request = await axios.post('/forgot-password-submit', { email: query.email, code: query.code, password: fieldData['password'] });
                }
                if (request.status === 200) {
                    // Reset cookies after user reset password to require user login again
                    await axios.get('/logout');
                    setSuccessEmail(request.data?.email || true);
                }
            } catch (err) {
                logService.error(err);
                let title;
                const messages = [];
                if ((err?.response?.status >= 400 && err?.response?.status < 500) || errorMessageMap(err?.response?.data?.error?.code)) {
                    title = 'Errors';
                    messages.push(COMMON_ERROR_MESSAGE);
                } else {
                    title = 'Oups';
                    messages.push(NETWORK_ERROR_MESSAGE);
                }

                if (err?.response?.data?.error?.code === ERRORS_CODE.notAuthorizedException.code) {
                    messages.push('Your old password is not correct');
                } else {
                    messages.push(errorMessageMap(err?.response?.data?.error?.code));
                }

                setErrors([{
                    title,
                    error: err,
                    messages
                }]);
            }
            setIsLoading(false);
        })();
    };

    useEffect(() => {
        if (successEmail) {
            // Remove email from query but preserve the extra query attributes to send them with QueryParams
            // eslint-disable-next-line no-unused-vars
            const { email, ...extraQueryFields } = query;
            if (Object.keys(extraQueryFields).length) {
                navigate('/login' + QueryParametersParser.toString(extraQueryFields), { state: { email: typeof successEmail === 'string' ? successEmail : undefined } });
            } else {
                navigate('/login', { state: { email: typeof successEmail === 'string' ? successEmail : undefined } });
            }
        }
    }, [successEmail, query]);

    useEffect(() => {
        if (!query?.email) {
            navigate('/login' + QueryParametersParser.toString(query));
        }
    }, [query]);

    return <CardLayout
        pageTitle='OPSWAT Accounts | Change Password'
        className='changePassword'
        heading={'Change Password'}
        errors={errors}
        content={<>
            {!query?.code && <Input
                label='Old Password'
                name='oldPassword'
                autoComplete='current-password'
                type='password'
                isRequired={true}
                value={fieldData['oldPassword']}
                onChange={onChange}
                errors={fieldErrors['oldPassword']}
            />}
            <Input
                label='New Password'
                name='password'
                autoComplete='new-password'
                type='password'
                isRequired={true}
                value={fieldData['password']}
                onChange={onChange}
                errors={fieldErrors['password']}
            />
            <Input
                label='Confirm Password'
                name='passwordConfirmation'
                autoComplete='new-password'
                type='password'
                isRequired={true}
                value={fieldData['passwordConfirmation']}
                onChange={onChange}
                errors={fieldErrors['passwordConfirmation']}
            />
            <Button
                fullwidth={true}
                color='primary'
                onClick={onSubmit}
                disabled={!isValid || isLoading}
                loading={isLoading}
            >
                Submit
            </Button>
        </>}/>;
};

export default ChangePassword;

ChangePassword.propTypes = {
    location: PropTypes.object
};
