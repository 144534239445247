import { useEffect } from 'react';
import { navigate } from '@reach/router';
import QueryParametersParser from '../../services/QueryParametersParser';
import { axios } from '../../services/axios';
import { organizationService } from '../../services/organizationService';
import { logService } from '../../services/LogService';
import LoadingLayout from '../../components/layout/loadingLayout';
import PropTypes from 'prop-types';

const AcceptedInvite = ({ location }) => {
    useEffect(() => {
        const query = QueryParametersParser.parse(location?.search);
        if (query?.id) {
            (async () => {
                try {
                    // Reset cookies when user accept invitation to require user login again
                    await axios.get('/logout');
                } catch (e) {
                    // Ignore if any error
                    logService.error(e);
                }

                const request = await organizationService.acceptInvitationToOrganization(query.id);
                let navigateQuery;
                if (!request.error) {
                    navigateQuery = {
                        redirect: location.origin + '/organization/overview',
                        infoMessage: 'You have joined to organization successfully.'
                    };

                } else {
                    navigateQuery = {
                        redirect: location.origin + '/organization/overview',
                        orgAcceptInviteErrorCode: request.errorCode,
                        orgAcceptInviteErrorTrace: request.trace
                    };
                }

                navigate('/login' + QueryParametersParser.toString(navigateQuery));
            })();
        } else {
            // Redirect to login page if not accept invitation
            navigate('/login' + (location?.search || ''));
        }
    }, [location]);

    return <LoadingLayout title='OPSWAT Accounts | Accept Invitation'/>;
};

export default AcceptedInvite;

AcceptedInvite.propTypes = {
    location: PropTypes.object
};
