import { useMemo, useState, useEffect, useCallback } from 'react';
import { Button } from 'react-bulma-components';
import Validator from 'services/Validator';
import { axios } from 'services/axios';
import Input from '../../components/input/input';
import { logService } from '../../services/LogService';
import { COMMON_ERROR_MESSAGE, errorMessageMap, ERRORS_CODE, NETWORK_ERROR_MESSAGE } from '../../services/Constant';
import moment from 'moment';
import ActionLayout from '../../components/layout/action-layout/actionLayout';
import { getAppNameByID } from '../../services/utility';
import QueryParametersParser from '../../services/QueryParametersParser';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';

const ResetPassword = (props) => {
    const { search } = useLocation();

    const [email, setEmail] = useState(props?.location?.state?.email ?? '');
    const [autoSubmit] = useState(props?.location?.state?.autoSubmit ?? false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const isValid = useMemo(() => Validator.isEmail(email), [email]);

    const [requestError, setRequestError] = useState([]);
    const errors = useMemo(() => !isValid && email ? ['Invalid Email Address'] : [], [email, isValid]);

    const query = useMemo(() => QueryParametersParser.parse(search), [search]);
    const appName = useMemo(() => getAppNameByID(query?.app), [query]);
    const redirect = useMemo(() => query?.redirect, [query]);

    const onSubmit = useCallback((e) => {
        e && e.preventDefault();
        setLoading(true);
        (async () => {
            try {

                const request = await axios.post('/reset', {
                    email,
                    app: appName,
                    redirect
                });
                if (request.status === 200) {
                    setSuccess(true);
                }
            } catch (err) {
                logService.error(err);
                let title;
                const messages = [];
                if ((err?.response?.status >= 400 && err?.response?.status < 500) || errorMessageMap(err?.response?.data?.error?.code)) {
                    title = 'Errors';
                    messages.push(COMMON_ERROR_MESSAGE);
                } else {
                    title = 'Oups';
                    messages.push(NETWORK_ERROR_MESSAGE);
                }

                if (err?.response?.data?.error?.code === ERRORS_CODE.rateLimitError.code) {
                    const wait = moment.duration(err?.response?.data?.error?.lockTime).humanize();
                    messages.pop();
                    messages.push(<span>You have reached the maximum number (<b>{err?.response?.data?.error?.max}</b>) of reset password request per day. Try again after <b>{wait}</b>.</span>);
                    messages.push('Please be sure to check spam folder on your mailbox.');
                } else {
                    messages.push(errorMessageMap(err?.response?.data?.error?.code));
                }

                setRequestError([{
                    title,
                    error: err,
                    messages
                }]);
            }
            setLoading(false);
        })();
    }, [email]);

    useEffect(() => {
        if (email && autoSubmit) {
            onSubmit();
        }
        // eslint-disable-next-line
    }, [email, autoSubmit]);

    const dom = useMemo(() => {
        const pageTitle = 'OPSWAT Accounts | Reset password';

        if (!success) {
            const onChange = (event) => {
                const emailValue = event.target.value;
                setEmail(emailValue);
                if (event.key === 'Enter' && emailValue && Validator.isEmail(emailValue)) {
                    onSubmit();
                }
            };

            return <ActionLayout
                className={'resetPassword'}
                pageTitle={pageTitle}
                app={appName}
                message={{ title: 'Reset your password?' }}
                errors={requestError}>
                <Input
                    label='Enter your email address'
                    name='email'
                    type='email'
                    isRequired={true}
                    value={email}
                    onChange={onChange}
                    errors={errors}
                />
                <Button
                    fullwidth={true}
                    color='primary'
                    onClick={onSubmit}
                    disabled={!isValid || loading}
                    loading={loading}
                    className={'form--button'}
                >
                    Submit
                </Button>
            </ActionLayout>;
        }

        return <ActionLayout
            className={'resetPassword'}
            pageTitle={pageTitle}
            app={appName}
            message={{ title: 'Thank you!', content: 'You will receive an email with a link to reset your password if the email you entered exists.' }}
            question={'If you do not find the email for resetting the password, please also check the spam folder.'}
        />;
    }, [success, loading, email, isValid, errors, appName, onSubmit, requestError]);

    return <>{dom}</>;
};

export default ResetPassword;

ResetPassword.propTypes = {
    location: PropTypes.object
};
