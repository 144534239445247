import { Helmet } from 'react-helmet-async';
import 'bulma-pageloader';
import PropTypes from 'prop-types';

import './loadingLayout.scss';

const LoadingLayout = ({ title, children }) => {
    return <div className='loadingLayout pageloader is-active'>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <span className='title'>Loading...</span>
        {children}
    </div>;
};

export default LoadingLayout;

LoadingLayout.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node
};
