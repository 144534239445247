import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import ContentLoader from 'react-content-loader';
import SSOLayout from '../ssoLayout';
import { navigate } from '@reach/router';
import { Button, Icon, Columns, Container, Dropdown, Menu } from 'react-bulma-components';
import { profileService } from '../../../services/profileService';
import { logService } from '../../../services/LogService';
import PropTypes from 'prop-types';

import './freshestLayout.scss';

const FreshestLayout = ({ className, pageTitle, content, menuItems, page, onMenuClick, type }) => {
    const [userInfo, setUserInfo] = useState();
    const userUpdate = (info) => setUserInfo(info['user'] || info['mfa']);

    const [mfaInfo, setMfaInfo] = useState();
    const mfaUpdate = (info) => setMfaInfo(info['mfa']);

    const isMfaForced = useMemo(() => mfaInfo?.isMfaForced, [mfaInfo]);

    useEffect(() => {
        userUpdate(profileService.getInfo());
        profileService.registerUpdateEvent(userUpdate);
        profileService.registerUpdateEvent(mfaUpdate);
        return () => {
            profileService.unregisterUpdateEvent(userUpdate);
            profileService.unregisterUpdateEvent(mfaUpdate);
        };
    }, []);

    const onUserMenuSelect = (selected) => {
        switch (selected) {
            case 'changePassword':
                navigate('/changePassword', { state: { email: userInfo.email } });
                break;
            case 'logout':
                profileService.removeInfo();
                navigate('/logout');
                break;
            default:
                logService.info('Uncategorized selected:', selected);
                break;
        }
    };

    const onAppMenuSelect = (selected) => {
        switch (selected) {
            case 'OCM':
                window.location.href = `${process.env.REACT_APP_app_href_OCM}/`;
                break;
            case 'MA':
                window.location.href = `${process.env.REACT_APP_app_href_MA}/console/saml/login`;
                break;
            case 'MDC':
                window.location.href = `${process.env.REACT_APP_app_href_MDC}/login`;
                break;
            default:
                logService.info('Uncategorized selected:', selected);
                break;
        }
    };

    const navContent = useMemo(() => {

        let userMenu = (<Button className={'is-loading'}/>);

        if (userInfo) {
            userMenu = (
                <Dropdown
                    className='user-dropdown'
                    label={<div className='user-menu'>
                        <div className='user-name'>{`${userInfo.firstName} ${userInfo.lastName}`}</div>
                        <Icon>
                            <i className='fas fa-caret-down'/>
                        </Icon>
                    </div>}
                >
                    <div
                        className='dropdown-item'
                        title='changePassword'
                        role='menuitem'
                        onClick={() => {
                            onUserMenuSelect('changePassword');
                        }}
                    >
                        Change password
                    </div>
                    <Dropdown.Divider/>
                    <div
                        className='logout dropdown-item'
                        title='logout'
                        role='menuitem'
                        onClick={() => {
                            onUserMenuSelect('logout');
                        }}
                    >
                        Logout
                    </div>
                </Dropdown>
            );
        }

        let appMenu;

        if (userInfo) {
            const className = classNames('app-dropdown', { disabled: isMfaForced });
            appMenu = <Dropdown
                className={className}
                unselectable={isMfaForced}
                label={<div aria-label='App dropdown'>
                    <Icon>
                        <i className='fas fa-bars'/>
                    </Icon>
                </div>}
            >
                <div
                    className='dropdown-item'
                    title='OCM'
                    role='menuitem'
                    onClick={() => {
                        !isMfaForced && onAppMenuSelect('OCM');
                    }}
                >
                    My OPSWAT
                </div>
                <div
                    className='dropdown-item'
                    title='MA'
                    role='menuitem'
                    onClick={() => {
                        !isMfaForced && onAppMenuSelect('MA');
                    }}
                >
                    MetaDefender IT Access
                </div>
                <div
                    className='dropdown-item'
                    title='MDC'
                    role='menuitem'
                    onClick={() => {
                        !isMfaForced && onAppMenuSelect('MDC');
                    }}
                >
                    MetaDefender Cloud
                </div>
            </Dropdown>;
        }

        return (
            <>
                {userMenu}
                {appMenu}
            </>
        );
        // eslint-disable-next-line
    }, [userInfo, isMfaForced]);

    const buildMenu = (menuItems, page, onMenuClick, type, title, className) => {
        const menus = menuItems.map((menu, index) => {
            const title = <span className='sidebar--item'>
                <Icon>
                    {menu.icon}
                </Icon>
                {
                    menu.loading ?
                        <ContentLoader
                            height={28}
                            width='100%'
                            speed={1}
                        >
                            <rect x='0' y='0' rx='4' ry='4' width={'70%'} height='28'/>
                        </ContentLoader>
                        :
                        menu.label
                }
            </span>;
            return <Menu.List.Item
                key={index}
                className={classNames({ 'disabled': menu.disabled || menu.loading, 'hidden': menu.hide })}
                active={menu.page === page && menu.parent === type && (!menu.subs || menu.subs.every((sub) => sub.page !== page))}
                onClick={(event) => {
                    if (!menu.disabled && !menu.loading) {
                        event.preventDefault();
                        onMenuClick(menu.subs && !menu.page ? menu.subs[0] : menu);
                    }
                }}
            >

                {menu.subs && !menu.loading ? buildMenu(menu.subs, page, onMenuClick, type, title, classNames({ 'hideSubsMenu': menu.subs.every((subItem) => subItem.page !== page || subItem.parent !== type) && (menu.page !== page && menu.parent !== type) })) : title}
            </Menu.List.Item>;
        });

        return <Menu.List title={title} className={className}>
            {menus}
        </Menu.List>;
    };

    const sideBar = useMemo(() => {
        return <Menu>
            {buildMenu(menuItems, page, onMenuClick, type)}
        </Menu>;
        // eslint-disable-next-line
    }, [menuItems, page, onMenuClick, type]);

    const footer = <section className='freshestFooter'>
        <hr/>
        <Columns breakpoint='tablet' variableGap={{ mobile: 0, desktop: 8 }}>
            <Columns.Column className='is-half'>
                <Container>
                    <a href='https://twitter.com/opswat' target={'_blank'} rel={'noopener noreferrer'}  aria-label='twitter'>
                        <Icon>
                            <i className='fab fa-twitter'/>
                        </Icon>
                    </a>
                    <a href='https://www.facebook.com/OPSWAT' target={'_blank'} rel={'noopener noreferrer'}  aria-label='facebook'>
                        <Icon>
                            <i className='fab fa-facebook-square'/>
                        </Icon>
                    </a>
                    <a href='https://www.youtube.com/user/opswat1' target={'_blank'} rel={'noopener noreferrer'}  aria-label='youtube'>
                        <Icon>
                            <i className='fab fa-youtube'/>
                        </Icon>
                    </a>
                    <a href='https://www.linkedin.com/company/opswat/' target={'_blank'} rel={'noopener noreferrer'}  aria-label='linkedin'>
                        <Icon>
                            <i className='fab fa-linkedin'/>
                        </Icon>
                    </a>
                </Container>
                <Container className='about'>
                    <span>© {new Date().getFullYear()} OPSWAT, Inc. All rights reserved.
                        OPSWAT®, MetaDefender®, MetaAccess, Trust No File, Trust No Device
                        and the OPSWAT logo are trademarks of OPSWAT, Inc.
                    </span>
                </Container>
            </Columns.Column>
            <Columns.Column className='is-half has-text-right-tablet'>
                <a href='https://onlinehelp.opswat.com/policies/OPSWAT_Portal_Terms_Of_Service.html'>Terms of Service</a>
                <a href='https://onlinehelp.opswat.com/policies/index.html'>Privacy Policy</a>
                <a href='https://www.opswat.com/connect'>Connect</a>
                <a href='https://www.opswat.com/blog'>OPSWAT Blog</a>
            </Columns.Column>
        </Columns>
    </section>;

    return <SSOLayout layoutClass={classNames(`${className}--page`, 'freshestLayout')} fullGray={true} pageTitle={pageTitle} navContent={navContent}>
        <Columns breakpoint='tablet'>
            <Columns.Column
                className='sidebar'
                tablet={{ size: 3 }}
                desktop={{ size: 2 }}>
                {sideBar}
            </Columns.Column>
            <Columns.Column
                tablet={{ size: type === 'reports' ? 8 : 7 }}
                desktop={{ size: type === 'reports' ? 9 : 7, offset: 1 }}
            >
                {content}
            </Columns.Column>
        </Columns>
        {footer}
    </SSOLayout>;
};

export default FreshestLayout;

FreshestLayout.propTypes = {
    className: PropTypes.string,
    pageTitle: PropTypes.string,
    content: PropTypes.node,
    menuItems: PropTypes.array,
    page: PropTypes.string,
    onMenuClick: PropTypes.func,
    type: PropTypes.string,
};
