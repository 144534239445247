import { axios } from './axios';
import { logService } from './LogService';
import { navigate } from '@reach/router';
import { ERRORS_CODE } from './Constant';

const FIELDS = ['user', 'MDC', 'MA', 'mfa'];

class ProfileService {
    constructor() {
        this.loading = false;
        this.info = {};
        this.events = [];
    }

    async loadInfo() {
        if (this.loading) {
            // If loading
            return;
        }

        this.loading = true;

        try {
            if (FIELDS.every((field) => this.info[field])) {
                // If already get data
                this.events.forEach((e) => e(this.info));
            } else {
                const response = await axios.get('/profile?fields=' + encodeURIComponent(FIELDS.join(',')));
                if (response?.data) {
                    this.info = {};
                    FIELDS.forEach((field) => {
                        if (response.data[field]) {
                            this.info[field] = response.data[field];
                        }
                    });
                    this.events.forEach((e) => e(this.info));
                }
            }
        } catch (err) {
            logService.error(err);
            if (err?.response?.data?.error?.code === ERRORS_CODE.sessionError.code) {
                await navigate('/login?redirect=' + encodeURIComponent(window.location.href));
            }
        }
        this.loading = false;
    }

    updateInfo(field, data) {
        this.info = { ...this.info, [field]: data };
        this.events.forEach((e) => e(this.info));
    }

    getInfo() {
        return this.info;
    }

    removeInfo() {
        this.info = {};
        this.events.forEach((e) => e(this.info));
    }

    registerUpdateEvent(event) {
        this.events.push(event);
    }

    unregisterUpdateEvent(event) {
        this.events = this.events.filter((e) => e !== event);
    }
}

export const profileService = new ProfileService();
