import { Fragment, useState, useMemo, useEffect } from 'react';
import { ROLES } from '../../../services/Constant';
import { organizationService } from '../../../services/organizationService';
import ContentLoader from 'react-content-loader';
import { Button, Form, Table } from 'react-bulma-components';
import className from 'classnames';
import SimpleMessages from '../../common/simple-messages/simpleMessages';
import SimpleModal from '../../common/simple-modal/simpleModal';
import PropTypes from 'prop-types';

import './usersTable.scss';

const LIMIT = 20;
const SEARCH_TYPES = [
    { key: 'email', label: 'Email', placeholder: 'example: user@example.com' },
    { key: 'given_name', label: 'First name', placeholder: 'example: Jane' },
    { key: 'family_name', label: 'Last name', placeholder: 'example: Doe' }
];

const UsersTable = ({ orgId, userId, orgName }) => {
    const { Control, Input, Select } = Form;

    const [loading, setLoading] = useState({});
    const [errors, setErrors] = useState();

    const [users, setUsers] = useState(undefined);
    const [nextUserToken, setNextUserToken] = useState(undefined);

    const [deleteUser, setDeleteUser] = useState(undefined);
    const [updateUser, setUpdateUser] = useState(undefined);

    const [showSearch, setShowSearch] = useState(false);
    const [searchType, setSearchType] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [search, setSearch] = useState();

    useEffect(() => {
        const timeout = setTimeout(() => setSearch(searchValue), 500);
        return function () {
            clearTimeout(timeout);
        };
    }, [searchValue]);

    const searchQuery = useMemo(() => {
        if (search) {
            return { text: search, type: SEARCH_TYPES[searchType]?.key || SEARCH_TYPES[0].key };
        }

        return undefined;
    }, [search, searchType]);

    useEffect(() => {

        let discard = false;

        if (orgId && userId) {
            setUsers(undefined);
            setNextUserToken(undefined);
            setLoading({});

            (async () => {
                const data = await organizationService.getUsers(orgId, userId, searchQuery, false, LIMIT);
                if (!discard && data) {
                    setUsers(data.users);
                    setLoading(undefined);
                    setNextUserToken(data.nextPageToken);
                }
            })();
        }

        return function () {
            discard = true;
        };
    }, [userId, orgId, searchQuery]);

    const loadMore = (orgId, userId, searchQuery, token) => {
        setLoading({ loadMore: true });

        (async () => {
            const data = await organizationService.getUsers(orgId, userId, searchQuery, false, LIMIT, token);
            if (data) {
                setUsers((users) => (users ? users.concat(data.users) : data.users));
                setLoading(undefined);
                setNextUserToken(data.nextPageToken);
            }
        })();
    };

    const searchDom = useMemo(() => {
        if (showSearch) {
            return <tr>
                <td colSpan={4} className='searchRow'>
                    <Control className='searchControl'>
                        <Select className='searchType' onChange={(e) => {
                            setSearchType(e.target.value);
                        }} value={searchType}>
                            {SEARCH_TYPES.map((type, index) => <option key={index} value={index}>{type.label}</option>)}
                        </Select>

                        <Input
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            placeholder={SEARCH_TYPES[searchType]?.placeholder}
                            autoFocus={true} />
                        {searchValue && <div className='clearSearchBtn' onClick={() => setSearchValue('')}>
                            <i className='fas fa-times' />
                        </div>}
                    </Control>
                </td>
            </tr>;
        }
    }, [showSearch, searchValue, searchType]);

    const tableContentDom = useMemo(() => {
        let dom;
        if (users) {
            dom = <>
                {users.map((user, index) => <Fragment key={index}>
                    <tr>
                        <td>{`${user.firstName} ${user.lastName}`}</td>
                        <td className='hidden-sm'>{user.email}</td>
                        <td className={className({ 'hidden-xs': !updateUser || updateUser.userId !== user.userId })}>
                            {updateUser && updateUser.userId === user.userId ?
                                <Select className='editRole' onChange={(e) => {
                                    const roleId = e.target.value;
                                    setUpdateUser(({ ...updateUser, roles: [roleId] }));
                                }} value={Array.isArray(user.roles) && user.roles.length ? updateUser.roles[0] : ROLES.user.id}>
                                    {Object.keys(ROLES).map((key) => <option key={key} value={ROLES[key].id}>{ROLES[key].label}</option>)}
                                </Select>
                                :
                                (Array.isArray(user.roles) ? user.roles.map((role) => Object.keys(ROLES).find((key) => ROLES[key].id === role)).filter((key) => key).map((key) => ROLES[key].label).join(', ') : '')
                            }
                        </td>
                        <td>
                            {!loading && !updateUser && user.userId !== userId && !user.roles.includes(ROLES.admin.id) && <>
                                <a href='/' className='floatRight' aria-label='edit' onClick={(e) => {
                                    e.preventDefault();
                                    setUpdateUser(user);
                                    setErrors();
                                }}>
                                    <i className='fas fa-pen' />
                                </a>
                                <a href='/' className='negative floatRight' aria-label='remove' onClick={(e) => {
                                    e.preventDefault();
                                    setDeleteUser(user);
                                    setErrors();
                                }}>
                                    <i className='fas fa-trash' />
                                </a>
                            </>}
                            {updateUser && updateUser.userId === user.userId && <>
                                <a href='/' className='negative floatRight' aria-label='cancel' onClick={(e) => {
                                    e.preventDefault();
                                    setUpdateUser(undefined);
                                }}>
                                    <i className='fas fa-times' />
                                </a>
                                <a href='/' className='floatRight' aria-label='done' onClick={(e) => {
                                    e.preventDefault();
                                    (async () => {
                                        const result = await organizationService.updateUserRole(userId, updateUser, orgId);
                                        if (result.error) {
                                            // Update fail
                                            setErrors(result.messages);
                                        } else {
                                            // Update user
                                            setUsers((users) => users.map((user) => user.userId === updateUser.userId ? updateUser : user));
                                        }
                                        setLoading(undefined);
                                    })();
                                    setUpdateUser(undefined);
                                    setLoading({ updating: updateUser.userId });
                                }}>
                                    <i className='fas fa-check' />
                                </a>
                            </>}
                            {loading && loading.deleting === user.userId && <span className='negative floatRight'>Deleting...</span>}
                            {loading && loading.updating === user.userId && <span className='pending floatRight'>Updating...</span>}
                        </td>
                    </tr>
                </Fragment>)}
                {!users.length && <tr>
                    <td colSpan={4}>
                        No results found.
                    </td>
                </tr>}
                {nextUserToken && <tr>
                    <td colSpan={4} className='loadMoreRow'>
                        <Button className='loadMore' disabled={!!loading} loading={loading?.loadMore} onClick={() => loadMore(orgId, userId, searchQuery, nextUserToken)}>Load more</Button>
                    </td>
                </tr>}
            </>;
        } else {
            dom = Array(5).fill('').map((v, index) => <Fragment key={index}>
                <tr>
                    <td colSpan={4}>
                        <ContentLoader height={20} width='100%' speed={1}>
                            <rect x='0' y='0' rx='10' ry='10' width={`${40 + 60 * Math.random()}%`} height='20' />
                        </ContentLoader>
                    </td>
                </tr>
            </Fragment>);
        }
        return dom;
    }, [users, orgId, userId, loading, searchQuery, updateUser, nextUserToken]);

    const modalDeleteUser = useMemo(() => {
        const deleteHandler = () => {
            (async () => {
                const result = await organizationService.removeUserFromOrganization(orgId, deleteUser);

                if (result.error) {
                    // Update fail
                    setErrors(result.messages);
                } else {
                    // Update to remove user
                    setUsers((users) => users && users.filter((user) => user.userId !== deleteUser.userId));
                }
                setLoading(undefined);
            })();
            setDeleteUser(undefined);
            setLoading({ deleting: deleteUser.userId });
        };

        return <SimpleModal
            className='deleteUserModal'
            isShow={deleteUser}
            onClose={() => setDeleteUser(undefined)}
            title='Remove user from organization'
            body={<div className='text-center'>
                You are about to remove the user's api key<br/>
                associated with <span className='highlightDelete'>{deleteUser?.email}</span> from the <span className='text-bold'>{orgName}</span> Organization which is an active user. <br/>
                All the access rights and the configurations associated with the user under the organization will be removed.<br/>
                Are you sure you want to continue?
            </div>}
            footer={<>
                <Button color='danger' onClick={deleteHandler}>
                    Yes
                </Button>
                <Button onClick={() => setDeleteUser(undefined)}>
                    No
                </Button>
            </>}
        />;
    }, [deleteUser, userId, orgId]);

    return <div className='usersTable'>
        <SimpleMessages messageList={errors} />
        <Table className='orgTable'>
            <tbody>
                <tr>
                    <td>Name</td>
                    <td className='hidden-sm'>Email</td>
                    <td className='hidden-xs'>Role</td>
                    <td className='buttonBox'>
                        <Button className='searchBtn' color='info' disabled={!!searchValue && showSearch} onClick={() => setShowSearch((show) => !show)}><i className='fas fa-search' /> Search User</Button>
                    </td>
                </tr>
                {searchDom}
                {tableContentDom}
            </tbody>
        </Table>
        {modalDeleteUser}
    </div>;
};

export default UsersTable;

UsersTable.propTypes = {
    orgId: PropTypes.string,
    userId: PropTypes.string,
    orgName: PropTypes.string
};
