/* eslint-disable quotes */
import * as log from 'loglevel';

const LOG_LEVEL_KEY = 'loglevel';
const LOG_LEVELS = ['trace', 'debug', 'info', 'warn', 'error', 'silent'];

class LogService {
    constructor() {
        let logLevel = window.localStorage.getItem(LOG_LEVEL_KEY);
        if (!logLevel) {
            window.localStorage.setItem(LOG_LEVEL_KEY, 'info');
        }

        logLevel = LOG_LEVELS.includes(logLevel) ? logLevel : 'info';
        log.setLevel(logLevel, false);
        
        const currentAppVersion = process.env.REACT_APP_CURRENT_VERSION;
        log.debug("Current version:", currentAppVersion);
    }

    info(...args) {
        log.info(...args);
    }

    warn(...args) {
        log.warn(...args);
    }

    error(...args) {
        log.error(...args);
    }

    sentryReport(...args) {
        log.error(...args);

        // If network error or 404 status response, skip sentry.io log
        if (args.filter((e) => e.response?.status === 404 || e.message === 'Network Error').length) {
            return;
        }
    }
}

export const logService = new LogService();
