import { useMemo } from 'react';
import { Container, Section } from 'react-bulma-components';
import { Columns } from 'react-bulma-components';
import { Helmet } from 'react-helmet-async';
import classname from 'classnames';
import productData from '../../services/productData';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';

import './homeLayout.scss';

const HomeLayout = ({ className, children, app, pageTitle }) => {
    // eslint-disable-next-line no-prototype-builtins
    const productInfo = useMemo(() => productData.hasOwnProperty(app) ? productData[app] : productData.OCM, [app]);

    return <Section className={classname('homeLayout pt-0 pb-0', className)}>
        <Container fluid>
            <Helmet>
                <title> {pageTitle} </title>
            </Helmet>
            <Columns breakpoint='tablet' variableGap={{ mobile: 0, desktop: 8 }}>
                <Columns.Column className='is-one-third leftPanelWrapper'>
                    <div className='leftPanel'>
                        <Link className='logoWrapper' to={'/' + (window?.location?.search || '')}>
                            {productInfo.logo}
                        </Link>
                        <p className='productTexts'>Trust no file. Trust no device.™</p>
                    </div>
                </Columns.Column>

                <Columns.Column className='rightPanelWrapper'>
                    {children}
                </Columns.Column>
            </Columns>
        </Container>
    </Section>;
};

export default HomeLayout;

HomeLayout.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    app: PropTypes.string,
    pageTitle: PropTypes.string
};
