// file deepcode ignore PrototypePollutionFunctionParams
/* eslint-disable camelcase */
import { axios } from './axios';
import { errorTraceID } from './utility';
import { logService } from './LogService';
import organizationErrors from './organizationErrors';
import QueryParametersParser from './QueryParametersParser';
class OrganizationService {
    constructor() {
        this.organization = {};
        this.events = [];
    }

    async createOrganization(parent_id, name) {
        if (parent_id && name) {
            try {
                await axios.post('/v2/organizations', {
                    organization: {
                        parent_id,
                        name,
                    }
                });
                return { error: false };
            } catch (err) {
                return {
                    error: true,
                    messages: [organizationErrors.createOrganizationErrors(err?.response?.data?.code), errorTraceID(err, true)]
                };
            }
        }
    }

    async getUserOrganization(userId) {
        try {
            let response = await axios.get(`/organization/users/${userId}`, {
                headers: {
                    userpid: userId
                }
            });
            const organizationId = response.data.organizationId;
            await this.getOrganization(userId, organizationId, true);
        } catch (err) {
            if (err?.response?.data?.message === 'Unauthorized') {
                // User doesn't has organization
                this.organization[userId] = null;
            }
            this.pushUpdateEvent(userId);
        }
    }


    async getOrganization(userId, orgId, withDetails) {
        try {
            let response = await axios.get(`/v2/organizations/${orgId}`, {
                headers: {
                    details: withDetails
                }
            });

            const organizationData = response.data.organizationData;
            const roles = [organizationData?.roles] || [];

            if (organizationData?.subOrganizations) {

                let subOrgs = organizationData.subOrganizations.map((subOrganization) => {
                    subOrganization.roles = [subOrganization.roles];
                    return subOrganization;
                });

                subOrgs.sort((a, b) => a['name'].localeCompare(b['name']));

                organizationData.subOrganizations = subOrgs;
            }
            this.organization[userId] = { ...response.data.organizationData, roles };
        } catch (err) {
            if (err?.response?.data?.message === 'Unauthorized') {
                // User doesn't has organization
                this.organization[userId] = null;
            }
        }
        this.pushUpdateEvent(userId);
    }

    async getUserRole(organizationId) {
        try {

            let response = await axios.get(`/v2/organizations/${organizationId}`);
            let organizationData;

            if (response) {
                organizationData = response.data.organizationData;

            }
            const roles = [organizationData?.roles] || [];
            return roles;

        } catch (err) {
            logService.error(err);
        }
    }


    async getUsers(organizationId, userId, search, isInviting, limit, token) {
        try {
            const response = await axios.get(
                `/organization/users/org/${organizationId}`
                + QueryParametersParser.toString({
                    search_text: search?.text,
                    search_type: search?.type,
                    inviting: isInviting ? 'true' : '',
                    limit,
                    token
                }),
                {
                    headers: {
                        orgid: organizationId,
                        userpid: userId
                    }
                });
            return response.data;
        } catch (err) {
            logService.error(err);
        }
    }

    async updateUserRole(userId, updateUser, orgId) {
        try {
            await axios.post(`/organization/users/${updateUser.userId}`, {
                roles: updateUser.roles
            }, {
                headers: {
                    orgid: orgId,
                    userpid: userId
                }
            });
            return { error: false };
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            return {
                error: true,
                messages: [organizationErrors.updateUserErrors(err?.response?.data?.code, updateUser), errorTraceID(err, true)]
            };
        }
    }

    async updateOrgName(organizationId, userId, name) {
        return this.updateOrg(organizationId, userId, { name });
    }

    async addIpRange(organizationId, userId, ips) {
        return this.updateOrg(organizationId, userId, { ipWhitelist: ips });
    }

    async updateSubOrganization(orgId, newOrgData) {
        try {
            await axios.patch(`/v2/organizations/${orgId}`, {
                ...newOrgData
            });
            return { error: false };
        } catch (err) {
            logService.error(err);
            return {
                error: true,
                messages: [organizationErrors.updateOrgErrors(err?.response?.data?.code), errorTraceID(err, true)]
            };
        }
    }

    async updateOrganizationRsa(orgId, rsaKey) {
        try {
            await axios.patch(`/v2/organizations/${orgId}/rsa`, { mdc_public_key: rsaKey });
            return { error: false };
        } catch (err) {
            logService.error(err);
            return {
                error: true,
                messages: [organizationErrors.updateOrgErrors(err?.response?.data?.code), errorTraceID(err, true)]
            };
        }
    }

    async deleteOrganizationRsa(orgId) {
        try {
            await axios.patch(`/v2/organizations/${orgId}/rsa`);
            return { error: false };
        } catch (err) {
            logService.error(err);
            return {
                error: true,
                messages: [organizationErrors.updateOrgErrors(err?.response?.data?.code), errorTraceID(err, true)]
            };
        }
    }

    async updateOrg(organizationId, userId, organization) {
        try {
            const response = await axios.post(`/organization/update/${organizationId}`, { organization }, {
                headers: {
                    userpid: userId
                }
            });
            this.organization[userId] = { ...this.organization[userId], ...response.data };
            this.pushUpdateEvent(userId);
            return { error: false };
        } catch (err) {
            logService.error(err);
            return {
                error: true,
                messages: [organizationErrors.updateOrgErrors(err?.response?.data?.code), errorTraceID(err, true)]
            };
        }
    }

    async inviteUserToOrganization(organizationId, userId, newUser) {
        try {
            const response = await axios.post('/organization/users/invite', {
                email: newUser.email,
                organizationId: organizationId,
                roles: [newUser.role]
            }, {
                headers: {
                    userpid: userId
                }
            });
            return { error: false, data: response.data };
        } catch (err) {
            logService.error(err);
            return {
                error: true,
                messages: [organizationErrors.inviteUserErrors(err?.response?.data?.code, newUser), errorTraceID(err, true)]
            };
        }
    }

    async acceptInvitationToOrganization(id) {
        try {
            const response = await axios.get('/organization/users/invite/' + id);
            return { error: false, data: response.data };
        } catch (err) {
            logService.error(err);
            return {
                error: true,
                errorCode: err?.response?.data?.code,
                trace: errorTraceID(err, true)
            };
        }
    }

    async removeUserFromOrganization(orgId, removeUser) {
        try {
            await axios.delete(`/v2/organizations/${orgId}/user/${removeUser.userId}`);
            return { error: false };
        } catch (err) {
            logService.error(err);
            return {
                error: true,
                messages: [organizationErrors.removeUserErrors(err?.response?.data?.code, removeUser), errorTraceID(err, true)]
            };
        }
    }

    async removeInviteUser(userId, removeUser) {
        try {
            await axios.delete(`/organization/users/invite/${removeUser.inviteId}`, {
                headers: {
                    userpid: userId
                }
            });
            return { error: false };
        } catch (err) {
            logService.error(err);
            return {
                error: true,
                messages: [organizationErrors.removeInviteUserErrors(err?.response?.data?.code), errorTraceID(err, true)]
            };
        }
    }

    pushUpdateEvent(userId) {
        this.events.forEach((e) => {
            e(this.organization[userId] ? this.organization[userId] : null);
        });
    }

    registerUpdateEvent(event) {
        this.events.push(event);
    }

    unregisterUpdateEvent(event) {
        this.events = this.events.filter((e) => e !== event);
    }

}

export const organizationService = new OrganizationService();
