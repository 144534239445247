import { useEffect, useMemo, useState } from 'react';
import { Heading, Columns, Form, Button, Icon, Dropdown } from 'react-bulma-components';
import classNames from 'classnames';
import SimpleModal from '../../common/simple-modal/simpleModal';
import { organizationService } from '../../../services/organizationService';
import { ROLES } from '../../../services/Constant';
import LicensesTable from '../licenses/licensesTable';
import OrganizationsTable from './organizationsTable';
import SimpleMessages from '../../common/simple-messages/simpleMessages';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { userService } from '../../../services/userService';


import './organizationPage.scss';
const { Input } = Form;


const OrganizationPage = ({ orgId, userId, organizationData }) => {

    const isAdmin = useMemo(() => organizationData?.roles?.some((roleId) => ROLES.admin.id === roleId), [organizationData]);
    const [isParentAdmin, setIsParentAdmin] = useState();
    const [updatingName, setUpdatingName] = useState(false);
    const [newNameInput, setNewNameInput] = useState('');
    const [showChangeNameModal, setShowChangeNameModal] = useState(false);
    const [errors, setErrors] = useState();
    const [organization, setOrganization] = useState();
    const [organizationUserData, setOrganizationUserData] = useState();

    useEffect(() => {

        setIsParentAdmin();
        setOrganization(organizationData);

        if (organizationData && organizationData.parent_id) {

            (async () => {
                const response = await organizationService.getUserRole(organizationData.parent_id);

                if (response) {
                    response.some((roleId) => ROLES.admin.id === roleId) ? setIsParentAdmin(true) : setIsParentAdmin(false);
                } else {
                    setIsParentAdmin(false);
                }
            })();
        }

    }, [organizationData]);

    useEffect(() => {

        if (userId) {

            (async () => {
                const response = await userService.getUser(userId);

                if (response && response.data && response.data.organizationUserData) {
                    setOrganizationUserData(response.data.organizationUserData);
                }
            })();
        }

    }, [userId, isParentAdmin]);

    useEffect(() => {

        setErrors();

    }, [orgId]);



    const organizationName = useMemo(() => {

        let organizationMenu = <Heading renderAs={'h1'} weight={'normal'} className='organization-name'>{organization?.name || 'not configured yet'}</Heading>;

        const onOrgMenuSelect = async (id) => {
            await navigate(`/organization/${id}/overview`);
        };

        if (organization && organizationUserData) {

            const className = classNames('organization-dropdown', { disabled: false });
            const data = organizationUserData.filter((org) => (org.organization_id !== organization.id) && (org.rank === organization.rank));

            if (data && data.length) {
                organizationMenu = <Dropdown
                    className={className}
                    label={<div className='organization-menu'>
                        <Heading renderAs={'h1'} weight={'normal'} className='organization-name'>{organization.name || 'not configured yet'}</Heading>
                        <Icon>
                            <i className='fas fa-caret-down' />
                        </Icon>
                    </div>}
                >
                    {data.map((org) => {
                        return <div
                            className='dropdown-item'
                            title={org.name}
                            key={org.name}
                            role='menuitem'
                            onClick={() => {
                                onOrgMenuSelect(org.organization_id);
                            }}
                        >
                            {org.name || 'not configured yet'}
                        </div>;
                    })}
                </Dropdown>;
            }

        }

        return <>
            {organizationMenu}
        </>;
    }, [organization, organizationUserData]);

    const modalChangeName = useMemo(() => {

        const changeNameHandler = () => {

            (async () => {

                const result = await organizationService.updateOrgName(organization.id, userId, newNameInput);

                if (result.error) {
                    // Update fail
                    setErrors(result.messages);
                }
                setUpdatingName(false);
            })();

            setShowChangeNameModal(false);
            setUpdatingName(true);
        };

        const onInputChange = (e) => {

            if (e.key === 'Enter') {
                changeNameHandler();
            } else {
                setNewNameInput(e.target.value);
            }
        };

        const updateBtn = <Button color='info' onClick={changeNameHandler}>
            Update
        </Button>;

        const cancelBtn = <Button onClick={() => setShowChangeNameModal(false)}>
            Cancel
        </Button>;

        return <SimpleModal
            className='changeNameModal'
            isShow={showChangeNameModal}
            onClose={() => setShowChangeNameModal(false)}
            title='Input your new organization name'
            body={<Input value={newNameInput} onChange={onInputChange} autoFocus={true} />}
            footer={<>
                {updateBtn}
                {cancelBtn}
            </>}
        />;
    }, [organization, userId, showChangeNameModal, newNameInput]);


    const header = useMemo(() => {
        const editNameButton = null; //enable above after we support organization creation from Cognito

        const goToParentButton = (() => {
            if (organization?.parent_id) {

                const addHandler = async () => {
                    await navigate(`/organization/${organization.parent_id}/overview`);
                };

                switch (isParentAdmin) {
                    case true:
                        return <a
                            className="goToParentBtn"
                            onClick={addHandler}>
                            <i className="fas fa-arrow-left" />
                            Go to parent
                        </a>;
                    case false:
                        return null;
                    default:
                        return <ContentLoader
                            height={33}
                            width='100%'
                            speed={1}
                        >
                            <rect x='0' y='5' rx='10' ry='10' width='100%' height='28' />
                        </ContentLoader>;
                }
            }
            return null;
        });


        let dom;
        if (!organization) {
            dom = <ContentLoader
                height={33}
                width='100%'
                speed={1}
            >
                <rect x='0' y='5' rx='10' ry='10' width={`${40 + 60 * Math.random()}%`} height='28' />
            </ContentLoader>;
        } else {
            dom = <Columns breakpoint="mobile">
                <Columns.Column size={9}>
                    {organizationName}
                    {editNameButton}
                    {updatingName && <span>Updating...</span>}
                </Columns.Column>
                <Columns.Column size={3} className='ctrlCol'>
                    {goToParentButton()}
                </Columns.Column>

            </Columns >;
        }

        return <div className='orgName'>
            {dom}
        </div>;
    }, [organization, organizationUserData, updatingName, isParentAdmin]);

    return <div className='organizationsPage'>
        <Columns className='pageHeader'>
            <Columns.Column>
                {header}
            </Columns.Column>
        </Columns>
        <div className='pageBody'>
            <LicensesTable organizationData={organization} />
            <OrganizationsTable
                userId={userId}
                orgId={orgId}
                isAdmin={isAdmin}
                organizationData={organization}
            />
        </div>
        {modalChangeName}
        <SimpleMessages messageList={errors} />
    </div>;
};

export default OrganizationPage;

OrganizationPage.propTypes = {
    userId: PropTypes.string,
    organizationData: PropTypes.object,
    orgId: PropTypes.string
};
