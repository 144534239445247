import { useEffect } from 'react';
import { navigate } from '@reach/router';
import QueryParametersParser from '../../services/QueryParametersParser';
import { axios } from '../../services/axios';
import { logService } from '../../services/LogService';
import { ERRORS_CODE } from '../../services/Constant';
import { getAppNameByID, getTraceID } from '../../services/utility';
import LoadingLayout from '../../components/layout/loadingLayout';
import productData from '../../services/productData';
import PropTypes from 'prop-types';

const Active = ({ location }) => {
    useEffect(() => {
        let query = QueryParametersParser.parse(location?.search);
        if (query?.code && query?.email) {
            (async () => {
                try {
                    // Reset cookies to require user login again
                    await axios.get('/logout');
                } catch (e) {
                    // Ignore if any error
                    logService.error(e);
                }
                const autoLogin = query?.app === process.env.REACT_APP_appID_MA && query?.token;
                let error, email, user, loginResponse;
                let navigateState = {};
                try {
                    const response = await axios.post('/confirm', query);
                    email = response?.data?.email;
                    user = response?.data?.user;
                    loginResponse = response?.data?.loginResponse;
                    navigateState = autoLogin ? { activatedEmail: email, user: user, loginResponse: loginResponse }: { activatedEmail: email };
                } catch (err) {
                    error = err;
                }

                const appName = getAppNameByID(query.app);
                // eslint-disable-next-line no-prototype-builtins
                const productName = appName && productData.hasOwnProperty(appName) ? productData[appName].shortname : productData.OCM.shortname;

                // Remove code and email from query
                query = {
                    ...query,
                    code: undefined,
                    email: undefined
                };

                if (!error) {
                    // Confirm account success
                    query = {
                        ...query,
                        infoMessage: `Your OPSWAT ${productName} account has been activated. Please sign in with your username and password.`
                    };
                } else {
                    logService.error(error);
                    // Get error code
                    const errorCode = error?.response?.data?.error?.code;

                    // Get trace ID
                    let traceId = getTraceID(error);
                    if (traceId) {
                        const match = traceId.match(/(\w+-\w+-\w+)/g);
                        if (match && match.length) {
                            traceId = match[0];
                        }
                    }

                    // Show error on login page
                    query = {
                        ...query,
                        activateErrorCode: errorCode || ERRORS_CODE.userSideError.code,
                        activateErrorTraceID: traceId
                    };
                }


                if (appName && query.redirect) {
                    // Check if product has `customHost` attribute set, which will be used to redirect the user after account activation
                    // eslint-disable-next-line no-prototype-builtins
                    const customHosts = appName && productData.hasOwnProperty(appName) && productData[appName].customHosts ? productData[appName].customHosts : undefined;

                    // Custom host may contain multiple values so split and
                    // Then filter for value === to 'redirect' parameterer provided in query
                    let customHost = customHosts ? (customHosts.split(',')).filter((link) => link === query.redirect) : undefined;

                    if (customHost && customHost[0]) {
                        // Remove trailing '/' (if existent)
                        customHost = customHost[0].replace(/\/$/, '');

                        // If 'signin' attribute is defined for product, signin = productData[appName].signin with starting '/' removed (if existent)
                        // Else signin = "login"
                        const signin = productData[appName].signin ? productData[appName].signin.replace(/^\//, '') : 'login';
                        // Build url used to redirect
                        const redirectUrl = customHost + '/' + signin;
                        navigate(redirectUrl, { state: navigateState });
                    }
                }
                navigate('/login' + QueryParametersParser.toString(query), { state: navigateState });
            })();
        } else {
            // Redirect to login page if not active email
            navigate('/login' + (location?.search || ''));
        }
    }, [location]);

    return <LoadingLayout title='OPSWAT Accounts | Active Email' />;
};

export default Active;

Active.propTypes = {
    location: PropTypes.object
};
