import { useEffect } from 'react';
import PropTypes from 'prop-types';

const LogoutPage = ({ location }) => {
    useEffect(() => {
        // Clear login session cookies
        let query = '';
        if (location?.search) {
            query = (location.search[0] === '?' ? '' : '?') + location.search;
        }
        const returnTo = `${location.origin}/login${query}`;
        window.location.href = `${process.env.REACT_APP_API_BASE_URL}/logout?returnTo=${encodeURIComponent(returnTo)}`;
    }, [location]);

    return <></>;
};

export default LogoutPage;

LogoutPage.propTypes = {
    location: PropTypes.object
};
