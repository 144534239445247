import axiosModule from 'axios';
// import MockAdapter from 'axios-mock-adapter';

const baseURL = `${process.env.REACT_APP_API_BASE_URL}`;
export const axios = axiosModule.create({
    baseURL,
    timeout: 30000,
    withCredentials: true
});

//see https://github.com/ctimmerm/axios-mock-adapter for more details
// const mock = new MockAdapter(axios);
//
// mock.onPost('/login').reply(() => {
//     return new Promise((resolve) => {
//         resolve([200, { success: true }]);
//     });
// });
//
// mock.onPost('/register').reply(() => {
//     const randomNumber = Math.floor(Math.random() * 10);
//     return new Promise((resolve) => {
//         if (randomNumber < 3) {
//             resolve([200, { success: true, user: { email: 'test@test.com', firstName: 'test', lastName: 'testing' } }]);
//         } else if (randomNumber > 2 && randomNumber < 6) {
//             setTimeout(() => {
//                 resolve([500, { success: false, errors: [{
//                         title: 'Errors',
//                         messages: [
//                             'Your register attempt has failed. Please try again later.'
//                     ]}]
//                 }]);
//             }, 1500);
//         } else {
//             setTimeout(() => {
//                 resolve([404, { success: false, errors: [{
//                     title: 'Oups',
//                     messages: [
//                         'Network error occurred, and your request could not be completed. Please refresh the page.'
//                     ]}] }
//                 ]);
//             }, 1500);
//         }
//     });
// });
//
// mock.onPost('/login').reply(() => {
//     const randomNumber = Math.floor(Math.random() * 10);
//     return new Promise((resolve) => {
//         if (randomNumber < 3) {
//             resolve([200, { success: true, user: { email: 'test@test.com', firstName: 'test', lastName: 'testing' } }]);
//         } else if (randomNumber > 2 && randomNumber < 6) {
//             setTimeout(() => {
//                 resolve([500, { success: false, errors: [{
//                         title: 'Errors',
//                         messages: [
//                             'Your login attempt has failed. Make sure the username and password are correct.',
//                             'If you haven\'t previously logged into our new SSO, please <a href="/resetPassword">reset your password</a> first.'
//                     ]}]
//                 }]);
//             }, 1500);
//         } else {
//             setTimeout(() => {
//                 resolve([404, { success: false, errors: [{
//                     title: 'Oups',
//                     messages: [
//                         'Network error occurred, and your request could not be completed. Please refresh the page.'
//                     ]}] }
//                 ]);
//             }, 1500);
//         }
//     });
// });
//
// mock.onPost('/consent').reply(() => {
//     return new Promise((resolve) => {
//         window.setTimeout(() => {
//             resolve([200, { success: true }]);
//         }, 2000);
//     });
// });
//
// mock.onPost('/reset').reply(() => {
//     return new Promise((resolve) => {
//         window.setTimeout(() => {
//             resolve([200, { success: true }]);
//         }, 2000);
//     });
// });
