import { useMemo, useState, useEffect } from 'react';
import Input from '../../input/input';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';

import './profileSelection.scss';

const ProfileSelection = ({ fieldData, handlePrivacyChange, saving, consentData }) => {

    const [checked, setChecked] = useState(false);

    const productList = ((obj) => {
        // Store product names as array and ignore OCM
        const appNames = [];
        Object.keys(obj).forEach((key) => {
            if (key !== 'OCM') {
                appNames.push(consentData[key].name);
            }
        });
        // Join names, and remove last pair of comma + whitespace
        const str = appNames.join(', ').replace(/,\s*$/, '');

        return str;

    })(consentData);

    useEffect(() => {

        if (fieldData) {
            // Check if discard
            const discard = fieldData['change-privacy'] === false;

            // Check if user consented to all products
            const consentToAll = Object.keys(fieldData['privacy']).length >= Object.keys(consentData).length;

            // Take entries from fieldData privacy field (initial or current)
            // and return false either if entry with key !== OESISCloud (to remove on launch)
            // and value false exists, otherwise return true
            const check = consentToAll ? !Object.entries(discard ? fieldData['init-privacy'] : fieldData['privacy']).some((entry) => entry[0] !== 'OESISCloud' && entry[1] === false) : false;
            setChecked(check);
        }


    }, [fieldData]);

    const handleChange = (event) => {
        setChecked(!checked);
        handlePrivacyChange(event);
    };

    const privacyBox = useMemo(() => {
        const label = 'I agree to the OPSWAT Inc. <a href=\'https://www.opswat.com/legal\' target=\'_blank\'>Terms of Service and Privacy Policy</a>, unless my organization has a separate written agreement with OPSWAT Inc., in which case those separate terms shall apply.';
        if (fieldData) {
            return <Input
                componentType='checkbox'
                onChange={(evt) => handleChange(evt)}
                label={label}
                readOnly={saving}
                checked={checked} />;
        }

        return <ContentLoader height={40} width='100%' speed={1}>
            <rect x='0' y='10' rx='10' ry='10' width={`${20 + Math.random() * 80}%`} height='20' />
        </ContentLoader>;

    }, [fieldData, handlePrivacyChange, saving, checked]);

    return <div className='profileSelection'>
        <p className='privacyDesc'>By checking the box below, I activate access to the following applications: <i>{productList}</i>. By unchecking the box, I deactivate the access. </p>
        {privacyBox}
        <p className='privacyDesc'>Request for handling your data, go to <a href='https://go.opswat.com/myuserright' target='_blank' rel='noopener noreferrer'>My User Rights</a>.</p>
    </div>;
};

export default ProfileSelection;

ProfileSelection.propTypes = {
    fieldData: PropTypes.object,
    consentData: PropTypes.object,
    handlePrivacyChange: PropTypes.func,
    saving: PropTypes.bool
};
