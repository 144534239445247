import { useCallback, useEffect, useMemo, useState } from 'react';
import CardLayout from '../../components/layout/card-layout/cardLayout';
import { Button } from 'react-bulma-components';
import { axios } from '../../services/axios';
import { logService } from '../../services/LogService';
import { navigate } from '@reach/router';
import { organizationService } from '../../services/organizationService';
import { COMMON_ERROR_MESSAGE, ERRORS_CODE, ROLES } from '../../services/Constant';
import { Helmet } from 'react-helmet-async';
import 'bulma-pageloader';

const ResetIpWhitelist = () => {
    const [userId, setUserId] = useState();
    const [orgId, setOrgId] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isEnterprise, setIsEnterprise] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState([]);

    const orgUpdate = (orgData) => {
        if (orgData && orgData.id && orgData.roles?.some((role) => role === ROLES.admin.id)) {
            setOrgId(orgData.id);
            if (orgData.mdCloudLicenseType?.toLowerCase() === 'enterprise') {
                // If user is admin in enterprise organization
                setIsEnterprise(true);
            }
            return;
        }

        // Back to profile if no organization or user not admin
        navigate('/profile');
    };

    useEffect(() => {
        (async () => {
            try {
                // Load user profile
                let response = await axios.get('/profile?fields=user');
                if (response?.status === 200 && response?.data?.user?.userId) {
                    const userId = response.data.user.userId;
                    setUserId(userId);
                    // Register organization update event
                    organizationService.registerUpdateEvent(orgUpdate);
                    // Get user organization
                    await organizationService.getUserOrganization(userId);
                    return;
                }
            } catch (err) {
                logService.error(err);
                if (err?.response?.data?.error?.code === ERRORS_CODE.sessionError.code) {
                    await navigate('/login?redirect=' + encodeURIComponent(window.location.href));
                    return;
                }
            }

            // Back to profile if no user info
            await navigate('/profile');
        })();
    }, []);

    const onSubmit = useCallback((e) => {
        e && e.preventDefault();
        setIsLoading(true);
        setErrors([]);
        (async () => {
            try {
                const request = await axios.post(`/organization/reset-ips/${orgId}`, {}, {
                    headers: {
                        userpid: userId
                    }
                });
                if (request.status === 200) {
                    setSuccess(true);
                }
            } catch (err) {
                logService.error(err);
                setErrors([{
                    title: 'Error',
                    error: err,
                    messages: [COMMON_ERROR_MESSAGE]
                }]);
            }
            setIsLoading(false);
        })();
    }, [orgId, userId]);

    const contentDom = useMemo(() => {
        if (success) {
            return <span>Your IP allowlist has been reset successfully!</span>;
        }

        if (!isEnterprise) {
            return <span>This feature is available only on the enterprise licensing</span>;
        }

        return <>
            <span>Click "Submit" button below to reset and clear your IP allowlist.</span>
            <Button
                fullwidth={true}
                color='primary'
                onClick={onSubmit}
                disabled={isLoading}
                loading={isLoading}
            >
                Submit
            </Button>
        </>;
    }, [success, isEnterprise, isLoading, onSubmit]);

    if (!orgId) {
        return <div className='pageloader is-active'>
            <Helmet>
                <title>OPSWAT Accounts | Reset IP allowlist</title>
            </Helmet>
            <span className='title'>Loading...</span>
        </div>;
    }

    return <CardLayout
        pageTitle='OPSWAT Accounts | Reset IP allowlist'
        className='resetIps'
        heading={'Reset IP allowlist'}
        errors={errors}
        content={contentDom}/>;
};

export default ResetIpWhitelist;
