/* eslint-disable quotes */

export const ERRORS_CODE = {
    userSideError: { code: 40000, message: 'Client-side error occurred.' },
    captchaError: { code: 40001, message: 'Your reCAPTCHA is invalid.' },
    blockUserError: { code: 40002, message: 'Too many failed attempts, please try again later.' },
    rateLimitError: { code: 40003, message: 'Limit exceeded, please try again later.' },
    emailDomainBlockError: { code: 40004, message: 'Your email domain is not allowed to register.' },
    recoveryCodeMismatchException: { code: 40005, message: 'Your recovery code is invalid.' },

    // Error 400 from AWS
    expiredCodeException: { code: 40051, message: 'Your code has been expired.' },
    codeMismatchException: { code: 40052, message: `Your code is incorrect or the user doesn't exist.` },
    usernameExistsException: { code: 40053, message: 'An account with the given email already exists.' },
    passwordResetRequiredException: { code: 40054, message: 'Your account is required to be reset the password.' },
    userNotFoundException: { code: 40055, message: `The account doesn't exist.` },
    tooManyRequestsException: { code: 40056, message: 'Too many requests in a short period, please try again later.' },
    limitExceededException: { code: 40057, message: 'Limit exceeded, please try again later.' },
    tooManyFailedAttemptsException: { code: 40058, message: 'Too many failed attempts in a short period, please try again later.' },
    userNotConfirmedException: { code: 40059, message: `User isn't confirmed yet. Please confirm the account and try again.` },
    enableSoftwareTokenMFAException: { code: 40060, message: 'Error during enabling MFA.' },

    // Error 401
    notAuthorizedException: { code: 40100, message: 'Incorrect username or password.' },
    sessionError: { code: 40101, message: 'Your login session has been expired, please login again.' },
};

export const errorMessageMap = (errorCode) => {
    const key = Object.keys(ERRORS_CODE).find((key) => ERRORS_CODE[key].code === errorCode);
    if (key) {
        return ERRORS_CODE[key].message;
    }
};
export const COMMON_ERROR_MESSAGE = 'Your attempt has failed. Please try again.';
export const NETWORK_ERROR_MESSAGE = 'Network error occurred, and your request could not be completed. Please refresh the page.';

export const ROLES = {
    user: {
        label: 'User',
        id: 'ixajqbm49o'
    },
    admin: {
        label: 'Admin',
        id: 'kcgor9le52'
    }
};

export const colors = {
    foundColor: '#3D4A68',
    notFoundColor: '#9C9C9C',
    areaColor: '#194ca7',
    infectedColor: '#FB211E',
    cleanColor: '#04cb04',
    suspiciousColor: '#FDB00D',
    sanitizedColor: '#7f00ff'
};

export const SESSION_COOKIE = '__opswat-session-login';
export const REFRESH_COOKIE = '__opswat-refresh-login';
export const PAYLOAD_COOKIE = '__opswat-payload-login';
