// file deepcode ignore PrototypePollutionFunctionParams
/* eslint-disable camelcase */
import { axios } from './axios';
import { errorTraceID } from './utility';
import { logService } from './LogService';
import organizationErrors from './organizationErrors';
class Oauth2ClientService {

    async listOauth2Clients(orgId) {
        try {
            const response = await axios.get(`/v2/organizations/${orgId}/oauth2clients`);
            return { data: response.data.oauth2clients };
        } catch (err) {
            logService.error(err);
            return {
                error: true,
                messages: [organizationErrors.listOauth2ClientErrors(), errorTraceID(err, true)],
            };
        }
    }

    async postOauth2Client(orgId, newClientInput) {
        try {
            const response = await axios.post(`/v2/organizations/${orgId}/oauth2clients`, { ...newClientInput });
            return response;
        } catch (err) {
            logService.error(err);
            return {
                error: true,
                messages: [organizationErrors.postOauth2ClientErrors(err.message), errorTraceID(err, true)],
            };
        }
    }

    async deleteOauth2Client(orgId, clientId) {
        try {
            const response = await axios.delete(`/v2/organizations/${orgId}/oauth2clients/${clientId}`);
            return response;
        } catch (err) {
            logService.error(err);
            return {
                error: true,
                messages: [organizationErrors.deleteOauth2ClientErrors(), errorTraceID(err, true)],
            };
        }
    }
}

export const oauth2clientService = new Oauth2ClientService();
