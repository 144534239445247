import { axios } from './axios';
import { errorTraceID } from './utility';
import { logService } from './LogService';
import organizationErrors from './organizationErrors';


class UserService {

    async getUser(userId) {
        try {
            let response = await axios.get(`/v2/users/${userId}`);

            if (response && response.data) {
                return { error: false, data: response.data };
            }
        } catch (err) {
            logService.error(err);
            return {
                error: true,
                messages: [organizationErrors.getUserErrors(err?.response?.data?.code), errorTraceID(err, true)],
            };
        }
    }

    async updateUser(userId, updateData) {
        try {
            await axios.patch(`/v2/users/${userId}`, {
                organizationUserData: {
                    ...updateData
                }
            });

            return { error: false };
        } catch (err) {
            logService.error(err);
            return {
                error: true,
                err: err.response.data,
                messages: [organizationErrors.updateUserErrors(err.response.data.error.code) + ` ${err.response.data.error.details}`, errorTraceID(err, true)],
            };
        }
    }
}

export const userService = new UserService();
