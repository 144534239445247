import { Fragment, useMemo } from 'react';
import { Heading, Table, Card, } from 'react-bulma-components';
import ContentLoader from 'react-content-loader';
import moment from 'moment';
import PropTypes from 'prop-types';

import './licensesTable.scss';

const DATE_FORMAT = 'MMM D, YYYY';

const getLicenseLimitsTimeInterval = (product) => {
    switch (product.licenseLimitsTimeInterval) {
        case 'monthly':
            return 'month';
        case 'hourly':
            return 'hour';
        case 'daily':
        default:
            return 'day';
    }
};

const getMdcLicenseType = (product) => {
    switch (product.mdCloudLicenseType?.toLowerCase()) {
        case 'enterprise':
            return 'Enterprise';
        case 'commercial':
            return 'Commercial';
        case 'professional':
        default:
            return 'Professional';
    }
};

const MDC_METADATA = {
    name: 'MetaDefender Cloud',
    rows: [
        {
            header: 'Prevention',
            show: (product) => product.preventionApiLimit,
            parse: (product) => <span><b>{product.preventionApiLimit}</b> requests per {getLicenseLimitsTimeInterval(product)}</span>
        },
        {
            header: 'Reputation',
            show: (product) => product.reputationApiLimit,
            parse: (product) => <span><b>{product.reputationApiLimit}</b> requests per {getLicenseLimitsTimeInterval(product)}</span>
        },
        {
            header: 'Sandbox',
            show: (product) => product.sandboxApiLimit,
            parse: (product) => <span><b>{product.sandboxApiLimit}</b> requests per {getLicenseLimitsTimeInterval(product)}</span>
        },
        {
            header: 'Feed',
            show: (product) => product.dailyFeedLimit,
            parse: (product) => <span><b>{product.dailyFeedLimit}</b> Hashes per {getLicenseLimitsTimeInterval(product)}</span>
        },
        {
            header: 'Analysis',
            show: (product) => product.mdc_limit_analysis,
            parse: (product) => <span><b>{product.mdc_limit_analysis}</b> requests per {getLicenseLimitsTimeInterval(product)}</span>
        },
        {
            header: 'License Expiration Date',
            show: (product) => product.licenseExpiryDate,
            parse: (product) => <span><b>{moment(product.licenseExpiryDate).format(DATE_FORMAT)}</b></span>
        },
        {
            header: 'Metadefender Cloud License Type',
            show: () => true,
            parse: (product) => <span><b>{getMdcLicenseType(product)}</b></span>
        },
        {
            header: 'Throttle',
            show: (product) => product.mdCloudThrottle,
            parse: (product) => <span><b>{product.mdCloudThrottle}</b></span>
        },
    ]
};

const DASHBOARD_URL = `${process.env.REACT_APP_app_href_MDC}/account`;

const LicensesTable = ({ organizationData }) => {

    const licenseCard = useMemo(() => {
        if (organizationData) {
            const product = organizationData;
            return (
                <Table>
                    <tbody>
                        {MDC_METADATA.rows.filter((row) => row.show(product)).map((row, index) => <Fragment key={index}>
                            <tr>
                                <td>{row.header}</td>
                                <td className='hidden-xs'>{row.parse(product)}</td>
                            </tr>
                            <tr className='show-xs'>
                                <td>{row.parse(product)}</td>
                            </tr>
                        </Fragment>)}
                    </tbody>
                </Table>
            );
        }

        return <ContentLoader
            height={100}
            width='100%'
            speed={1}
        >
            <rect x='0' y='0' rx='10' ry='10' width={'100%'} height='15' />
            <rect x='0' y='30' rx='10' ry='10' width={'80%'} height='15' />
            <rect x='0' y='60' rx='10' ry='10' width={'80%'} height='15' />
        </ContentLoader>;
    }, [organizationData]);


    const mTLSLinkDom = useMemo(() => {
        if (!organizationData) {
            return <ContentLoader
                height={40}
                width='100%'
                speed={1}
            >
                <rect x='0' y='0' rx='10' ry='10' width={`${20 + 10 * Math.random()}%`} height='20' />
            </ContentLoader>;
        }

        if (!organizationData.mTLS) {
            return;
        }

        return <div className='orgHalfHeadline'>
            <p className='bold mr-2'>API Domain:</p>
            <p className='selectAll'>{organizationData.mTLS}</p>
        </div>;
    }, [organizationData]);


    return <div className='licensesTable'>
        <Heading subtitle renderAs={'h2'} size={6}>Licenses</Heading>
        <Card>
            <Card.Header>
                <Heading subtitle renderAs={'h2'} size={6}>{MDC_METADATA.name}</Heading>
            </Card.Header>
            <Card.Content>
                {licenseCard}
            </Card.Content>
            <Card.Footer>
                <Card.Footer.Item><a href={DASHBOARD_URL} target='_blank' rel='noopener noreferrer'>Dashboard <i className='fas fa-external-link-alt' /></a></Card.Footer.Item>
            </Card.Footer>
        </Card>
        {mTLSLinkDom}
    </div >;
};

export default LicensesTable;

LicensesTable.propTypes = {
    organizationData: PropTypes.object,
    userId: PropTypes.string
};
