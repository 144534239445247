import TagManager from 'react-gtm-module';

class GtmService {
    constructor() {
        this.isInit = false;
    }

    init() {
        if (!this.isInit) {
            if (process.env.REACT_APP_GTM_ID && process.env.REACT_APP_GTM_AUTH && process.env.REACT_APP_GTM_PREVIEW && ['qa', 'staging', 'prod'].includes(process.env.REACT_APP_ENVIRONMENT.toLowerCase())) {
                TagManager.initialize({
                    gtmId: process.env.REACT_APP_GTM_ID,
                    auth: process.env.REACT_APP_GTM_AUTH,
                    preview: process.env.REACT_APP_GTM_PREVIEW
                });
                this.isInit = true;
            }
        }
    }

    sendRouteChangeEvent(page, additionInfo = {}) {
        if (this.isInit) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'route-change',
                    page,
                    ...additionInfo
                },
            });
        }
    }
}

export default new GtmService();
