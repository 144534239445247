import { useCallback, useMemo, useState } from 'react';
import { Button } from 'react-bulma-components';
import { axios } from 'services/axios';
import Input from '../../components/input/input';
import Validator from '../../services/Validator';
import { logService } from '../../services/LogService';
import { COMMON_ERROR_MESSAGE, errorMessageMap, ERRORS_CODE, NETWORK_ERROR_MESSAGE } from '../../services/Constant';
import { Link, useLocation } from '@reach/router';
import QueryParametersParser from '../../services/QueryParametersParser';
import { getAppNameByID } from '../../services/utility';
import ActionLayout from '../../components/layout/action-layout/actionLayout';
import PropTypes from 'prop-types';

const ConfirmEmail = (props) => {
    const { search } = useLocation();
    const [email, setEmail] = useState(props?.location?.state?.email ?? '');

    const [loading, setLoading] = useState(false);
    const [isResend, setIsResend] = useState(!!props?.location?.state?.tryResend);
    const [isResent, setIsResent] = useState(false);

    const [requestError, setRequestError] = useState([]);
    const errors = useMemo(() => !Validator.isEmail(email) && email ? ['Invalid Email Address'] : [], [email]);
    const query = useMemo(() => QueryParametersParser.parse(search), [search]);
    const appName = useMemo(() => getAppNameByID(query?.app), [query]);

    const onSubmit = useCallback((e) => {
        e && e.preventDefault();
        setLoading(true);
        setRequestError([]);
        (async () => {
            try {
                const request = await axios.post('/resend', { email, query });
                if (request.status === 200) {
                    setIsResent(true);
                }
            } catch (err) {
                logService.error(err);
                let title;
                const messages = [];
                if ((err?.response?.status >= 400 && err?.response?.status < 500) || errorMessageMap(err?.response?.data?.error?.code)) {
                    title = 'Errors';
                    messages.push(COMMON_ERROR_MESSAGE);
                } else {
                    title = 'Oups';
                    messages.push(NETWORK_ERROR_MESSAGE);
                }

                if (err?.response?.data?.error?.code !== ERRORS_CODE.notAuthorizedException.code) {
                    messages.push(errorMessageMap(err?.response?.data?.error?.code));
                }

                setRequestError([{
                    title,
                    error: err,
                    messages
                }]);
            }
            setLoading(false);
        })();
    }, [email, query]);

    const message = useMemo(() => {
        if (!isResend) {
            return { title: 'Check your email to confirm your Account', content: 'Thank you for registering. Please confirm your account by clicking the button in your email.' };
        }

        return { title: 'Resend the activation email', content: isResent ? 'Resent activation email.' : '' };
    }, [isResend, isResent]);

    const question = useMemo(() => {
        if (!isResend) {
            const onClick = (e) => {
                e && e.preventDefault();
                setIsResend(true);
                setIsResent(false);
            };

            return <>
                <p>
                    <span>Didn’t get the activation email? Please check your spam folder or click </span>
                    <a href={'/confirmEmail' + QueryParametersParser.toString(query)} onClick={onClick}>
                        here
                    </a>
                    <span> to resend the email.</span>
                </p>
                <p>
                    <span>If no email is received within ten minutes, check that the submitted address is correct or try to </span>
                    <Link to={'/resetPassword' + QueryParametersParser.toString(query)} state={{ email }}>reset your password</Link>
                    <span>.</span>
                </p>
            </>;
        } else if (isResent) {
            return <p>
                <span>If still has no email is received within ten minutes, check that the submitted address is correct or try to </span>
                <Link to={'/resetPassword' + QueryParametersParser.toString(query)} state={{ email }}>reset your password</Link>
                <span>.</span>
            </p>;
        }
    }, [isResend, isResent, query, email]);

    const dom = useMemo(() => {
        if (isResend && !isResent) {
            return <>
                <Input
                    label='Please enter your registered email address'
                    name='email'
                    type='text'
                    onChange={(e) => setEmail(e.target.value)}
                    errors={errors}
                    value={email}
                    autoFocus={true}
                    disabled={loading}
                />
                <Button
                    fullwidth={true}
                    color='primary'
                    disabled={!email || errors?.length || loading}
                    loading={loading}
                    className={'form--button'}
                    onClick={onSubmit}
                >
                    Submit
                </Button>
            </>;
        }

        return <></>;
    }, [email, errors, isResend, isResent, loading, onSubmit]);

    const pageTitle = useMemo(() => isResend ? 'OPSWAT Accounts | Resend Email' : 'OPSWAT Accounts | Thank you', [isResend]);

    return (
        <ActionLayout className='confirmPage' app={appName} hideTabs={true} pageTitle={pageTitle} message={message} question={question} errors={requestError}>
            {dom}
        </ActionLayout>
    );
};

export default ConfirmEmail;

ConfirmEmail.propTypes = {
    location: PropTypes.object
};
