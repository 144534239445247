/* eslint-disable camelcase */

/**
 * Validates the REACT_APP_ENVIRONMENT env variable value
 * @returns REACT_APP_ENVIRONMENT safe value
 */
function getENVIRONMENT() {
    return ['local', 'dev', 'qa', 'staging', 'prod'].includes(process.env.REACT_APP_ENVIRONMENT) ? process.env.REACT_APP_ENVIRONMENT : 'prod';
}

/**
 * Validates the REACT_APP_MA_LOGIN_URL env variable value
 * @returns REACT_APP_MA_LOGIN_URL safe value
 */
function getMA_LOGIN_URL() {
    return process.env.REACT_APP_MA_LOGIN_URL || 'https://console.metaaccess-b.opswat.com/signin';
}

/**
 * Validates the REACT_APP_MA_REGISTER_URL env variable value
 * @returns REACT_APP_MA_REGISTER_URL safe value
 */
function getMA_REGISTER_URL() {
    return process.env.REACT_APP_MA_REGISTER_URL || 'https://console.metaaccess-b.opswat.com/register';
}

/**
 * Safe process.env varaibles
 */
export default {
    ENVIRONMENT: getENVIRONMENT(),
    MA_LOGIN_URL: getMA_LOGIN_URL(),
    MA_REGISTER_URL: getMA_REGISTER_URL(),
};
