/* eslint-disable camelcase */
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import './oauth2ClientEntry.scss';

const Oauth2ClientEntry = ({ client, setOauth2client }) => {

    const [isRevealed, toggleIsRevealed] = useState(false);

    async function copyToClipboard(secret_id) {
        await navigator.clipboard.writeText(secret_id);
    }

    async function updateClientToChecked() {
        client.checked = !client.checked;
        await setOauth2client((prevValue) => prevValue.map((entry) => {
            if (entry.clientId === client.clientId) {
                entry.checked = client.checked;
            }
            return entry;
        }));
    }

    const clientSecret = useMemo(() => (
        <td>
            <div className='cellContainer'>
                <span onClick={async () => toggleIsRevealed(!isRevealed)}>
                    {isRevealed ?
                        <div className='clientSecret showSecrets'>{client.clientSecret}</div> :
                        <div className='clientSecret hideSecrets'>Retrieve Client Secret</div>
                    }
                </span>
            </div>
        </td>
    ));

    return (
        <tr>
            <td>
                <div className='cellContainer clientNameContainer'>
                    {client.clientName}
                </div>
            </td>

            <td className='has-text-weight-semibold'>
                <div className='cellContainer clientIdContainer'>
                    <span className='clientIdText'>{client.clientId}</span>
                    <span onClick={async () => copyToClipboard(client.clientId)} className='icons copyIcon'><i className="far fa-clone" /></span>
                </div>
            </td>
            {clientSecret}
            <td>
                <input type="checkbox" onChange={updateClientToChecked} checked={client.checked} className='checkbox is-pulled-right cellContainer'></input>
            </td>
        </tr>
    );
};

Oauth2ClientEntry.propTypes = {
    client: PropTypes.object,
    setOauth2client: PropTypes.func
};

Oauth2ClientEntry.defaultProps = {
    client: {},
    setOauth2client: () => { }
};

export default Oauth2ClientEntry;
