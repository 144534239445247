import { useEffect, useMemo, useState } from 'react';
import FreshestLayout from '../../components/layout/freshest-layout/freshestLayout';
import ProfileInfo from '../../components/profile/information/profileInfo';
import ProfileLicenses from '../../components/profile/product/profileLicenses';
import MfaPage from '../../components/profile/mfa/mfaPage';
import OrganizationPage from 'components/organization/organizations/organizationPage';
import UsersPage from '../../components/organization/users/usersPage';
import SecurityPage from 'components/organization/security/securityPage';
import { navigate } from '@reach/router';
import { profileService } from '../../services/profileService';
import { organizationService } from '../../services/organizationService';
import { ROLES } from '../../services/Constant';
import Page404 from '../404/404';
import '../../components/organization/common/orgTable.scss';
import licensesImg from '../../assets/images/menu/licenses.png';
import organisationsImg from '../../assets/images/menu/organisations.svg';
import userMgmtImg from '../../assets/images/menu/userMgmt.svg';
import policiesImg from '../../assets/images/menu/policies.svg';
import PropTypes from 'prop-types';

import './profile.scss';

const TYPES = {
    organization: 'organization',
    profile: 'profile'
};

const Profile = ({ pageType, page, orgId }) => {

    const [mfaInfo, setMfaInfo] = useState();
    const [userId, setUserId] = useState();
    const [isIdpUser, setIsIdpUser] = useState();
    const userInfoUpdate = (info) => {
        setMfaInfo(info['mfa']);
        setUserId(info['user']?.userId);
        setIsIdpUser(info['user']?.issuerID || null);
    };

    const [organizationData, setOrganizationData] = useState();
    const [needsUpdate, setNeedsUpdate] = useState(false);
    const orgUpdate = (data) => setOrganizationData(data);


    // Get user data
    useEffect(() => {
        // Register info update event
        profileService.registerUpdateEvent(userInfoUpdate);

        // Load information
        (async () => {
            await profileService.loadInfo();
        })();

        return () => profileService.unregisterUpdateEvent(userInfoUpdate);
    }, []);

    useEffect(() => {
        if (pageType !== 'organization') {
            setOrganizationData(undefined);
            setNeedsUpdate(!needsUpdate);
        }
    }, [pageType]);


    //Get organization data
    useEffect(() => {
        // Register organization update event
        organizationService.registerUpdateEvent(orgUpdate);
        if (orgId && userId) {
            // Get sub-organization
            setOrganizationData(undefined);
            (async () => {
                await organizationService.getOrganization(userId, orgId, true);
            })();
        } else if ((!organizationData && userId)) {
            // Get user organization
            (async () => {
                await organizationService.getUserOrganization(userId);
            })();
        }

        return () => organizationService.unregisterUpdateEvent(orgUpdate);
    }, [userId, orgId, needsUpdate]);

    const isAdmin = useMemo(() => organizationData?.roles?.some((roleId) => ROLES.admin.id === roleId), [organizationData]);

    const onMenuClick = useMemo(() => (menu) => {
        if ((menu.parent === TYPES.organization)) {
            navigate(`/${menu.parent}` + (organizationData?.id ? '/' : '') + organizationData.id + '/' + menu.page);
        } else if (menu.page !== (page || '') || menu.parent !== pageType) {
            navigate(`/${menu.parent}` + (menu.page ? '/' : '') + menu.page);
        }
    }, [page, pageType, organizationData]);

    useEffect(() => {
        if (mfaInfo?.isMfaForced && page !== 'mfa') {
            navigate('/profile/mfa');
        } else if (isIdpUser && page === 'mfa') {
            navigate('/profile');
        }
    }, [page, mfaInfo, isIdpUser]);

    const basicMenuItems = useMemo(() => {
        const menus = [
            {
                label: 'User profile',
                page: '',
                icon: <i className='far fa-user' />,
                parent: TYPES.profile
            },
            {
                label: 'Licenses',
                page: 'licenses',
                icon: <img src={licensesImg} alt='licenses' />,
                parent: TYPES.profile
            }
        ];

        return menus.map((menu) => (
            {
                ...menu,
                // Disable menu when force to config MFA
                disabled: !!mfaInfo?.isMfaForced
            }
        ));
    }, [mfaInfo]);

    const mfaMenuItems = useMemo(() => {
        return [{
            label: 'MFA',
            page: 'mfa',
            icon: <i className='fas fa-fingerprint' />,
            parent: TYPES.profile,
            // Show loading when check if user is login from IDP or not
            loading: isIdpUser === undefined,
            // Hide menu if user is login from IDP
            hide: !!isIdpUser,
        }];
    }, [isIdpUser]);

    const organizationMenuItems = useMemo(() => {
        const menu = {
            label: 'Organization',
            page: 'overview',
            icon: <img src={organisationsImg} alt='organisations' />,
            parent: TYPES.organization,
            subs: [
                {
                    label: 'Users',
                    page: 'users',
                    icon: <img src={userMgmtImg} alt='userMgmt' />,
                    parent: TYPES.organization
                },
                {
                    label: 'Security',
                    page: 'security',
                    icon: <img src={policiesImg} alt='security' />,
                    parent: TYPES.organization,
                    // Disable menu when user is not org admin
                    disabled: !!(organizationData && !isAdmin),
                    // Show loading when not load org data yet
                    // loading: organizationData === undefined,
                    // Hide menu when if doesn't have org data
                    // hide: organizationData === null,
                }
            ]
        };

        return [
            {
                ...menu,
                // Disable menu when force to config MFA
                disabled: !!mfaInfo?.isMfaForced,
                // Show loading when not load org data yet
                loading: organizationData === undefined,
                // Hide menu when if doesn't have org data
                hide: organizationData === null,
                // Only show subs menu if user is admin
                subs: menu.subs.map((sub) => ({ ...sub, hide: organizationData === null || (organizationData && !isAdmin) }))
            }
        ];
    }, [mfaInfo, organizationData, isAdmin]);

    const menuItems = useMemo(() => basicMenuItems.concat(mfaMenuItems).concat(organizationMenuItems), [basicMenuItems, mfaMenuItems, organizationMenuItems]);

    useEffect(() => {
        // Redirect users to the correct pages
        // If trying to access a link which is not available for them
        let navigateUrl;
        menuItems.forEach((parentMenu) => {
            if (parentMenu.hide && pageType === parentMenu.parent && (page || '') === parentMenu.page) {
                // If access to hidden main menu
                navigateUrl = '/profile';
            } else if (parentMenu.subs) {
                // Check if there are sub menus on main menu
                parentMenu.subs.forEach((sub) => {
                    if ((parentMenu.hide || sub.hide) && pageType === sub.parent && (page || '') === sub.page) {
                        // redirect to parent
                        navigateUrl = `/${parentMenu.parent}` + (parentMenu.page ? `/${parentMenu.page}` : '');
                    }
                });
            }
        });

        if (navigateUrl) {
            navigate(navigateUrl);
        }
    }, [pageType, page, menuItems]);


    const content = useMemo(() => {
        if (pageType !== TYPES.organization && orgId) {
            return null;
        }

        switch (pageType) {
            case TYPES.profile:
                switch (page) {
                    case 'licenses':
                        return <ProfileLicenses organizationData={organizationData} userId={userId} />;
                    case 'mfa':
                        return <MfaPage />;
                    default:
                        return !page ? <ProfileInfo /> : null;
                }
            case TYPES.organization:
                switch (page) {
                    case 'users':
                        return <UsersPage organizationData={organizationData} userId={userId} />;
                    case 'security':
                        return <SecurityPage organizationData={organizationData} userId={userId} />;
                    default:
                        return (!page || page === 'overview') ? <OrganizationPage userId={userId} organizationData={organizationData} orgId={orgId || null} /> : null;
                }
            default:
                return null;
        }
    }, [page, pageType, orgId, userId, organizationData]);

    const layout = useMemo(() => {

        if (!content) {
            return <Page404 />;
        }
        return (<FreshestLayout
            className='profile'
            pageTitle='OPSWAT Accounts | Profile'
            menuItems={menuItems}
            page={page || ''}
            onMenuClick={onMenuClick}
            type={pageType}
            content={
                <div className={'profile-container'}>
                    {content}
                </div>
            }
        />);
    }, [content]);

    return layout;
};

export default Profile;

Profile.propTypes = {
    orgId: PropTypes.string,
    pageType: PropTypes.string,
    page: PropTypes.string
};
