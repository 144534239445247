/* eslint-disable security/detect-unsafe-regex */
import isCidr from 'is-cidr';
import isIp from 'is-ip';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NAME_REGEX = /^[^`~!@#$%^&*()_+={}[\]|\\:/;“"'’<,>?๐฿]*$/;
const COMPANY_REGEX = /^[^`~#$%^*()+={}[\]|\\:;“"'’<>๐฿]*$/;
const PHONE_REGEX = /^\+\d{7,15}$/; // eg: "+14325551212"
const IP_RANGE_REGEX = /^([01]?\d\d?|2[0-4]\d|25[0-5])(?:\.(?:[01]?\d\d?|2[0-4]\d|25[0-5])){3}(?:\/[0-2]\d|\/3[0-2])?$/; // eg: "192.128.0.1/24"

class Validator {
    isEmail(email) {
        return EMAIL_REGEX.test(email);
    }

    isValidName(name) {
        return NAME_REGEX.test(name);
    }

    checkName(name) {
        let errors = [];
        if (!this.isValidName(name)) {
            return [...errors, 'Shouldn\'t include special character'];
        }
        if (name.length > 50) {
            return [...errors, 'Maximum 50 characters allowed'];
        }

        return errors;
    }

    isValidCompany(name) {
        return COMPANY_REGEX.test(name);
    }

    checkCompany(name) {
        let errors = [];
        if (!this.isValidCompany(name)) {
            return [...errors, 'Contains disallowed characters'];
        }
        if (name.length > 50) {
            return [...errors, 'Maximum 50 characters allowed'];
        }

        return errors;
    }

    makeUpPhoneNumber(number) {
        return number.replace(/['"\-_.\s]/g, '');
    }

    isValidPhoneNumber(number) {
        number = number.replace(/['"\-_.\s]/g, '');
        return PHONE_REGEX.test(number);
    }

    checkPassword(password) {
        if (password.length < 13) {
            return ['Minimum password length is 13'];
        } else if (password.length > 255) {
            return ['Maximum password length is 255'];
            // eslint-disable-next-line
        } else if (/[^\x00-\x7f]/.test(password)) {
            return ['Disallow non-ASCII characters'];
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/.test(password)) {
            return ['The password must contain at least one lowercase letter, one uppercase letter, one number and one special character'];
        }

        return [];
    }

    isValidTotp(totp) {
        return totp && (/^\d{6}$/.test(totp));
    }

    isValidRecoveryCode(code) {
        return code && (/^\w{5}-\w{5}$/.test(code));
    }

    isIpRange(ip) {
        // Input is IPv4/IPv6 or CIDR
        return ip && (isIp(ip) || isCidr(ip) || IP_RANGE_REGEX.test(ip));
    }
}

export default new Validator();
