import { errorMessageMap } from './Constant';

const getAppNameByID = (appId) => {
    if (appId) {
        const envKey = Object.keys(process.env).find((key) => process.env[key] === appId);
        if (envKey) {
            const match = envKey.match(/REACT_APP_appID_(\w+)/i);
            if (match.length === 2) {
                return match[1];
            }
        }
    }
};

const getTraceID = (error) => {
    if (error?.response?.headers) {
        return error.response.headers['x-amzn-trace-id'] || error.response.headers['X-Amzn-Trace-Id'];
    }
};

const errorTraceID = (error, ignoreStatus = false) => {
    let trace = undefined;
    if (typeof error === 'string') {
        trace = error;
    } else if (
        typeof error === 'object' &&
        error?.response?.headers &&
        (
            (error?.response?.data?.error?.code && !errorMessageMap(error?.response?.data?.error?.code)) || // There are error code but no mapping message
            error?.response?.status >= 500 ||   // 5xx status code
            ignoreStatus
        )
    ) {
        trace = getTraceID(error);
    }

    if (trace !== undefined) {
        const match = trace.match(/(\w+-\w+-\w+)/g);
        if (match && match.length) {
            return `Please contact our support and provide the error id: ${match[0]}`;
        } 
        return 'Please contact the support team.';
        
    }
};

export { getAppNameByID, errorTraceID, getTraceID };
