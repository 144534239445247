import QueryParametersParser from 'services/QueryParametersParser';
import CONFIG from '../services/config';

const { MA_LOGIN_URL, MA_REGISTER_URL } = CONFIG;

/**
 * Available MA redirect pages
 */
export const MA_PAGE = {
    LOGIN: 1,
    REGISTER: 2
};

/**
 * Any link that determines itself to be from MetaAccess should redirect to Metaaccess
 *
 * @link https://opswat.atlassian.net/browse/CSSO-766
 *
 * @param {string} queryString URL query paramteres string
 * @param {number} page one of MA_PAGE. Default MA_PAGE.LOGIN
 * @returns true if a redirect is triggered
 */
export default function useMARedirect(queryString, page = MA_PAGE.LOGIN) {
    const query = QueryParametersParser.parse(queryString);
    if (query?.app === 'appMA0001' && query?.CSSO766 === 'true') {
        const baseUrl = (page === MA_PAGE.LOGIN) ? MA_LOGIN_URL : MA_REGISTER_URL;
        window.location.href = `${baseUrl}${queryString}`;
        return true;
    }
}
