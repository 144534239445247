import { Navbar } from 'react-bulma-components';
import PropTypes from 'prop-types';

import './navbar.scss';

const CustomNavbar = ({ navContent }) => {
    return <Navbar>
        <Navbar.Brand>
            <Navbar.Item renderAs='a' href={'/' + (window.location.search || '')} aria-label='logo'>
                <div className='logo'/>
            </Navbar.Item>
        </Navbar.Brand>
        {navContent && <Navbar.Menu>
            <Navbar.Container position='end'>
                {navContent}
            </Navbar.Container>
        </Navbar.Menu>}
    </Navbar>;
};

export default CustomNavbar;

CustomNavbar.propTypes = {
    navContent: PropTypes.node
};
