import { useMemo } from 'react';
import { useLocation } from '@reach/router';
import classname from 'classnames';
import SimpleMessages from '../../common/simple-messages/simpleMessages';
import HomeLayout from '../homeLayout';
import productData from '../../../services/productData';
import PropTypes from 'prop-types';

import './formLayout.scss';

const FormLayout = ({ className, children, app, errors, info, pageTitle, hideTabs }) => {
    const { pathname } = useLocation();
    // eslint-disable-next-line no-prototype-builtins
    const productInfo = useMemo(() => productData.hasOwnProperty(app) ? productData[app] : productData.OCM, [app]);

    const loginFormHeader = useMemo(() => {
        if (!hideTabs) {
            return <div className='formHeader'>
                <p className='h3'>{pathname === '/login' ? 'Sign in' : pathname === '/register' ? 'Create your OPSWAT Account' : ''}</p>
                <p className='appName'>to continue to <strong>{productInfo.shortname}</strong></p>
            </div>;
        }
    }, [hideTabs, pathname, productInfo]);

    return <HomeLayout className={classname('formLayout', className)} app={app} pageTitle={pageTitle}>
        <form className='form--wrapper'>
            {loginFormHeader}
            <SimpleMessages messageList={info} type='success'/>
            <SimpleMessages messageList={errors}/>
            <div className='form--inputs'>
                {children}
            </div>
        </form>
    </HomeLayout>;
};

export default FormLayout;

FormLayout.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    app: PropTypes.string,
    errors: PropTypes.array,
    info: PropTypes.array,
    pageTitle: PropTypes.string,
    hideTabs: PropTypes.bool
};
