import { Card, Heading } from 'react-bulma-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SSOLayout from '../ssoLayout';
import SimpleMessages from '../../common/simple-messages/simpleMessages';

import './cardLayout.scss';

const CardLayout = ({ pageTitle, className, type, heading, content, errors }) => {
    return <SSOLayout layoutClass={classNames(`${className}--page`, 'cardLayout')} fullGray={true} pageTitle={pageTitle}>
        <Card className={classNames(`${className}--card`, 'cardContainer')}>
            <Card.Content>
                {heading && <Heading className={type}>
                    {heading}
                </Heading>}
                <SimpleMessages messageList={errors}/>
                {content && <form className='content'>
                    {content}
                </form>}
            </Card.Content>
        </Card>
    </SSOLayout>;
};

export default CardLayout;

CardLayout.propTypes = {
    pageTitle: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    heading: PropTypes.any,
    content: PropTypes.any,
    errors: PropTypes.array
};
