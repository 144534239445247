import { Fragment, useState, useMemo, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import { Button, Columns, Form, Table } from 'react-bulma-components';
import className from 'classnames';
import SimpleMessages from '../../common/simple-messages/simpleMessages';
import AddEntry from '../../common/add-entry/addEntry';
import { organizationService } from 'services/organizationService';
import OrganizationsTableEntry from './organizationsTableEntry';
import PropTypes from 'prop-types';
import Validator from 'services/Validator';

import './organizationsTable.scss';
const { Control, Input } = Form;

const OrganizationsTable = ({ orgId, userId, isAdmin, organizationData }) => {

    const [newOrgInput, setNewOrgInput] = useState({ name: '' });
    const [showAddOrgModal, setShowAddOrgModal] = useState(false);
    const [loading, setLoading] = useState({});
    const [errors, setErrors] = useState();
    const [success, setSuccess] = useState();
    const [organization, setOrganization] = useState();
    const [childOrganizations, setChildOrganizations] = useState();
    const [reachedRankLimit, setReachedRankLimit] = useState();
    const [smallName, setSmallName] = useState();

    useEffect(() => {

        setOrganization(organizationData);
        setChildOrganizations(organizationData?.subOrganizations);
        const reachRank = organizationData?.rank >= 5;
        setReachedRankLimit(reachRank);
        setSmallName(true);

    }, [organizationData]);


    // Clearing error and success messages
    // when navigating to another page
    useEffect(() => {

        setErrors();
        setSuccess();

    }, [orgId]);

    const modalAddOrg = useMemo(() => {

        if (!showAddOrgModal) {
            return null;
        }

        const handleChange = (e) => {

            const enteredName = e.target.value;
            setNewOrgInput({ ...newOrgInput, name: enteredName });
        };

        const addHandler = () => {

            if (newOrgInput.name && organizationData && !Validator.checkName(newOrgInput.name)?.length) {

                (async () => {

                    const children = childOrganizations;
                    setChildOrganizations(undefined);
                    const newOrgName = newOrgInput.name;
                    // Call to v2 enpoint for sub-org creation
                    const result = await organizationService.createOrganization(organizationData.id, newOrgName);

                    if (result.error) {
                        // Add fail
                        setChildOrganizations(children);
                        setErrors(result.messages);
                    } else {
                        await organizationService.getOrganization(userId, organizationData.id, true);
                        setSuccess([`Organization '${newOrgName}' added successfully!`]);
                    }

                    setLoading(undefined);
                })();

                setShowAddOrgModal(false);
                setLoading({ adding: newOrgInput.name });
            }
        };
        const validateOrgName = (name) => {
            if (Validator.checkName(name)?.length || !name) {
                return 'invalidInput';
            }

            return 'validInput';
        };
        const newOrgNameInput = <Control>
            <Input
                className={className(validateOrgName(newOrgInput.name))}
                value={newOrgInput.name}
                onChange={handleChange}
                onKeyPress={(e) => e.key === 'Enter' ? addHandler() : undefined}
                placeholder={'Organization Name'}
                autoFocus={true} />
        </Control>;

        const addBtn = <Button color='info' onClick={addHandler}>
            Add
        </Button>;

        const cancelBtn =
            <Button onClick={() => setShowAddOrgModal(false)}>
                Cancel
            </Button>;

        return <AddEntry
            inputsDom={newOrgNameInput}
            buttonsDom={
                <>
                    {addBtn}
                    {cancelBtn}
                </>
            }
        />;
    }, [orgId, userId, showAddOrgModal, newOrgInput, loading]);

    const addOrgDom = useMemo(() => {
        if (isAdmin && !reachedRankLimit) {

            const addHandler = () => {
                setNewOrgInput({ name: '' });
                setErrors();
                setSuccess();
                setShowAddOrgModal((showAddOrgModal) => !showAddOrgModal);
            };
            return <a onClick={addHandler} className="headerLink"> <i className="fas fa-plus" /> Add Organization</a>;
        }
    }, [isAdmin, loading, reachedRankLimit]);



    const tableContentDom = useMemo(() => {

        let dom;

        if (childOrganizations && Array.isArray(childOrganizations)) {

            dom = <>
                {!childOrganizations.length &&
                    <tr>
                        <td colSpan={7}>
                            No child organizations added. Click on "Add organization" button to start managing your own organizations.
                        </td>
                    </tr>}
                {childOrganizations.map((org, index) => <Fragment key={index}>
                    <OrganizationsTableEntry organizationData={org} parentId={organization.id} setErrors={setErrors} setSuccess={setSuccess} smallName={smallName} />
                </Fragment>)}
            </>;
        } else {

            dom = Array(5).fill('').map((v, index) => <Fragment key={index}>
                <tr>
                    <td colSpan={6}>
                        <ContentLoader height={20} width='100%' speed={1}>
                            <rect x='0' y='0' rx='10' ry='10' width={`${40 + 60 * Math.random()}%`} height='20' />
                        </ContentLoader>
                    </td>
                </tr>
            </Fragment>);
        }
        return dom;
    }, [childOrganizations, orgId, smallName]);

    const nameHeading = useMemo(() => {
        let icon;

        if (smallName) {
            icon = <div key={'goBig'} >
                <i className="fas fa-caret-right" />
            </div >;
        } else {
            icon =
                <div key={'goSmall'}>
                    <i className="fas fa-caret-left" />
                </div>;
        }

        const addHandler = async () => {
            setSmallName(!smallName);
        };

        return <td className='orgTableName' onClick={addHandler}>
            <p>Name</p>
            {icon}
        </td>;

    }, [smallName]);



    // If organization data is loaded and the user
    // is not an admin OR the organization reached the max rank (5)
    // render organization table as NULL
    if (organizationData && (!isAdmin || reachedRankLimit)) {
        return null;
    }

    return <div className='organizationsTable'>
        <Columns className='pageHeader' breakpoint='mobile'>
            <Columns.Column className="headerCol">
                <h2 className='subtitle is-6'>Sub Organizations</h2>
            </Columns.Column>
            <Columns.Column className="headerCol">
                {addOrgDom}
            </Columns.Column>
        </Columns>
        <SimpleMessages messageList={errors || success} type={errors ? 'danger' : 'success'} />
        <div className='orgTable xScrollTable'>
            <Table>
                <tbody>
                    <tr>
                        {nameHeading}
                        <td>Prevention</td>
                        <td>Reputation</td>
                        <td>Sandbox</td>
                        <td>Feed</td>
                        <td>Analysis</td>
                        <td>Manage</td>
                    </tr>
                    {/* TO IMPLEMENT ORGANIZATION SEARCH
                    {searchDom} */}
                    {modalAddOrg}
                    {tableContentDom}
                </tbody>
            </Table>

            {/* TO IMPLEMENT ORGANIZATION DELETION
            {modalDeleteOrg} */}
        </div>
    </div >;
};

export default OrganizationsTable;

OrganizationsTable.propTypes = {
    orgId: PropTypes.string,
    userId: PropTypes.string,
    isAdmin: PropTypes.bool,
    organizationData: PropTypes.object

};
