import ErrorBoundary from './pages/error-boundary/errorBoundary';
import { Location, Router, Redirect } from '@reach/router';
import LoginPage from './pages/login/login';
import RegisterPage from './pages/register/register';
import ChangePassword from './pages/change-password/changePassword';
import ConsentPage from './pages/consent/consent';
import ResetPassword from './pages/reset-password/resetPassword';
import ConfirmEmail from './pages/confirm-email/confirmEmail';
import Active from './pages/active/active';
import LogoutPage from './pages/logout/logout';
import Profile from './pages/profile/profile';
import AcceptedInvite from './pages/accepted-invite/acceptedInvite';
import ResetIpWhitelist from './pages/reset-ip-whitelist/resetIpWhitelist';
import Page404 from './pages/404/404';
import { HelmetProvider } from 'react-helmet-async';
import gtmService from './services/gtmService';

function App() {
    return (
        <HelmetProvider>
            <ErrorBoundary>
                <Location>
                    {({ location }) => {
                        gtmService.sendRouteChangeEvent(location.pathname);
                        return <Router>
                            <Redirect from='/' to='/login' noThrow/>
                            <Redirect from='/opswatLogin' to='/login' noThrow/>
                            <Redirect from='/communityRegistration' to='/register' noThrow/>

                            <LoginPage path='login'/>
                            <LogoutPage path='/logout'/>
                            <RegisterPage path='register'/>
                            <ChangePassword path='changePassword/'/>
                            <ResetPassword path='resetPassword'/>
                            <ConsentPage path='consent'/>
                            <ConfirmEmail path='confirmEmail'/>
                            <Active path='active'/>
                            <Profile key={'profilePage'} path=':pageType'/>
                            <Profile key={'profilePage'} path=':pageType/:page'/>
                            <Profile key={'profilePage'} path=':pageType/:orgId/:page'/>
                            <AcceptedInvite path='accepted-invite'/>
                            <ResetIpWhitelist path='reset-ip-whitelist'/>

                            <Page404 default/>
                        </Router>;
                    }}
                </Location>
            </ErrorBoundary>
        </HelmetProvider>
    );
}

export default App;
