import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const FormPost = ({ inputs, entityEndpoint }) => {
    const formRef = useRef(null);

    useEffect(() => {
        if (formRef?.current && inputs && entityEndpoint) {
            formRef.current.submit();
        }
    }, [formRef, inputs, entityEndpoint]);

    if (inputs && entityEndpoint) {
        return <>
            <form ref={formRef} name="form_post" method="post" action={entityEndpoint} style={{ display: 'none' }}>
                {inputs.map((input, index) => <input key={index} type="hidden" name={input.name} value={input.value}/>)}
            </form>
        </>;
    }

    return null;
};

export default FormPost;

FormPost.propTypes = {
    inputs: PropTypes.array,
    entityEndpoint: PropTypes.string
};
