import { navigate } from '@reach/router';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import { useState, useMemo, useEffect } from 'react';
import className from 'classnames';
import { Form } from 'react-bulma-components';
import { organizationService } from 'services/organizationService';
import { ROLES } from '../../../services/Constant';

import './organizationsTableEntry.scss';

const { Input, Control } = Form;


function OrganizationsTableEntry({ organizationData, parentId, setErrors, setSuccess, smallName }) {

    const isAdmin = useMemo(() => organizationData?.roles?.some((roleId) => ROLES.admin.id === roleId), [organizationData]);
    const [isInEdit, setIsInEdit] = useState(false);
    const [updating, setUpdating] = useState(undefined);

    const [organization, setOrganization] = useState();

    useEffect(() => {
        const data = organizationData;
        if (isNaN(data.mdc_limit_analysis)) {
            // eslint-disable-next-line camelcase
            data.mdc_limit_analysis = 0;
        }
        setOrganization(data);

    }, [organizationData]);

    const modalEditOrg = useMemo(() => {

        const limitInputs = {
            preventionApiLimit: null,
            reputationApiLimit: null,
            sandboxApiLimit: null,
            dailyFeedLimit: null,
            // eslint-disable-next-line camelcase
            mdc_limit_analysis: null
        };

        const handleChange = (event) => {

            const { name, value } = event.target;
            let val = parseInt(value);

            if (isNaN(val)) {
                limitInputs[name] = '';
            } else {
                // is A Number
                limitInputs[name] = value;
            }

        };

        const addHandler = () => {

            for (const key in limitInputs) {

                limitInputs[key] = parseInt(limitInputs[key]);
                if (isNaN(limitInputs[key])) {
                    limitInputs[key] = organization[key];
                }
            }

            if (limitInputs && organization && parentId) {
                setUpdating({ updating: organization.name });
                (async () => {

                    const newOrgData = {
                        'preventionApiLimit': limitInputs.preventionApiLimit,
                        'reputationApiLimit': limitInputs.reputationApiLimit,
                        'sandboxApiLimit': limitInputs.sandboxApiLimit,
                        'dailyFeedLimit': limitInputs.dailyFeedLimit,
                        'mdc_limit_analysis': limitInputs.mdc_limit_analysis,
                        'parent_id': parentId
                    };

                    const result = await organizationService.updateSubOrganization(organization.id, { organization: { ...newOrgData } });
                    if (result.error) {
                        // Add fail
                        setErrors(result.messages);
                    } else {
                        setSuccess([`Licenses for '${organization.name}' updated successfully!`]);
                        setOrganization({
                            ...organization,
                            ...newOrgData
                        });
                    }
                    setUpdating(undefined);
                })();
            }
            setIsInEdit(false);
        };

        const testKeyIsPositiveNumber = (event) => {

            if (!/\d/.test(event.key)) {
                event.preventDefault();
            }

            return undefined;
        };


        const addBtn = <a className="addBtn" onClick={addHandler}>
            <i className="fas fa-check" />
        </a>;

        const cancelBtn =

            <a className="cancelBtn" onClick={() => setIsInEdit(false)}>
                <i className="fas fa-ban" />
            </a>;

        if (organization) {
            let shortOrganizationName = organization.name.length > 10 ? organization.name.slice(0, 10) + '...' : organization.name;
            return <tr>
                <td>
                    <div className='entryContainer'>
                        <p>
                            {smallName ? `${shortOrganizationName}` : `${organization.name}`}
                        </p>
                    </div>
                </td>
                {Object.keys(limitInputs).map((key, index) => {

                    return <td key={index}>
                        <Control className='editInput'>
                            <Input
                                className={className({ 'invalidInput': limitInputs[key] })}
                                value={limitInputs[key]}
                                onChange={handleChange}
                                onKeyPress={(e) => e.key === 'Enter' ? addHandler() : testKeyIsPositiveNumber(e)}
                                name={`${key}`}
                                type='text'
                                placeholder={`${organization[key]}`}
                                autoFocus={true}
                            />
                        </Control>
                    </td>;
                })}
                <td>
                    <div className='entryContainer'>
                        {addBtn}
                        {cancelBtn}
                    </div>
                </td>
            </tr>;
        }
    }, [organization, parentId, updating, smallName]);

    const goToOrgButton = ((organizationId) => {
        if (isAdmin) {
            const addHandler = async () => {

                await navigate(`/organization/${organizationId}/overview`);
            };

            return <a onClick={addHandler}>

                <i className="fas fa-arrow-right" />
            </a>;
        }
        return null;
    });

    const editOrgButton = useMemo(() => {
        if (isAdmin) {
            const addHandler = () => {
                setErrors();
                setSuccess();
                setIsInEdit(true);
            };
            return (<a className="editOrgBtn" onClick={addHandler}>
                <i className='fas fa-pen' />
            </a>);
        }

        return null;

    }, [isAdmin, isInEdit]);

    const dom = useMemo(() => {

        if (updating) {
            return <tr>
                <td colSpan={6}>
                    <ContentLoader height={20} width='100%' speed={1}>
                        <rect x='0' y='0' rx='10' ry='10' width={`${40 + 60 * Math.random()}%`} height='20' />
                    </ContentLoader>
                </td>
            </tr>;
        }

        if (isInEdit && organization) {

            return modalEditOrg;
        }
        else if (organization) {
            let shortOrganizationName = organization.name.length > 10 ? organization.name.slice(0, 10) + '...' : organization.name;
            return <tr>
                <td>{smallName ? `${shortOrganizationName}` : `${organization.name}`}</td>
                <td>{`${organization.preventionApiLimit}`}</td>
                <td>{`${organization.reputationApiLimit}`}</td>
                <td>{`${organization.sandboxApiLimit}`}</td>
                <td>{`${organization.dailyFeedLimit}`}</td>
                <td>{`${organization.mdc_limit_analysis}`}</td>
                <td>
                    {editOrgButton}
                    {goToOrgButton(organization.id)}
                </td>
            </tr>;
        }

        return <></>;

    }, [organization, isInEdit, updating, smallName]);


    return dom;
}

export default OrganizationsTableEntry;

OrganizationsTableEntry.propTypes = {
    organizationData: PropTypes.object,
    parentId: PropTypes.string,
    setErrors: PropTypes.func,
    setSuccess: PropTypes.func,
    smallName: PropTypes.bool,
};
