import { Fragment, useEffect, useMemo, useState } from 'react';
import { Card, Heading, Button, Table, Icon } from 'react-bulma-components';
import ContentLoader from 'react-content-loader';
import SimpleMessages from '../../common/simple-messages/simpleMessages';
import PropTypes from 'prop-types';
import Input from '../../../components/input/input';

import { userService } from '../../../services/userService.js';

import './licenseCard.scss';

const TABLE_ENTRIES = 10;


const LicenseCard = ({ meta, product, organizationData = undefined, userData = undefined }) => {

    const [checked, setChecked] = useState({});
    const [loading, setLoading] = useState();
    const [startIndex, setStartIndex] = useState(0);
    const [lastPageItemNumber, setLastPageItemNumber] = useState();
    const [errors, setErrors] = useState();
    const [success, setSuccess] = useState();
    const [data, setData] = useState();

    useEffect(() => {
        setErrors();
        setSuccess();
        if (userData && organizationData) {

            const indexOfChecked = userData.findIndex((organization) => organization.use_mdc_license);

            if (indexOfChecked !== -1) {
                const checkedOrg = userData[indexOfChecked];

                // Removing item with use_mdc_license === true
                userData.splice(indexOfChecked, 1);

                // Building sorted array, with first element.use_mdc_license === true
                setData([checkedOrg, ...userData]);
                setChecked({
                    // eslint-disable-next-line camelcase
                    organization_id: checkedOrg.organization_id,
                    name: checkedOrg.name
                });
            } else {
                setData(userData);
                setChecked({});
            }
            setLastPageItemNumber(userData.length % TABLE_ENTRIES);
        }
    }, [userData, organizationData]);


    function nextPage() {
        if (startIndex + TABLE_ENTRIES < userData?.length) {
            setStartIndex(startIndex + TABLE_ENTRIES);
        } else if (startIndex + lastPageItemNumber < userData?.length - 1) {
            setStartIndex(startIndex + lastPageItemNumber);
        }
    }

    function previousPage() {
        if (startIndex + lastPageItemNumber === userData?.length - 1) {
            setStartIndex(startIndex - lastPageItemNumber);
        }
        else if (startIndex - TABLE_ENTRIES >= 0) {
            setStartIndex(startIndex - TABLE_ENTRIES);
        }
    }

    const header = useMemo(() => {
        if (!product) {
            return <ContentLoader
                height={60}
                width='100%'
                speed={1}
            >
                <rect x='0' y='0' rx='10' ry='10' width={200} height='15' />
                <rect x='0' y='30' rx='10' ry='10' width='80%' height='30' />
            </ContentLoader>;
        }

        return <>
            <Heading subtitle renderAs={'h2'} size={6}>{`${meta.name} API Key`}</Heading>
            <Heading renderAs={'h3'} weight={'normal'}>{product[meta.apikeyPath]}</Heading>
        </>;
    }, [meta, product]);

    const body = useMemo(() => {
        if (!product || typeof organizationData === 'undefined') {
            return <ContentLoader
                height={250}
                width='100%'
                speed={1}
            >
                {Array.from(Array(6).keys()).map((value) =>
                    <rect key={value} x='0' y={`${value * 45}`} rx='10' ry='10' width={`${20 + 60 * Math.random()}%`} height='15' />)}
            </ContentLoader>;
        }

        if (organizationData && data && checked) {

            const handleUpdate = () => {
                setLoading(true);
                (async () => {

                    // Call to v2 enpoint for updating user
                    const result = await userService.updateUser(data[0].user_id, {
                        'organization_id': checked.organization_id,
                        'use_mdc_license': true,
                    });

                    if (result.error) {
                        // Add fail
                        setErrors(result.messages);
                    } else {
                        setSuccess([`Updated succesfully. Now using licenses from ${checked.name}!`]);
                    }

                    setLoading(false);
                })();
            };

            const content = data.slice(startIndex, Math.min((startIndex + TABLE_ENTRIES), data.length)).map((item) => <Input
                key={item.organization_id}
                className='orgInput'
                checked={checked.organization_id === item.organization_id}
                label={`<p>${item.name}</p>`}
                name={item.name}
                onChange={() => setChecked({ 'organization_id': item.organization_id, name: item.name })}
                componentType='checkbox'
            />);

            return <div className='content-wrapper'>
                <p>Your MetaDefender Cloud limits are controlled by the organization.</p>
                <div className='content-header'>
                    <p>
                        Select organization to use licenses from:
                    </p>
                    <Button
                        onClick={handleUpdate}
                        className='addButton'
                        loading={loading}
                    >
                        Update
                    </Button>
                </div>
                <div className='content-body'>
                    {content}
                </div>
                <div className="content-page-btns">
                    <a onClick={previousPage}> <i className="fas fa-arrow-left" />Previous page</a>
                    <a onClick={nextPage}>Next page<i className="fas fa-arrow-right" /></a>
                </div>
            </div>;
        }

        return (
            <Table>
                <tbody>
                    {
                        meta.rows.filter((row) => row.show(product)).map((row, index) => <Fragment key={index}>
                            <tr>
                                <td>{row.header}</td>
                                <td className='hidden-xs'>{row.parse(product)}</td>
                            </tr>
                            <tr className='show-xs'>
                                <td>{row.parse(product)}</td>
                            </tr>
                        </Fragment>)
                    }
                </tbody>
            </Table>
        );
    }, [meta, product, organizationData, data, checked, startIndex, loading]);

    return <div className='licenseCard'>
        <div className='productTitle'>
            <Heading subtitle renderAs={'h2'} size={6}>{meta.name}</Heading>
            <a href={meta.url} target={'_blank'} rel={'noopener noreferrer'} aria-label={meta.name} className='iconWrapper'>
                <Icon>
                    <i className='fas fa-external-link-alt' />
                </Icon>
            </a>
        </div>
        <SimpleMessages messageList={errors || success} type={errors ? 'danger' : 'success'} />
        <Card>
            <Card.Header>
                {header}
            </Card.Header>
            <Card.Content>
                {body}
            </Card.Content>
            <Card.Footer>
                <Card.Footer.Item><a href={meta.document} target={'_blank'} rel={'noopener noreferrer'}>View Documentation</a></Card.Footer.Item>
                <Card.Footer.Item><a href={meta.websiteUrl} target={'_blank'} rel={'noopener noreferrer'}>Go to {meta.name}</a></Card.Footer.Item>
            </Card.Footer>
        </Card>
    </div>;
};

export default LicenseCard;

LicenseCard.propTypes = {
    meta: PropTypes.object,
    product: PropTypes.object,
    organizationData: PropTypes.object,
    userData: PropTypes.array
};
