import { Component } from 'react';
import { logService } from '../../services/LogService';
import crashImg from '../../assets/images/crash.svg';
import PropTypes from 'prop-types';

import './errorBoundary.scss';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
        this.props = props;
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        logService.error(error, { extra: info });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (<div className='ErrorBoundary'>
                <div>
                    <img src={crashImg} alt='crash'/>
                </div>
                <div>
                    <h1 dangerouslySetInnerHTML={{ __html: 'Oups! It seems like the webpage crashed and we failed to recover from the error.<br/>We are sorry for the inconvenience. Please refresh your page.' }}/>
                </div>
            </div>);
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

ErrorBoundary.propTypes = {
    children: PropTypes.node
};
