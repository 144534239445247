/* eslint-disable camelcase */
import ContentLoader from 'react-content-loader';
import Oauth2ClientEntry from './oauth2ClientEntry';
import PropTypes from 'prop-types';
import className from 'classnames';
import Validator from '../../../../services/Validator';
import SimpleMessages from '../../../common/simple-messages/simpleMessages';
import SimpleModal from '../../../common/simple-modal/simpleModal';
import { Table, Button, Form } from 'react-bulma-components';
import { oauth2clientService } from 'services/oauth2clientService';
import { Fragment, useState, useMemo, useEffect } from 'react';


import './oauth2ClientTable.scss';

const TABLE_ENTRIES = 5;
const { Control, Input } = Form;

function parseClientResponse({ clientName, clientSecret, client_id }) {
    return {
        clientName,
        clientId: client_id,
        clientSecret,
        checked: false
    };
}

const Oauth2ClientTable = ({ userId, orgId, isAdmin }) => {

    const [oauth2clients, setOauth2Clients] = useState();
    const [loading, setLoading] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [displayAddAPIClient, setDisplayAddAPIClient] = useState(false);
    const [displayDeleteAPIClient, setDisplayDeleteAPIClient] = useState(false);
    const [isValidClient, setIsValidClient] = useState();
    const [errors, setErrors] = useState();
    const [success, setSuccess] = useState();
    const [newClientInput, setNewClientInput] = useState({ clientName: '', clientId: '', clientSecret: '' });
    const [sortedByName, setSortedByName] = useState();
    const [lastPageItemNumber, setLastPageItemNumber] = useState();

    // load data
    useEffect(() => {
        let discard = false;
        setErrors();
        setSuccess();
        setDisplayAddAPIClient(false);
        setDisplayDeleteAPIClient(false);
        setNewClientInput({ clientName: '', clientId: '', clientSecret: '', checked: false });
        setSortedByName();
        setStartIndex(0);

        if (orgId && userId && isAdmin) {
            setOauth2Clients(undefined);
            setLoading({});

            (async () => {
                const result = await oauth2clientService.listOauth2Clients(orgId);
                if (!discard && result.data) {
                    setOauth2Clients(result.data.map((client) => parseClientResponse(client)));
                    setLastPageItemNumber();
                    setLoading(undefined);
                }
            })();
        }

        return function () {
            discard = true;
        };
    }, [userId, orgId]);

    function nextPage() {
        if (startIndex + TABLE_ENTRIES < oauth2clients?.length) {
            setStartIndex(startIndex + TABLE_ENTRIES);
        } else if (startIndex + lastPageItemNumber < oauth2clients?.length - 1) {
            setStartIndex(startIndex + lastPageItemNumber);
        }
    }

    function previousPage() {
        if (startIndex + lastPageItemNumber === oauth2clients?.length - 1) {
            setStartIndex(startIndex - lastPageItemNumber);
        }
        else if (startIndex - TABLE_ENTRIES >= 0) {
            setStartIndex(startIndex - TABLE_ENTRIES);
        }
    }

    function validateClient() {
        setIsValidClient(
            (newClientInput.clientName && Validator.isValidName(newClientInput.clientName)) &&
            (newClientInput.clientId && Validator.isValidName(newClientInput.clientId)) &&
            (newClientInput.clientSecret && Validator.isValidName(newClientInput.clientSecret))
        );
    }

    function sortingByName() {
        if (!Array.isArray(oauth2clients)) {
            return;
        }
        if (sortedByName === 'back') {
            setOauth2Clients(oauth2clients.sort((b, a) => (a.clientName > b.clientName) ? 1 : ((b.clientName > a.clientName) ? -1 : 0)));
            setSortedByName('front');

        } else {
            setOauth2Clients(oauth2clients.sort((a, b) => (a.clientName > b.clientName) ? 1 : ((b.clientName > a.clientName) ? -1 : 0)));
            setSortedByName('back');
        }
    }

    const disableAddApiModalButton = useMemo(() => {
        return !oauth2clients;
    }, [oauth2clients]);

    const disableDeleteApiModalButton = useMemo(() => {
        return !oauth2clients || !oauth2clients?.filter((entry) => entry.checked === true).length;
    }, [oauth2clients]);

    const modalAddAPIClient = useMemo(() => {
        if (!displayAddAPIClient) {
            return null;
        }
        validateClient();

        const handleChange = (e, attribute) => {
            setNewClientInput((newClientInput) => ({ ...newClientInput, [attribute]: e.target.value }));
            validateClient();
        };

        const addHandler = () => {
            if (isValidClient) {
                setLoading({ action: 'Updating...' });
                (async () => {
                    const result = await oauth2clientService.postOauth2Client(orgId, {
                        clientName: newClientInput.clientName,
                        clientId: newClientInput.clientId,
                        clientSecret: newClientInput.clientSecret,
                    });
                    if (result.error) {
                        // Add fail
                        setErrors(result.messages);
                    } else {
                        let newOauth2ClientsArray;
                        if (oauth2clients.some((client) => client.clientId === newClientInput.clientId)) {
                            // update
                            newOauth2ClientsArray = oauth2clients.map((client) => {
                                if (client.clientId === newClientInput.clientId) {
                                    client.clientName = newClientInput.clientName;
                                    client.clientSecret = newClientInput.clientSecret;
                                    client.checked = false;
                                }
                                return client;
                            });
                        } else {
                            // add
                            newOauth2ClientsArray = [newClientInput].concat(oauth2clients || []);
                        }
                        setOauth2Clients(newOauth2ClientsArray);
                        setSuccess([result.data.message]);
                        setNewClientInput({ clientName: '', clientId: '', clientSecret: '', checked: false });

                    }
                    setLoading(undefined);
                })();

                setDisplayAddAPIClient(false);
            }
        };

        return (
            <tr>
                <td>
                    <Control>
                        <Input
                            className={className({ 'invalidInput': !isValidClient && newClientInput.clientName })}
                            value={newClientInput.clientName}
                            onChange={(e) => handleChange(e, 'clientName')}
                            onKeyPress={(e) => e.key === 'Enter' ? addHandler() : undefined}
                            placeholder={'Name'}
                            autoFocus={true} />
                    </Control>
                </td>
                <td>
                    <Control>
                        <Input
                            className={className({ 'invalidInput': !isValidClient && newClientInput.clientId })}
                            value={newClientInput.clientId}
                            onChange={(e) => handleChange(e, 'clientId')}
                            onKeyPress={(e) => e.key === 'Enter' ? addHandler() : undefined}
                            placeholder={'Id'} />
                    </Control>
                </td>
                <td>
                    <Control>
                        <Input
                            className={className({ 'invalidInput': !isValidClient && newClientInput.clientSecret })}
                            value={newClientInput.clientSecret}
                            onChange={(e) => handleChange(e, 'clientSecret')}
                            onKeyPress={(e) => e.key === 'Enter' ? addHandler() : undefined}
                            placeholder={'Secret'} />
                    </Control>
                </td>
                <td className="btnsContainer">
                    <Button className="addButton" disabled={!isValidClient} onClick={addHandler}>
                        Add
                    </Button>
                    <Button className="removeButton" onClick={() => setDisplayAddAPIClient(false)}>
                        Cancel
                    </Button>
                </td>
            </tr>);
    }, [orgId, userId, displayAddAPIClient, newClientInput, isValidClient]);

    const modalDeleteAPIClients = useMemo(() => {
        const toBeDeleted = oauth2clients?.filter((entry) => entry.checked === true);
        if (!toBeDeleted || !displayDeleteAPIClient) {
            return;
        }

        const deleteHandler = () => {
            setLoading({ action: 'Deleting...' });
            (async () => {
                let results = [];
                await toBeDeleted.forEach(async (deleteClient) => {
                    const result = await oauth2clientService.deleteOauth2Client(orgId, deleteClient.clientId);
                    results.push(result);
                });
                if (results.find((res) => Object.prototype.hasOwnProperty.call(res, 'error'))) {
                    setErrors('Error deleting clients');
                } else {
                    // Update to remove clients
                    setOauth2Clients((oauth2clients) => {
                        return oauth2clients.filter((clientEntry) => {
                            const mustBeDeleted = toBeDeleted.find((entry) => entry.clientId === clientEntry.clientId);
                            if (!mustBeDeleted) {
                                return true;
                            }
                            return false;
                        });
                    });
                    setSuccess([`Successfully deleted ${toBeDeleted.length} clients!`]);
                    setStartIndex(0);
                }
                setLoading(undefined);
            })();
            setDisplayDeleteAPIClient(undefined);
        };

        return <SimpleModal
            className='deleteUserModal'
            isShow={!!toBeDeleted}
            onClose={() => setDisplayDeleteAPIClient(undefined)}
            title='Remove clients from organization'
            body={<>
                Do you want to remove <span className='highlightDelete'>{toBeDeleted.length === 1 ? `${toBeDeleted.length} client` : `${toBeDeleted.length} clients`}</span> from organization?
            </>}
            footer={<>
                <Button color='danger' onClick={deleteHandler}>
                    Yes
                </Button>
                <Button onClick={() => setDisplayDeleteAPIClient(undefined)}>
                    No
                </Button>
            </>}
        />;
    }, [orgId, userId, displayDeleteAPIClient]);

    const tableContentDom = useMemo(() => {
        let dom;
        if (oauth2clients) {
            setLastPageItemNumber(oauth2clients.length % TABLE_ENTRIES);
            dom = <>
                {oauth2clients.slice(startIndex, Math.min((startIndex + TABLE_ENTRIES), oauth2clients.length)).map((client) => < Oauth2ClientEntry
                    key={client.clientId}
                    oauth2clients={oauth2clients}
                    setOauth2client={setOauth2Clients}
                    client={client} />)}
                <tr><td colSpan={4}></td></tr>
            </>;
        } else {
            dom = Array(4).fill('').map((v, index) => <Fragment key={index}>
                <tr>
                    <td colSpan={4}>
                        <ContentLoader height={20} width='100%' speed={1}>
                            <rect x='0' y='0' rx='10' ry='10' width={`${40 + 60 * Math.random()}%`} height='20' />
                        </ContentLoader>
                    </td>
                </tr>
            </Fragment>);
        }
        return dom;
    }, [oauth2clients, orgId, userId, loading, isAdmin, startIndex, sortedByName]);

    const tableFooter = useMemo(() => {
        let footer;
        if (oauth2clients?.length || loading?.action) {
            footer = <div className='footNote'>
                <div className="previousPageContainer">
                    <a onClick={previousPage}> <i className="fas fa-arrow-left" />Previous page</a>
                </div>
                <div className='detailsContainer'>
                    {oauth2clients?.length ? <p className='is-pulled-right'>Showing: {startIndex + 1} - {Math.min((startIndex + TABLE_ENTRIES), oauth2clients.length)}</p> : null}
                    {oauth2clients?.length ? <p className='is-pulled-right'>Total: {oauth2clients.length}</p> : null}
                    {loading && loading.action && <span className='pending floatRight'>{loading.action}</span>}
                </div>
                <div className="nextPageContainer">
                    <a onClick={nextPage}>Next page <i className="fas fa-arrow-right" /></a>
                </div>

            </div>;
        }
        else if (!loading) {
            footer = <div className='noAPIClientsMessage'>In order to manage MD Cloud API Clients, please add a new client</div>;
        }
        return footer;
    }, [oauth2clients, orgId, userId, loading, startIndex]);

    return (
        <Fragment>
            <h2 className='subtitle is-6' id="subtitle">OAuth2 Clients</h2>
            <SimpleMessages messageList={errors || success} type={errors ? 'danger' : 'success'} />
            <div className='orgTable xScrollTable clientTable'>
                <Table>
                    <tbody>
                        <tr>
                            <td>
                                <div onClick={() => sortingByName()} className='cellContainer justifyStart'>
                                    <span className='sorting'>Client Name</span>
                                    <div className='icon-wrapper sorting'>
                                        <span className='icons'><i className="fas fa-caret-up" /></span>
                                        <span className='icons'><i className="fas fa-caret-down" /></span>
                                    </div>
                                </div>
                            </td>
                            <td><div className='cellContainer justifyStart'>Client ID</div></td>
                            <td><div className='cellContainer'>Client Secret</div></td>
                            <td>
                                <div className='is-pulled-right cellContainer'>
                                    <Button
                                        onClick={() => setDisplayAddAPIClient(!displayAddAPIClient)}
                                        className="addButton"
                                        disabled={disableAddApiModalButton}>
                                        Add API Clients
                                    </Button>
                                    <Button
                                        onClick={() => setDisplayDeleteAPIClient(!displayDeleteAPIClient)}
                                        className="removeButton"
                                        disabled={disableDeleteApiModalButton}>
                                        Remove
                                    </Button>
                                </div>
                            </td>
                        </tr>
                        {modalAddAPIClient}
                        {modalDeleteAPIClients}
                        {tableContentDom}
                    </tbody>
                </Table>
                {tableFooter}
            </div>
        </Fragment>
    );
};

export default Oauth2ClientTable;

Oauth2ClientTable.propTypes = {
    userId: PropTypes.string,
    orgId: PropTypes.string,
    isAdmin: PropTypes.bool
};
