import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './index.scss';
import App from './App';
import gtmService from './services/gtmService';

gtmService.init();

// Config to use recaptcha.net instead of www.google.com because it was blocked in China
window.recaptchaOptions = {
    useRecaptchaNet: true
};

ReactDOM.render(
    <StrictMode>
        <App/>
    </StrictMode>,
    document.getElementById('root')
);
