import { useMemo } from 'react';
import { Heading, Columns } from 'react-bulma-components';
import { ROLES } from '../../../services/Constant';
import UsersTable from './usersTable';
import InviteTable from './inviteTable';
import PropTypes from 'prop-types';

import './usersPage.scss';

const UsersPage = ({ userId, organizationData }) => {

    const isAdmin = useMemo(() => organizationData?.roles?.some((roleId) => ROLES.admin.id === roleId), [organizationData]);
    const orgId = useMemo(() => organizationData?.id, [organizationData]);
    const orgName = useMemo(() => organizationData?.name, [organizationData]);

    return <div className='usersPage'>
        <Columns className='pageHeader' breakpoint='mobile'>
            <Columns.Column>
                <Heading renderAs={'h1'} weight={'normal'}>Users</Heading>
            </Columns.Column>
        </Columns>
        <div className='pageBody'>
            <UsersTable userId={userId} orgId={orgId} orgName={orgName}/>
            <InviteTable userId={userId} orgId={orgId} isAdmin={isAdmin}/>
        </div>
    </div>;
};

export default UsersPage;

UsersPage.propTypes = {
    userId: PropTypes.string,
    organizationData: PropTypes.object
};
