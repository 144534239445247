import { useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

import './totpInput.scss';

const TotpInput = (props) => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref?.current) {
            ref.current.focus();
        }
    }, [ref]);

    return <div className='totpInput'>
        <label>{props.label || 'TOTP'}</label>
        <InputMask ref={ref} {...props} mask={props.mask || '999 999'} maskPlaceholder='_' alwaysShowMask={true}/>
    </div>;
};

export default TotpInput;

TotpInput.propTypes = {
    label: PropTypes.string,
    mask: PropTypes.string
};
