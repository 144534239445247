import { Children, cloneElement } from 'react';
import { Container, Section } from 'react-bulma-components';
import Navbar from '../navbar/navbar';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './ssoLayout.scss';

const SSOLayout = ({ children, layoutClass, pageTitle, fullGray, navContent }) => {
    const childrenWithProps = Children.map(children, (child) => {
        return cloneElement(child);
    });
    return <Section className={classNames('sso--layout', layoutClass, { dividedBackground: !fullGray, fullBackground: fullGray })}>
        <Container>
            <Helmet>
                <script defer src="https://use.fontawesome.com/releases/v5.14.0/js/all.js"/>
                <title> {pageTitle} </title>
            </Helmet>
            <Navbar pageTitle={pageTitle} navContent={navContent}/>
            {childrenWithProps}
        </Container>
    </Section>;
};

export default SSOLayout;

SSOLayout.propTypes = {
    children: PropTypes.node.isRequired,
    layoutClass: PropTypes.string,
    pageTitle: PropTypes.string,
    fullGray: PropTypes.bool,
    navContent: PropTypes.node 
};
