const idpErrors = (code, issuerID) => {
    switch (code) {
        case 40001:
            return 'There was an error while parsing the message body. The message contains invalid data.';
        case 40002:
            return 'We encountered an error while parsing the SAML Response. The response may contain invalid data.';
        case 40401:
            return `Cannot find IDP using the ${issuerID} issuer identifier.`;
        case 40402:
            return 'We encountered an error while parsing the SAML Response. The response is missing the email attribute.';
        case 40403:
            return 'We encountered an error while parsing the SAML Response. The response is missing the name attribute.';
        case 50001:
        case 50002:
        case 50003:
        case 50004:
            return 'An internal error prevented us from processing the SAML response.';
        case 50005:
            return 'There was an unknown error when creating the OPSWAT user.';
        case 50006:
            return 'There was an unexpected error when trying to log in.';
        default:
            return;
    }
};

export default idpErrors;
