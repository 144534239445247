import { useMemo } from 'react';
import { Columns, Heading } from 'react-bulma-components';
import { ROLES } from '../../../services/Constant';
import Oauth2ClientTable from './oauth/oauth2ClientTable';
import RsaCard from './rsa/rsaCard';
import PropTypes from 'prop-types';

import './securityPage.scss';


const SecurityPage = ({ userId, organizationData }) => {

    const isAdmin = useMemo(() => organizationData?.roles?.some((roleId) => ROLES.admin.id === roleId), [organizationData]);
    const orgId = useMemo(() => organizationData?.id, [organizationData]);
    const rsaKey = useMemo(() => organizationData?.mdc_public_key, [organizationData]);

    return (
        <div className='securityPage'>
            <Columns className='pageHeader' breakpoint='mobile'>
                <Columns.Column>
                    <Heading renderAs={'h1'} weight={'normal'}>Security</Heading>
                </Columns.Column>
            </Columns>
            <div className='pageBody'>
                <RsaCard orgId={orgId} rsaKey={rsaKey} />
                <Oauth2ClientTable userId={userId} orgId={orgId} isAdmin={isAdmin} />
            </div>
            {/* <SimpleMessages messageList={errors} /> */}
        </div>
    );
};

export default SecurityPage;

SecurityPage.propTypes = {
    userId: PropTypes.string,
    organizationData: PropTypes.object
};
