import { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bulma-components';
import { axios } from 'services/axios';
import Input from '../../components/input/input';
import { Link, navigate } from '@reach/router';
import { logService } from '../../services/LogService';
import FormPost from '../../components/common/form-saml-response/formPost';
import consentData from '../../services/productData';
import { COMMON_ERROR_MESSAGE, errorMessageMap, NETWORK_ERROR_MESSAGE } from '../../services/Constant';
import ActionLayout from '../../components/layout/action-layout/actionLayout';
import PropTypes from 'prop-types';

import './consent.scss';

const Consent = ({ location }) => {
    const [loading, setLoading] = useState(false);
    const [consentAgree, setConsentAgree] = useState('');

    const [errors, setErrors] = useState([]);
    const [postInput, setPostInput] = useState();
    const [entityEndpoint, setEntityEndpoint] = useState();

    const appName = useMemo(() => location.state?.appName, [location]);

    useEffect(() => {
        if (!appName) {
            navigate('/login');
        }
    }, [appName]);

    const onProceed = (e) => {
        e && e.preventDefault();
        if (!location?.state?.samlRequest || !location.state?.appId) {
            setErrors([{
                title: 'Errors',
                messages: [
                    <>
                        <span>Please </span>
                        <Link to='/login'>login</Link>
                        <span> and try again.</span>
                    </>
                ]
            }]);
            return;
        }

        setLoading(true);
        (async () => {
            try {
                const consent = Object.fromEntries(Object.keys(consentData).map((key) => [key, true]));

                //Delete OESISCloud for now
                delete consent.OESISCloud;

                const request = await axios.post('/consent-agree', {
                    consent,
                    appId: location.state.appId,
                    SAMLRequest: location.state.samlRequest,
                    RelayState: location.state.relayState
                });
                if (request.status === 200 && request.data?.loginResponse) {
                    const input = [{ name: 'SAMLResponse', value: request.data.loginResponse.context }];
                    if (request.data.loginResponse.relayState) {
                        input.push({ name: 'RelayState', value: request.data.loginResponse.relayState });
                    }
                    setPostInput(input);
                    setEntityEndpoint(request.data.loginResponse.entityEndpoint);
                }
            } catch (err) {
                logService.error(err);
                let title;
                const messages = [];
                if ((err?.response?.status >= 400 && err?.response?.status < 500) || errorMessageMap(err?.response?.data?.error?.code)) {
                    title = 'Errors';
                    messages.push(COMMON_ERROR_MESSAGE);
                } else {
                    title = 'Oups';
                    messages.push(NETWORK_ERROR_MESSAGE);
                }

                messages.push(errorMessageMap(err?.response?.data?.error?.code));

                setErrors([{
                    title,
                    error: err,
                    messages
                }]);
            }
            setLoading(false);
        })();
    };

    const formResponseDom = useMemo(() => <FormPost inputs={postInput} entityEndpoint={entityEndpoint} />, [postInput, entityEndpoint]);

    const dom = useMemo(() => {
        if (!appName || !Object.keys(consentData).includes(appName)) {
            return;
        }

        const primaryConsent = consentData[appName];
        const appList = Object.keys(consentData);

        const label = 'I agree to the OPSWAT Inc. <a href=\'https://www.opswat.com/legal\' target=\'_blank\'>Terms of Service and Privacy Policy</a>, unless my organization has a separate written agreement with OPSWAT Inc., in which case those separate terms shall apply.';
        return <ActionLayout
            className={'consent'}
            pageTitle={'OPSWAT Accounts | Consent'}
            message={{ title: `Before continuing to ${primaryConsent.shortname}, you must agree to:` }}
            app={appName}
            errors={errors}>
            <>
                <Input
                    checked={!!consentAgree}
                    label={label}
                    name={appName}
                    onChange={(evt) => setConsentAgree(evt.target.checked ? appList : '')}
                    componentType='checkbox'
                />

                <Button
                    fullwidth={true}
                    color='primary'
                    onClick={onProceed}
                    disabled={!consentAgree || loading}
                    loading={loading}
                    className={'form--button'}
                >
                    Proceed
                </Button>
            </>
        </ActionLayout>;
        // eslint-disable-next-line
    }, [loading, consentAgree, appName, errors]);

    if (!location.state?.appName || !Object.keys(consentData).includes(location.state.appName)) {
        return null;
    }

    return <>
        {dom}
        {formResponseDom}
    </>;
};

export default Consent;

Consent.propTypes = {
    location: PropTypes.object
};
