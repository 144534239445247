import { useEffect, useState } from 'react';
import LicenseCard from './licenseCard';
import { Heading } from 'react-bulma-components';
import { profileService } from '../../../services/profileService';
import { userService } from '../../../services/userService';
import moment from 'moment';
import PropTypes from 'prop-types';

const DATE_FORMAT = 'MMM D, YYYY';

const PRODUCT = {
    MDC: {
        name: 'MetaDefender Cloud',
        document: 'https://onlinehelp.opswat.com/mdcloud/',
        url: 'https://www.opswat.com/products/metadefender/cloud',
        websiteUrl: `${process.env.REACT_APP_app_href_MDC}/login`,
        apikeyPath: 'mdcApiKey',
        rows: [
            {
                header: 'Prevention',
                show: (product) => product.mdcPreventionApi,
                parse: (product) => <span><b>{product.mdcPreventionApi}</b> requests per day</span>
            },
            {
                header: 'Reputation',
                show: (product) => product.mdcReputationApi,
                parse: (product) => <span><b>{product.mdcReputationApi}</b> requests per day</span>
            },
            {
                header: 'Sandbox',
                show: (product) => product.mdcAnalysisApi,
                parse: (product) => <span><b>{product.mdcAnalysisApi}</b> requests per day</span>
            },
            {
                header: 'Analysis',
                show: (product) => product.mdcAnalysisLimit,
                parse: (product) => <span><b>{product.mdcAnalysisLimit}</b> requests per day</span>
            },
            {
                header: 'Feed',
                show: (product) => product.mdcFeedLimit,
                parse: (product) => <span><b>{product.mdcFeedLimit}</b> Hashes per day</span>
            },
            {
                header: 'License Expiration Date',
                show: (product) => product.mdcExpiration,
                parse: (product) => <span><b>{moment(product.mdcExpiration).format(DATE_FORMAT)}</b></span>
            }
        ]
    },
    MA: {
        name: 'MetaDefender IT Access',
        document: 'https://onlinehelp.opswat.com/metaaccess/',
        url: 'https://www.opswat.com/products/metaaccess',
        websiteUrl: `${process.env.REACT_APP_app_href_MA}/console/saml/login`,
        apikeyPath: 'maApikey',
        rows: [
            {
                header: 'License Expiration Date',
                show: (product) => product.maExpiration,
                parse: (product) => <span><b>{moment(product.maExpiration).format(DATE_FORMAT)}</b></span>
            },
            {
                header: 'Tier',
                show: (product) => parseFloat(product.maLicTier),
                parse: (product) => <span><b>{product.maLicTier}</b></span>
            },
            {
                header: 'Number of Devices',
                show: (product) => parseFloat(product.maLicMaxDevices),
                parse: (product) => <span><b>{product.maLicMaxDevices}</b></span>
            },
            {
                header: 'Cloud Gateway',
                show: (product) => parseFloat(product.maLicCloudGateway),
                parse: (product) => <span><b>{product.maLicCloudGateway}</b></span>
            },
            {
                header: 'Max NAC appliances',
                show: (product) => parseFloat(product.maLicMaxNacAppl),
                parse: (product) => <span><b>{product.maLicMaxNacAppl}</b></span>
            },
            {
                header: 'Max Concurrent Connections',
                show: (product) => parseFloat(product.maLicMaxCcuConns),
                parse: (product) => <span><b>{product.maLicMaxCcuConns}</b></span>
            },
            {
                header: 'NAC expiration date',
                show: (product) => product.maNacExpiration,
                parse: (product) => <span><b>{moment(product.maNacExpiration).format(DATE_FORMAT)}</b></span>
            }
        ]
    }
};

const ProfileLicenses = ({ organizationData, userId }) => {
    const [products, setProducts] = useState({});
    const [orgUserInfo, setOrgUserInfo] = useState();

    const productsUpdate = (info) => setProducts({ MDC: info['MDC'], MA: info['MA'] });

    useEffect(() => {
        productsUpdate(profileService.getInfo());
        profileService.registerUpdateEvent(productsUpdate);
        return () => profileService.unregisterUpdateEvent(productsUpdate);
    }, []);

    useEffect(() => {
        // Get user data in relation with organizations
        if (userId) {
            (async () => {
                const userInfo = await userService.getUser(userId);
                if (!userInfo.error) {
                    setOrgUserInfo(userInfo.data.organizationUserData);
                }
            })();
        }
    }, [userId]);

    return <div className='profileLicenses'>
        <Heading renderAs={'h1'} weight={'normal'}>Active Licenses</Heading>
        <LicenseCard meta={PRODUCT.MDC} product={products.MDC} organizationData={organizationData} userData={orgUserInfo} />
        <LicenseCard meta={PRODUCT.MA} product={products.MA} organizationData={null} userData={null} />
    </div>;
};

export default ProfileLicenses;

ProfileLicenses.propTypes = {
    organizationData: PropTypes.object,
    userId: PropTypes.string
};
