import { useMemo, useState, useEffect } from 'react';
import { Content, Heading, Message, Button } from 'react-bulma-components';
import { errorTraceID } from '../../../services/utility';
import PropTypes from 'prop-types';

import './simpleMessages.scss';

const SimpleMessages = ({ messageList, type = 'danger' }) => {
    const [currentMessageList, setCurrentMessageList] = useState(messageList); 
    const handleMessageRemove = (event) => {
        event.preventDefault();
        setCurrentMessageList(null);
    };

    useEffect(() => {
        if (messageList !== currentMessageList) {
            setCurrentMessageList(messageList);
        }
    }, [messageList]);

    const dom = useMemo(() => {
        let list;
        if (typeof currentMessageList === 'string' && currentMessageList) {
            list = [{
                messages: [currentMessageList]
            }];
        } else if (Array.isArray(currentMessageList) && currentMessageList.length && currentMessageList.filter((message) => message).every((message) => typeof message === 'string')) {
            list = [{
                messages: currentMessageList
            }];
        } else if (Array.isArray(currentMessageList) && currentMessageList.length) {
            list = currentMessageList;
        } else {
            return null;
        }

        return list.filter((error) => error).map((error, index) => {
            let messages;
            if (Array.isArray(error.messages)) {
                messages = error.messages.filter((message) => message).map((message, index) => {
                    if (typeof message === 'string') {
                        return <li key={index} dangerouslySetInnerHTML={{ __html: message }}/>;
                    }

                    return <li key={index}>{message}</li>;
                });
            } else if (typeof error.messages === 'string') {
                messages = <li>{error.messages}</li>;
            }

            const traceMessage = errorTraceID(error.error);

            if (!messages && !traceMessage) {
                return null;
            }

            return <Message color={type} key={index}>
                <Message.Body>
                    <Content>
                        {error.title ? <Heading subtitle renderAs="h4" size={6}>
                            {error.title}
                        </Heading> : ''}
                        <ul>
                            {messages}
                            {traceMessage ? <li>{traceMessage}</li> : undefined}
                        </ul>
                    </Content>
                    <Button remove onClick={handleMessageRemove}/>
                </Message.Body>
            </Message>;
        });
    }, [currentMessageList, type]);

    if (!dom) {
        return null;
    }

    return <div className="simpleMessages">
        {dom}
    </div>;
};

export default SimpleMessages;

SimpleMessages.propTypes = {
    messageList: PropTypes.array,
    type: PropTypes.string
};
