import classname from 'classnames';
import { Content, Modal } from 'react-bulma-components';
import PropTypes from 'prop-types';

import './simpleModal.scss';

const SimpleModal = ({ className, isShow, onClose, title, body, footer, closeOnBlur = true }) => {
    return <Modal className={classname('simpleModal', className)} show={!!isShow} closeOnBlur={closeOnBlur} onClose={onClose}>
        <Content>
            <Modal.Card>
                <Modal.Card.Head onClose={onClose}>
                    <Modal.Card.Title>
                        {title}
                    </Modal.Card.Title>
                </Modal.Card.Head>
                <Modal.Card.Body>
                    <Content>
                        {body}
                    </Content>
                </Modal.Card.Body>
                <Modal.Card.Foot>
                    {footer}
                </Modal.Card.Foot>
            </Modal.Card>
        </Content>
    </Modal>;
};

export default SimpleModal;

SimpleModal.propTypes = {
    className: PropTypes.string,
    isShow: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    body: PropTypes.node,
    footer: PropTypes.node,
    closeOnBlur: PropTypes.bool
};
