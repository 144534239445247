import { useState, useMemo, useEffect } from 'react';
import {
    Button, Form,
    Columns
} from 'react-bulma-components';
import ContentLoader from 'react-content-loader';
import SimpleMessages from '../../../common/simple-messages/simpleMessages';
import { organizationService } from 'services/organizationService';
import PropTypes from 'prop-types';


import './rsaCard.scss';


const PUBLIC_KEY_FORMATS = {
    'pkcs1': 'BEGIN RSA PUBLIC KEY',
    'pkcs8': 'BEGIN PUBLIC KEY',
    'openssh': 'ssh-rsa'
};

const RsaCard = ({ orgId, rsaKey }) => {

    const { Input, Control } = Form;
    const [publicKey, setPublicKey] = useState(null);
    const [isUpdatingRsaKey, setIsUpdatingRsaKey] = useState(false);
    const [incorectFormat, setIncorectFormat] = useState(false);
    const [hasRsaKey, setHasRsaKey] = useState();

    useEffect(() => {
        if (rsaKey) {
            setHasRsaKey(true);
            setPublicKey(rsaKey);
        } else {
            setHasRsaKey(false);
        }
    }, [rsaKey]);


    const encryptedValue = useMemo(() => {
        let encryptedKey;
        if (!publicKey || publicKey.includes('**********')) {
            encryptedKey = publicKey;
        } else {
            encryptedKey = publicKey.slice(0, 15) + '*'.repeat(10) + publicKey.slice(publicKey.length - 16, publicKey.length - 1);
        }

        return encryptedKey;
    }, [publicKey]);

    const dom = useMemo(() => {

        const handleUpdate = (e) => {
            const current = e.target.value;

            setPublicKey(current);

        };

        const updateRsaKey = () => {

            setHasRsaKey(false);
            setIncorectFormat(false);

        };

        const clearRsaKey = () => {

            setIsUpdatingRsaKey(true);
            setIncorectFormat(false);

            (async () => {
                await organizationService.deleteOrganizationRsa(orgId);
                setIsUpdatingRsaKey(false);
            })();

            setPublicKey('');
            setHasRsaKey(false);
        };

        const setRsaKey = async () => {

            setIsUpdatingRsaKey(true);

            let currentFormat;
            Object.keys(PUBLIC_KEY_FORMATS).forEach((format) => {
                if (publicKey.includes(PUBLIC_KEY_FORMATS[format])) {
                    currentFormat = format;
                }
            });

            if (publicKey && orgId && currentFormat) {

                switch (currentFormat) {
                    case 'pkcs1':
                    case 'pkcs8':
                    case 'openssh': {
                        setHasRsaKey(true);
                        break;
                    }

                    default:
                        setHasRsaKey(null);
                        break;
                }

                (async () => {
                    await organizationService.updateOrganizationRsa(orgId, publicKey);
                    setIsUpdatingRsaKey(false);
                })();

            } else {

                setIncorectFormat(true);
                setIsUpdatingRsaKey(false);

            }
        };

        if (isUpdatingRsaKey || (!orgId && !rsaKey)) {
            return (
                <ContentLoader
                    height={100}
                    width='100%'
                    speed={1}
                >
                    <rect x='0' y='30' rx='10' ry='10' width={'85%'} height='15' />
                    <rect x='0' y='60' rx='10' ry='10' width={'80%'} height='15' />
                </ContentLoader>
            );
        }

        if (!hasRsaKey) {
            return (
                <Control>
                    {incorectFormat && <SimpleMessages
                        messageList={['Incorrect RSA Key format. Allowed types are pkcs1, pkcs8, openssh. Please try again.']} type='danger'>
                    </SimpleMessages>}
                    <Columns>
                        <Columns.Column size={2} className='labelCol'>
                            <p className='rsaLabel'>Public RSA Key:</p>
                        </Columns.Column>

                        <Columns.Column size={6} className='inputCol'>

                            <Input
                                value={publicKey}
                                onChange={handleUpdate}
                                autoFocus={true}
                                type="text"
                            />
                        </Columns.Column>
                        <Columns.Column size={4} className='btnCol'>

                            <Button className="addButton" onClick={setRsaKey} >
                                {'Add RSA Key'}
                            </Button>
                        </Columns.Column>
                    </Columns >
                </Control>
            );
        }

        return <div className='publicKeyContainer'>
            <Columns>
                <Columns.Column size={3} className=''>
                    <p className="rsaLabel">Public RSA Key:</p>
                </Columns.Column>
                <Columns.Column size={9} className=''>
                    <p>{encryptedValue}</p>
                    <Button className="addButton" onClick={updateRsaKey} >
                        {'Update RSA Key'}
                    </Button>
                    <Button className="removeButton" onClick={clearRsaKey} >
                        {'Remove RSA Key'}
                    </Button>
                </Columns.Column>
            </Columns>
        </div>;

    }, [incorectFormat, publicKey, hasRsaKey, isUpdatingRsaKey, orgId, rsaKey]);

    const infoMessage = useMemo(() => {

        return <div className='infoMessageContainer'>
            <Columns>
                <Columns.Column size={1} className='infoIconCol'>
                    <i className="fas fa-info-circle"></i>
                </Columns.Column >
                <Columns.Column size={11}>
                    <p>Upload a Public RSA Key to enhance your data privacy and security. </p>
                    <p>Supported key formats: PEM(PUBLIC KEY, RSA PUBLIC KEY), OpenSSH (ssh-rsa). <a href="#">Read more.</a></p>
                </Columns.Column >
            </Columns>
        </div>;
    }, []);


    return <div className='rsaKeyContainer'>
        <h2 className='subtitle is-6'>RSA Key</h2>
        <div className='contentContainer'>
            {infoMessage}
            {dom}
        </div>
    </div >;
};

export default RsaCard;


RsaCard.propTypes = {
    orgId: PropTypes.string,
    rsaKey: PropTypes.string,
};
