/* eslint-disable no-useless-escape */
import { useState, useMemo, useEffect, useRef } from 'react';
import { Button } from 'react-bulma-components';
import classNames from 'classnames';
import Input from '../../components/input/input';
import TotpInput from '../../components/input/totpInput';
import { axios } from 'services/axios';
import FormLayout from '../../components/layout/form-layout/formLayout';
import Validator from 'services/Validator';
import QueryParametersParser from '../../services/QueryParametersParser';
import { logService } from '../../services/LogService';
import FormPost from '../../components/common/form-saml-response/formPost';
import { Link, navigate } from '@reach/router';
import { errorTraceID, getAppNameByID } from '../../services/utility';
import productData from '../../services/productData';
import { NETWORK_ERROR_MESSAGE, ERRORS_CODE, errorMessageMap, PAYLOAD_COOKIE, REFRESH_COOKIE, SESSION_COOKIE } from '../../services/Constant';
import idpErrors from '../../services/idpErrors';
import moment from 'moment';
import organizationErrors from '../../services/organizationErrors';
import LoadingLayout from '../../components/layout/loadingLayout';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import useMARedirect from '../../hooks/useMARedirect';
import Cookies from 'js-cookie';

import './login.scss';

const LoginPage = ({ location }) => {
    const query = useMemo(() => QueryParametersParser.parse(location?.search), [location]);
    const isInQA = useMemo(() => query?.inQA === 'true' && (process.env.REACT_APP_ENVIRONMENT === 'dev' || process.env.REACT_APP_ENVIRONMENT === 'qa' || process.env.REACT_APP_ENVIRONMENT === 'local'), [query]);
    const autoLogin = useMemo(() => query?.app === process.env.REACT_APP_appID_MA && query?.token, [query]);

    const [email, setEmail] = useState(location?.state?.activatedEmail || location?.state?.email || '');
    const [password, setPassword] = useState('');
    const [checkedEmail, setCheckedEmail] = useState(location?.state?.activatedEmail || '');
    const [captchaResponse, setCaptchaResponse] = useState();
    const recaptchaRef = useRef(null);
    const [remainingTries, setRemainingTries] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCurrentUser, setIsLoadingCurrentUser] = useState(false);
    const [errors, setErrors] = useState([]);
    const [info, setInfo] = useState([]);

    const [samlRequest, setSamlRequest] = useState();
    const [relayState, setRelayState] = useState();
    const [appId, setAppId] = useState();
    const [samlResponse, setSamlResponse] = useState();
    const [entityEndpoint, setEntityEndpoint] = useState();
    const [samlIdpRequest, setSamlIdpRequest] = useState();
    const [responseData, setResponseData] = useState(undefined);

    const [isTotpChallenge, setIsTotpChallenge] = useState(false);
    const [totp, setTotp] = useState('');
    const [isInputRecoveryCode, setIsInputRecoveryCode] = useState(false);
    const [recoveryCode, setRecoveryCode] = useState('');

    useEffect(() => {
        if (query?.app) {
            setAppId(query?.app);
            setSamlRequest(query?.SAMLRequest);
            setRelayState(query?.RelayState);
        }

        if (!location?.state?.notLoadCurrentUser) {
            checkCurrentUser(query?.SAMLRequest, query?.app, query?.RelayState);
        }

        // Show error if there any error from IDP login
        if (query.idpErrorCode) {
            const idpErrorMessage = idpErrors(parseInt(query.idpErrorCode), query.idpIssuerId);
            if (idpErrorMessage) {
                setErrors([{
                    title: 'Error',
                    error: query.idpErrorTraceId,
                    messages: [idpErrorMessage]
                }]);
            }
        }

        // Show initial info
        if (query.infoMessage) {
            setInfo([{
                messages: [query.infoMessage]
            }]);
        }

        // Show error if there any error when user accepts invitation from organization
        if (query.orgAcceptInviteErrorCode) {
            setErrors([{
                title: 'Error',
                messages: [organizationErrors.acceptInviteErrors(parseInt(query.orgAcceptInviteErrorCode)), (query.orgAcceptInviteErrorTrace || undefined)]
            }]);
        }

        // Show error if there any error when user activate account
        if (query.activateErrorCode) {
            const appName = getAppNameByID(query.app);
            // eslint-disable-next-line no-prototype-builtins
            const productName = appName && productData.hasOwnProperty(appName) ? productData[appName].shortname : productData.OCM.shortname;

            setErrors([{
                title: 'Error',
                messages: [
                    <>
                        <span>There was an error activating your OPSWAT {productName} account. Please try to </span>
                        <b><Link to={'/confirmEmail' + (location?.search || '')} state={{ tryResend: true }}>resend the confirmation email</Link></b>
                        <span>.</span>
                    </>,
                    query.activateErrorTraceID ? <span>If the problem persists, contact support and provide the following <b>{query.activateErrorTraceID}</b></span> : 'If the problem persists, please contact the support team.']
            }]);
        }

        if (autoLogin) {
            setResponseData({ user: location?.state?.user, loginResponse: location?.state?.loginResponse });
        }
    }, [query, location, autoLogin]);

    const appName = useMemo(() => getAppNameByID(appId), [appId]);

    const checkCurrentUser = (samlRequest, appId, relayState) => {
        setIsLoadingCurrentUser(true);
        (async () => {
            try {
                const request = await axios.get('/current-user', { params: { SAMLRequest: samlRequest, appId, RelayState: relayState } });
                if (request.status === 200 && request.data) {
                    // If user not press login button yet, accept current user data
                    setResponseData((previous) => previous === undefined ? request.data : previous);
                    return;
                }
            } catch (err) {
                // Payload Too Large
                if (err?.response?.status === 413){
                    clearCookie();
                }
                logService.error(err);
            }
            setIsLoadingCurrentUser(false);
        })();
    };

    // The user has attempted to login twice and only have one remaining attempt to login before it locks the account
    const lastTryInGeneral = localStorage.getItem('loginAttempt') > 2;
    const lastTryByEmail = remainingTries < 2;
    const isAfterTwoTries = lastTryInGeneral || lastTryByEmail;

    const clearCookie = () => {
        let cookiesOptions = {
            path: '/',
            domain: '.opswat.com',
            expires: 0
        };
        Cookies.set(SESSION_COOKIE, '', cookiesOptions);
        Cookies.set(REFRESH_COOKIE, '', cookiesOptions);
        Cookies.set(PAYLOAD_COOKIE, '', cookiesOptions);
    };

    const onSubmit = useMemo(() => (totp, recovery) => {
        if (!email || !password || (!captchaResponse && isAfterTwoTries && !isInQA)) {
            return;
        }

        // Set response data to 'false', to discard checkCurrentUser result
        setResponseData(false);
        setIsLoading(true);
        setErrors([]);

        (async () => {
            try {
                const request = await axios.post('/login' + (isInQA ? '?inQA=true' : ''), { SAMLRequest: samlRequest, appId, email, password, totp, recovery, RelayState: relayState, captchaToken: captchaResponse });
                if (request.status === 200 && request.data) {
                    setResponseData(request.data);
                }
                localStorage.removeItem('loginAttempt');
            } catch (err) {
                logService.error(err);
                let title = 'Errors';
                const messages = [];
                if (err?.response?.data?.error?.code === ERRORS_CODE.userNotConfirmedException.code) {
                    messages.push(<>
                        <span>{errorMessageMap(err?.response?.data?.error?.code)}</span>
                    </>);
                    messages.push(<>
                        <span>Didn’t get the activation email? Please check your spam folder or </span>
                        <b><Link to={'/confirmEmail' + (location?.search || '')} state={{ email, tryResend: true }}>click here</Link></b>
                        <span> to resend the email.</span>
                    </>);
                } else if (err?.response?.data?.error?.code === ERRORS_CODE.passwordResetRequiredException.code) {
                    messages.push(<>
                        <span>We are on a new login system since 5 AM on October 30, 2020 (GMT). If you haven’t, please </span>
                        <b><Link to='/resetPassword' state={{ email }}>reset your password</Link></b>
                        <span>.</span>
                    </>);
                    messages.push(<>
                        <span>If urgent, please </span>
                        <a href='mailto:login-issues@opswat.com'>email</a>
                        <span> or </span>
                        <a href='https://www.opswat.com/support/contact-support'>phone</a>
                        <span>.</span>
                    </>);

                } else if (err?.response?.data?.error?.code === ERRORS_CODE.notAuthorizedException.code) {
                    messages.push('Your login attempt has failed. Make sure the email and password are correct.');
                    if (err?.response?.data?.error?.remains) {
                        const remains = err?.response?.data?.error?.remains;
                        const storedAttemps = parseInt(localStorage.getItem('loginAttempt'));

                        storedAttemps > 1 ? localStorage.setItem('loginAttempt', storedAttemps + 1) : localStorage.setItem('loginAttempt', 2);
                        setRemainingTries(remains);

                        if (remains > 1) {
                            messages.push(<>
                                <span><b>{remains}</b> attempts remaining.</span>
                            </>);
                        } else {
                            messages.push(<>
                                <span><b>{remains}</b> attempt remaining, you can <b>
                                    <Link to='/resetPassword' state={{ email, autoSubmit: true }}>reset your password</Link></b>.
                                </span>
                            </>);
                        }
                    }
                } else if (err?.response?.data?.error?.code === ERRORS_CODE.captchaError.code) {
                    setCaptchaResponse(undefined);
                    setRemainingTries(1);
                    messages.push(<>
                        <span>{ERRORS_CODE.captchaError.message}</span>
                    </>);
                } else if (err?.response?.data?.error?.code === ERRORS_CODE.blockUserError.code) {
                    const minutes = moment.duration(err?.response?.data?.error?.lockTime).humanize();
                    setCaptchaResponse(undefined);
                    setRemainingTries(0);
                    messages.push(<>
                        <span>Too many failed attempts, please try again after <b>{minutes}</b>.</span>
                    </>);
                } else if (err?.response?.data?.error?.code === ERRORS_CODE.codeMismatchException.code) {
                    messages.push('Your TOTP is incorrect. Please try again.');
                } else if ((err?.response?.status >= 400 && err?.response?.status < 500) || errorMessageMap(err?.response?.data?.error?.code)) {
                    // Payload Too Large
                    if (err?.response?.status === 413){
                        clearCookie();
                    }
                    messages.push(errorMessageMap(err?.response?.data?.error?.code));
                    messages.push(errorTraceID(err, true));
                } else {
                    title = 'Oups';
                    messages.push(NETWORK_ERROR_MESSAGE);
                }

                setErrors([{
                    title,
                    error: err,
                    messages
                }]);
            }
            if (recaptchaRef?.current) {
                recaptchaRef.current.reset();
            }
            setIsLoading(false);
        })();
    }, [appId, email, password, samlRequest, relayState, location, captchaResponse, isAfterTwoTries, isInQA]);

    const isWhiteListed = (url) => {
        const domain = process.env.REACT_APP_ENVIRONMENT === 'local' ? url?.replace('http://', '').replace('https://', '').split(/[/?#]/)[0] : url?.replace('https://', '').split(/[/?#]/)[0];
        const currentWhitelist = process.env.REACT_APP_redirect_whitelist_urls.split(',');

        if (currentWhitelist.includes(domain)) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (responseData) {
            if (responseData.user) {
                if (responseData.user.challengeName === 'TOTP') {
                    setIsTotpChallenge(true);
                } else {
                    const appName = getAppNameByID(appId);
                    if (responseData.loginResponse?.context && responseData.loginResponse?.entityEndpoint) {
                        if (responseData.user.consent && appName && Object.keys(productData).includes(appName) && !responseData.user.consent[appName] && productData[appName].termsUrl && productData[appName].policyUrl) {
                            // If consent of login App isn't agreed by user yet
                            navigate('/consent', { state: { appId, samlRequest, relayState, appName, consent: responseData.user.consent } });
                        } else {
                            // If login success with SAMLRequest from App
                            setSamlResponse(responseData.loginResponse.context);
                            setEntityEndpoint(responseData.loginResponse.entityEndpoint);
                        }
                        return;
                    }

                    if (autoLogin) {
                        window.location.href = `${process.env.REACT_APP_app_href_MA}/console/saml/login`;
                    } else if (query.redirect && isWhiteListed(query.redirect)) {
                        window.location.href = query.redirect;
                    } else if (responseData.user.isMfaForced || process.env.REACT_APP_ENVIRONMENT === 'local') {
                        // If user should be setting MFA before login app, or local env
                        window.location.href = '/profile';
                    } else if (query.app === process.env.REACT_APP_appID_SKL && !query.redirect) {
                        window.location.href = process.env.REACT_APP_app_href_SKL;
                    } else {
                        // If not login from any App, redirect to My OPSWAT as default app for Staging or Production environment
                        if (['staging', 'prod'].includes(process.env.REACT_APP_ENVIRONMENT)) {
                            window.location.href = `${process.env.REACT_APP_app_href_OCM}/`;
                        } else {
                            // Redirect to the profile page for other environments since there are only Staging and Prod environments for My OPSWAT
                            window.location.href = '/profile';
                        }
                    }
                }
            } else {
                setIsLoadingCurrentUser(false);
            }
        }
    }, [responseData, appId, samlRequest, relayState, query, autoLogin]);

    const onCheckEmail = (email, href) => {
        setIsLoading(true);
        (async () => {
            try {
                const response = await axios.post('/IDP/lookup', { email, location: href });
                if (response.status === 200 && response.data) {
                    if (response.data.type === 'HTTP-Redirect' && response.data.location) {
                        // Redirect to IDP
                        navigate(response.data.location);
                    } else if (response.data.type === 'HTTP-POST' && response.data.location && response.data.SAMLRequest) {
                        // Post request to IDP
                        // Set POST endpoint
                        setEntityEndpoint(response.data.location);
                        // Create POST data
                        const requestInputs = [{
                            name: 'SAMLRequest',
                            value: response.data.SAMLRequest
                        }];
                        if (response.data.RelayState) {
                            requestInputs.push({
                                name: 'RelayState',
                                value: response.data.RelayState
                            });
                        }
                        setSamlIdpRequest(requestInputs);
                    }
                    return;
                }
            } catch (error) {
                if (error?.response?.status === 404) {
                    setCheckedEmail(email);
                } else {
                    logService.error(error);
                    // Payload Too Large
                    if (error?.response?.status === 413){
                        clearCookie();
                    }
                    setErrors([{
                        title: 'Oups',
                        error,
                        messages: [NETWORK_ERROR_MESSAGE]
                    }]);
                }
            }
            setIsLoading(false);
        })();
    };

    const emailErrors = useMemo(() => {
        if (email && !Validator.isEmail(email)) {
            return ['Invalid Email Address'];
        }
        return [];
    }, [email]);

    const loginFormDom = useMemo(() => {
        if (!isTotpChallenge) {
            const isCheckedEmail = (checkedEmail && checkedEmail === email);

            return <>
                <div className={classNames({ 'emailInputBox': !isLoading })}>
                    <Input
                        label='Email'
                        name='email'
                        type='text'
                        id='email'
                        onChange={(e) => setEmail(e.target.value)}
                        errors={emailErrors}
                        value={email}
                        autoFocus={!isCheckedEmail}
                        disabled={!!(isLoading || isCheckedEmail)}
                    />
                    {isCheckedEmail && !isLoading && <a href='/' onClick={(e) => {
                        e.preventDefault();
                        setCheckedEmail('');
                    }}>Change</a>}
                </div>
                {isCheckedEmail && <Input
                    label='Password'
                    name='password'
                    type='password'
                    id='password'
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    autoFocus={true}
                    extraAction={{
                        label: 'Forgot password?',
                        to: '/resetPassword' + QueryParametersParser.toString(query),
                        value: email
                    }}
                    disabled={isLoading}
                />}
                { isAfterTwoTries &&
                    <div className='form_group_recaptcha'>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_GRC_SITE_KEY}
                            onChange={(response) => setCaptchaResponse(response)}
                        />
                    </div>
                }
                <Button
                    fullwidth={true}
                    color='primary'
                    disabled={!email || !!emailErrors.length || (!password && isCheckedEmail) || isLoading || (!captchaResponse && isAfterTwoTries && !isInQA)}
                    loading={isLoading}
                    className={'form--button'}
                    onClick={(e) => {
                        e.preventDefault();
                        setErrors([]);
                        if (isCheckedEmail) {
                            // If user should login by our SSO
                            onSubmit();
                        } else {
                            // Check if customer IDP POC or not
                            onCheckEmail(email, location.href);
                        }
                    }}
                >
                    Sign In
                </Button>

                <p className='switchPageText'>Don't have an OPSWAT Account? <Link to={'/register' + (location.search || '')}>Register</Link></p>
            </>;
        }

        let dom;
        if (!isInputRecoveryCode) {
            const onInputRecoveryCodeClick = (e) => {
                e.preventDefault();
                setIsInputRecoveryCode(true);
            };
            dom = <>
                <TotpInput
                    value={totp}
                    onChange={(evt) => {
                        const newTotp = evt.target.value.replace(/[\s_]/g, '');
                        setTotp(newTotp);
                        if (Validator.isValidTotp(newTotp) && newTotp !== totp) {
                            onSubmit(newTotp);
                        }
                    }}
                    disabled={isLoading}
                />
                <p className='helpTotp'>
                    Can't login? <a href='/' onClick={onInputRecoveryCodeClick}>Use recovery code</a>, or <a href='mailto:login-issues@opswat.com'>email</a>, <a href='https://www.opswat.com/support/contact-support'>phone</a> to us.
                </p>
            </>;
        } else {
            const onInputRecoveryCodeClick = (e) => {
                e.preventDefault();
                setIsInputRecoveryCode(false);
            };
            dom = <>
                <TotpInput
                    mask={'*****-*****'}
                    label={'Recovery Code'}
                    value={recoveryCode}
                    onChange={(evt) => {
                        const newRecoveryCode = evt.target.value.replace(/[\s_]/g, '');
                        setRecoveryCode(newRecoveryCode);
                    }}
                    disabled={isLoading}
                />
                <p className='helpTotp'>
                    <a href='/' onClick={onInputRecoveryCodeClick}>Back to TOTP Input</a>.
                </p>
            </>;
        }

        return <>
            {dom}
            <Button
                fullwidth={true}
                color='primary'
                disabled={(!isInputRecoveryCode && !Validator.isValidTotp(totp)) || (isInputRecoveryCode && !Validator.isValidRecoveryCode(recoveryCode)) || isLoading}
                loading={isLoading}
                className={'form--button'}
                onClick={(e) => {
                    e.preventDefault();
                    if (!isInputRecoveryCode) {
                        onSubmit(totp);
                    } else {
                        onSubmit(undefined, recoveryCode);
                    }
                }}
            >
                Submit
            </Button>
        </>;
    }, [onSubmit, email, emailErrors, password, checkedEmail, isLoading, isTotpChallenge, isInputRecoveryCode, totp, recoveryCode, location, query, captchaResponse, isAfterTwoTries, isInQA]);

    const formResponseDom = useMemo(() => {
        let input = undefined;
        if (samlResponse) {
            input = [{ name: 'SAMLResponse', value: samlResponse }];
            if (relayState) {
                input.push({ name: 'RelayState', value: relayState });
            }
        }
        return <FormPost inputs={input} entityEndpoint={entityEndpoint}/>;
    }, [samlResponse, relayState, entityEndpoint]);

    const formRequestDom = useMemo(() => <FormPost inputs={samlIdpRequest} entityEndpoint={entityEndpoint}/>, [samlIdpRequest, entityEndpoint]);

    const pageContent = useMemo(() => {
        if (isLoadingCurrentUser) {
            return <LoadingLayout title={'OPSWAT Accounts | Login'}>
                {formResponseDom}
            </LoadingLayout>;
        }

        return <FormLayout className='login' errors={errors} info={errors?.length ? [] : info} app={appName} pageTitle='OPSWAT Accounts | Login'>
            {loginFormDom}
            {formResponseDom}
            {formRequestDom}
        </FormLayout>;
    }, [appName, formResponseDom, formRequestDom, errors, info, loginFormDom, isLoadingCurrentUser]);

    if (useMARedirect(location?.search)) {
        return null;
    }

    return (
        <>{pageContent}</>
    );
};

export default LoginPage;

LoginPage.propTypes = {
    location: PropTypes.object
};
