// Add new property here when adding new supported app
// Refer to https://opswat.atlassian.net/wiki/spaces/MON/pages/1231257718/SSO+Add+a+new+App

import metaAccessLogo from '../assets/images/product-logos/MetaDefender_Access-IT.svg';
import metadefenderCloudLogo from '../assets/images/product-logos/MetaDefender_Cloud.svg';
import opswatAcademyLogo from '../assets/images/product-logos/Opswat_Academy.png';
import opswatDefaultLogo from '../assets/images/logo.svg';
import myOpswatLogo from '../assets/images/product-logos/MyOPSWAT.svg';

export default {
    MA: {
        name: 'OPSWAT MetaDefender IT Access',
        shortname: 'MetaDefender IT Access',
        termsUrl: 'https://onlinehelp.opswat.com/policies/OPSWAT%2C_INC._TERMS_OF_SERVICE.html',
        policyUrl: 'https://onlinehelp.opswat.com/policies/MetaAccess_Privacy_Policy.html',
        info: [
            'Register to enable the Free Product Trial, this will allow you full access to the product for up to 50 devices.',
            'After registering we will send a confirmation email and then you are able to start using the product. Additional help can be found via our Video Setup Guides.'
        ],
        logo: <img className='customLogo' src={metaAccessLogo} alt={'MetaDefender IT Access'} />,
        ...(process.env.REACT_APP_customHosts_MA && { customHosts: process.env.REACT_APP_customHosts_MA }),
        ...(process.env.REACT_APP_signin_MA && { signin: process.env.REACT_APP_signin_MA })
    },

    MDC: {
        name: 'OPSWAT Metadefender Cloud',
        shortname: 'Metadefender Cloud',
        termsUrl: 'https://onlinehelp.opswat.com/policies/OPSWAT%2C_INC._TERMS_OF_SERVICE.html',
        policyUrl: 'https://onlinehelp.opswat.com/policies/MetaDefender_Cloud_Privacy_Policy.html',
        info: [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        ],
        logo: <img className='customLogo' src={metadefenderCloudLogo} alt={'Metadefender Cloud'} />,
        ...(process.env.REACT_APP_customHosts_MDC && { customHosts: process.env.REACT_APP_customHosts_MDC }),
        ...(process.env.REACT_APP_signin_MDC && { signin: process.env.REACT_APP_signin_MDC })
    },

    SKL: {
        name: 'OPSWAT Academy',
        shortname: 'Academy',
        termsUrl: 'https://docs.opswat.com/policies/terms-of-service/opswat--inc--terms-of-service',
        policyUrl: 'https://docs.opswat.com/policies/privacy-policy/opswat-academy-privacy-policy',
        logo: <img className='customLogo' src={opswatAcademyLogo} alt={'Academy'} />,
        ...(process.env.REACT_APP_customHosts_SKL && { customHosts: process.env.REACT_APP_customHosts_SKL }),
        ...(process.env.REACT_APP_signin_SKL && { signin: process.env.REACT_APP_signin_SKL })

    },

    Allbound: {
        name: 'OPSWAT Allbound',
        shortname: 'Allbound',
        logo: <>
            <img className='originLogo' src={opswatDefaultLogo} alt={'OPSWAT Logo'} />
            <span className='productLogoTitle'>Allbound</span>
        </>,
        ...(process.env.REACT_APP_customHosts_Allbound && { customHosts: process.env.REACT_APP_customHosts_Allbound }),
        ...(process.env.REACT_APP_signin_Allbound && { signin: process.env.REACT_APP_signin_Allbound })

    },

    OCM: {
        name: 'OPSWAT OCM',
        shortname: 'My OPSWAT',
        termsUrl: 'https://docs.opswat.com/policies/terms-of-service/opswat--inc--terms-of-service',
        policyUrl: 'https://docs.opswat.com/policies/v1.0/privacy-policy/my-opswat-privacy-policy',
        logo: <img className='customLogo invertBrightLogo' src={myOpswatLogo} alt={'My OPSWAT'} />,
        ...(process.env.REACT_APP_customHosts_OCM && { customHosts: process.env.REACT_APP_customHosts_OCM }),
        ...(process.env.REACT_APP_signin_OCM && { signin: process.env.REACT_APP_signin_OCM })
    },

    MyOPSWAT: {
        name: 'My OPSWAT',
        shortname: 'MyOPSWAT',
        termsUrl: 'https://docs.opswat.com/policies/terms-of-service/opswat--inc--terms-of-service',
        policyUrl: 'https://docs.opswat.com/policies/v1.0/privacy-policy/my-opswat-privacy-policy',
        logo: <img className='customLogo invertBrightLogo' src={myOpswatLogo} alt={'My OPSWAT'} />,
        ...(process.env.REACT_APP_customHosts_MyOPSWAT && { customHosts: process.env.REACT_APP_customHosts_MyOPSWAT }),
        ...(process.env.REACT_APP_signin_MyOPSWAT && { signin: process.env.REACT_APP_signin_MyOPSWAT })
    },

    OESISCloud: {
        name: 'OESIS Cloud',
        shortname: 'OESIS Cloud',
        termsUrl: 'https://docs.opswat.com/policies/terms-of-service/opswat--inc--terms-of-service',
        policyUrl: 'https://docs.opswat.com/policies/privacy-policy/central-management-privacy-policy',
        logo: <img className='customLogo' src={myOpswatLogo} alt={'OESIS Cloud'} />,
        ...(process.env.REACT_APP_customHosts_OESISCloud && { customHosts: process.env.REACT_APP_customHosts_OESISCloud }),
        ...(process.env.REACT_APP_signin_OESISCloud && { signin: process.env.REACT_APP_signin_OESISCloud })

    },

    OPSWATStore: {
        name: 'OPSWAT Store',
        shortname: 'OPSWAT Store',
        termsUrl: 'https://docs.opswat.com/policies/terms-of-service/opswat--inc--terms-of-service',
        policyUrl: 'https://docs.opswat.com/policies/privacy-policy/central-management-privacy-policy',
        logo: <img className='customLogo' src={myOpswatLogo} alt={'OPSWAT Store'} />,
        ...(process.env.REACT_APP_customHosts_OPSWATStore && { customHosts: process.env.REACT_APP_customHosts_OPSWATStore }),
        ...(process.env.REACT_APP_signin_OPSWATStore && { signin: process.env.REACT_APP_signin_OPSWATStore })

    },

    AS: {
        name: 'Activation Server',
        shortname: 'AS',
        termsUrl: 'https://docs.opswat.com/policies/terms-of-service/opswat--inc--terms-of-service',
        policyUrl: 'https://docs.opswat.com/policies/privacy-policy/central-management-privacy-policy',
        logo: <img className='customLogo' src={myOpswatLogo} alt={'AS'} />,
        ...(process.env.REACT_APP_customHosts_AS && { customHosts: process.env.REACT_APP_customHosts_AS }),
        ...(process.env.REACT_APP_signin_AS && { signin: process.env.REACT_APP_signin_AS })

    },

    MDGateway: {
        name: 'Metadefender Gateway',
        shortname: 'MetaDefender Cloud Email Security',
        termsUrl: 'https://docs.opswat.com/policies/terms-of-service/opswat--inc--terms-of-service',
        policyUrl: 'https://docs.opswat.com/policies/privacy-policy/central-management-privacy-policy',
        logo: <img className='customLogo' src={myOpswatLogo} alt={'MDGateway'} />,
        ...(process.env.REACT_APP_customHosts_MDGateway && { customHosts: process.env.REACT_APP_customHosts_MDGateway }),
        ...(process.env.REACT_APP_signin_MDGateway && { signin: process.env.REACT_APP_signin_MDGateway })

    }
};
