import { useMemo, useState, useRef, useEffect } from 'react';
import { Form } from 'react-bulma-components';
import className from 'classnames';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';

import './input.scss';

const CustomInput = (props) => {
    const { inputClass, label, name, autoComplete, placeholder, type, componentType = 'input', showPasswordMeter, checked, value, onChange, readOnly, errors, warnings, isRequired, extraAction, disabled, autoFocus, id } = props;
    const { Field, Control, Label, Input, Checkbox } = Form;
    const [inputType, setInputType] = useState(type);
    const inputRef = useRef(null);

    const revealPasswordClassName = className({
        'revealed': inputType === 'text'
    }, 'reveal');

    const errorsWrapperClassName = className({
        'errors--wrapper-active': (typeof errors !== 'undefined' && errors?.length > 0) || (typeof warnings !== 'undefined' && warnings?.length > 0),
    }, 'errors--wrapper');

    const fieldClassName = className({
        'input--custom-error': typeof errors !== 'undefined' && errors?.length > 0
    }, 'input--custom');

    const errorsDom = useMemo(() => {
        if (errors?.length) {
            return errors.map((error, index) => {
                return <li key={index}>
                    {error}
                </li>;
            });
        } else if (warnings?.length) {
            return warnings.map((warning, index) => {
                return <li key={index} className='is-warning'>
                    {warning}
                </li>;
            });
        }

    }, [errors, warnings]);

    const revealPassword = (e) => {
        e.preventDefault();
        if (inputType === 'password') {
            setInputType('text');
        } else {
            setInputType('password');
        }
        if (inputRef?.current) {
            inputRef.current.focus();
        }
    };

    const extraActionDom = useMemo(() => {
        if (!extraAction) {
            return null;
        }

        return <Link to={extraAction?.to} state={{ email: extraAction?.value }} className='input--custom-reset-password'>
            {extraAction?.label}
        </Link>;
    }, [extraAction]);

    const revealPasswordDom = type === 'password' ? <span className={revealPasswordClassName} onClick={revealPassword}/> : '';

    const passwordMeter = useMemo(() => {
        if (!showPasswordMeter || !value) {
            return null;
        }

        let meters = ['strong', 'strong', 'strong'];
        if (errors?.length) {
            meters = ['week', 'none', 'none'];
        } else if (warnings?.length) {
            meters = ['medium', 'medium', 'none'];
        }

        return <div className='passwordMeter'>
            {meters.map((meter, index) => <div key={index} className={meter}/>)}
        </div>;
    }, [showPasswordMeter, errors, warnings, value]);

    useEffect(() => {
        if (autoFocus) {
            if (inputRef?.current) {
                inputRef.current.focus();
            }
        }
    });

    const inputTypeDom = <>
        {label && <Label id={id || name} className={className('input--label', { required: isRequired })}>{label}</Label>}
        <Input aria-labelledby={id || name} domRef={inputRef} className={inputClass} disabled={disabled} placeholder={placeholder || ''} name={name} type={inputType} value={value} onChange={onChange} readOnly={!onChange || readOnly} required={isRequired} onKeyPress={onChange} autoComplete={autoComplete || name}/>
        {revealPasswordDom}
        {passwordMeter}
        {
            errors ?
                <ul className={errorsWrapperClassName}>
                    {errorsDom}
                </ul> :
                ''
        }
    </>;

    const checkboxTypeDom = <>
        <Checkbox className={inputClass} name={name} checked={checked} onChange={onChange} disabled={!!readOnly}>
            <span dangerouslySetInnerHTML={{ __html: label }}/>
        </Checkbox>
    </>;

    const innerField = componentType === 'input' ? inputTypeDom : checkboxTypeDom;

    return <Field className={fieldClassName}>
        <Control>
            {innerField}
        </Control>
        {extraActionDom}
    </Field>;
};

export default CustomInput;

CustomInput.propTypes = {
    inputClass: PropTypes.string,
    label: PropTypes.node,
    name: PropTypes.string,
    autoComplete: PropTypes.any,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    componentType: PropTypes.string,
    showPasswordMeter: PropTypes.bool,
    checked: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    errors: PropTypes.array,
    warnings: PropTypes.array,
    isRequired: PropTypes.bool,
    extraAction: PropTypes.object,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    id: PropTypes.string
};
