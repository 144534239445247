/* eslint-disable no-useless-escape */
import { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import { Button } from 'react-bulma-components';
import ReCAPTCHA from 'react-google-recaptcha';
import productData from '../../services/productData';
import Input from '../../components/input/input';
import { axios } from 'services/axios';
import FormLayout from '../../components/layout/form-layout/formLayout';
import { logService } from '../../services/LogService';
import { Link, navigate } from '@reach/router';
import QueryParametersParser from '../../services/QueryParametersParser';
import { getAppNameByID } from '../../services/utility';
import Validator from 'services/Validator';
import { errorMessageMap, NETWORK_ERROR_MESSAGE } from '../../services/Constant';
import PropTypes from 'prop-types';
import useMARedirect, { MA_PAGE } from 'hooks/useMARedirect';

import './register.scss';

const HUBSPOT_QUERY_PARAMS = [
    {
        app: process.env.REACT_APP_appID_SKL,
        allowedParams: ['academy-skilljar', 'academy-hubsspotlp', null]
    }
];

const REGISTER_FIELDS = [
    { label: 'First Name', name: 'firstName', type: 'text', isRequired: true, validation: (value) => Validator.checkName(value) },
    { label: 'Last Name', name: 'lastName', type: 'text', isRequired: true, validation: (value) => Validator.checkName(value) },
    {
        label: 'Email', name: 'email', type: 'email', isRequired: true, validation: (value) => {
            let errors = [];
            if (!Validator.isEmail(value)) {
                return [...errors, 'Invalid Email Address'];
            }

            return errors;
        }
    },
    {
        label: 'Password', name: 'password', type: 'password', isRequired: true, showPasswordMeter: false, validation: (value) => Validator.checkPassword(value)
    },
    {
        label: 'Confirm Password', name: 'passwordConfirmation', type: 'password', isRequired: true, validation: (value, passwordValue) => {
            let errors = [];
            if (value !== passwordValue) {
                errors.push('Passwords do not match');
            }

            return errors || null;
        }
    },
    { label: 'Company Name', name: 'companyName', type: 'text', isRequired: false, validation: (value) => value ? Validator.checkCompany(value) : [] }
];

const useIsMountedRef = () => {
    const isMountedRef = useRef(null);
    useEffect(() => {
        isMountedRef.current = true;
        return () => isMountedRef.current = false;
    });
    return isMountedRef;
};

const RegisterPage = ({ location }) => {
    const [fieldData, setFieldData] = useState({
        email: '',
        password: '',
        passwordConfirmation: '',
        firstName: '',
        lastName: '',
        companyName: '',
        emailCheckbox: false,
    });
    const [consentAgree, setConsentAgree] = useState('');
    const [captchaResponse, setCaptchaResponse] = useState();
    const recaptchaRef = useRef(null);
    const isMountedRef = useIsMountedRef();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [fieldErrors, setFieldErrors] = useState({
        email: [],
        password: [],
        passwordConfirmation: [],
        firstName: [],
        lastName: []
    });

    const query = useMemo(() => QueryParametersParser.parse(location?.search), [location]);
    const appId = useMemo(() => query?.app, [query]);
    const appName = useMemo(() => getAppNameByID(appId), [appId]);
    const isInQA = useMemo(() => query?.inQA === 'true' && (process.env.REACT_APP_ENVIRONMENT === 'dev' || process.env.REACT_APP_ENVIRONMENT === 'qa'), [query]);

    const onChange = useCallback((evt) => {
        setErrors([]);
        const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
        const currentInput = REGISTER_FIELDS.filter((field) => field.name === evt.target.name)[0];
        const passwordConfirmationInput = REGISTER_FIELDS.filter((field) => field.name === 'passwordConfirmation')[0];
        const isPasswordConfirmation = evt.target.name === 'passwordConfirmation';
        const currentInputErrors = !isPasswordConfirmation ? currentInput?.validation(value) : passwordConfirmationInput?.validation(value, fieldData['password']);
        const passwordConfirmationErrors = evt.target.name === 'password' && fieldData['passwordConfirmation'].length ? passwordConfirmationInput?.validation(fieldData['passwordConfirmation'], value) : isPasswordConfirmation ? currentInputErrors : fieldErrors['passwordConfirmation'];

        if (evt.target.type !== 'checkbox') {
            setFieldErrors({ ...fieldErrors, [evt.target.name]: currentInputErrors, 'passwordConfirmation': passwordConfirmationErrors });
        }

        setFieldData({ ...fieldData, [evt.target.name]: value });
    }, [fieldData, fieldErrors]);

    const consentDom = useMemo(() => {
        const productKeys = Object.keys(productData);

        const label = 'I agree to the OPSWAT Inc. <a href=\'https://www.opswat.com/legal\'>Terms of Service and Privacy Policy</a>, unless my organization has a separate written agreement with OPSWAT Inc., in which case those separate terms shall apply.*';
        return <>
            <Input
                label={`${label}`}
                name={appName}
                checked={!!consentAgree}
                componentType='checkbox'
                onChange={() => setConsentAgree(productKeys)}
                id={appName}
            />
        </>;

    }, [appName, consentAgree]);

    const isValid = useMemo(() => {
        const isError = Object.entries(errors).filter((error) => error[1].length !== 0) && Object.entries(fieldErrors).filter((error) => error[1]?.length !== 0);
        if (!isError.length) {
            const {
                email,
                password,
                passwordConfirmation,
                firstName,
                lastName,
            } = fieldData;

            return (email && password && passwordConfirmation && firstName && lastName && (consentAgree || !consentDom) && (captchaResponse || isInQA));
        }

        return false;
    }, [fieldData, consentAgree, consentDom, errors, fieldErrors, captchaResponse, isInQA]);

    const onSubmit = useCallback((e) => {
        e && e.preventDefault();
        if (!isValid) {
            return;
        }

        setIsLoading(true);
        setErrors([]);

        const { email, password, firstName, lastName, companyName, emailCheckbox } = fieldData;
        (async () => {
            try {
                const leadSourceParam = query?.leadsource?.toLowerCase()?.trim() || null;
                const isLeadSourceParamAllowed = HUBSPOT_QUERY_PARAMS.filter((param) => param.app === query?.app && param.allowedParams.includes(leadSourceParam)).length ? true : false;

                const data = {
                    email,
                    password,
                    firstName,
                    lastName,
                    companyName,
                    consent: {},
                    emailRegister: emailCheckbox,
                    captchaToken: captchaResponse,
                    query,
                    leadSourceParam: isLeadSourceParamAllowed ? leadSourceParam : undefined
                };

                // eslint-disable-next-line no-extra-boolean-cast
                if (!!consentAgree) {
                    // Map keys to build array with pair [appName, true] representing consent
                    const productsConsent = Object.keys(productData).map((key) => [key, true]);

                    // Build object from [[appName1, true], [appName2, true], ...]
                    data.consent = Object.fromEntries(productsConsent);

                    // Delete OESISCloud for now
                    delete data.consent.OESISCloud;
                }
                const request = await axios.post('/register' + (isInQA ? '?inQA=true' : ''), data);

                if (request.status === 200) {
                    await axios.get('/logout');
                    // Thank form
                    await navigate('/confirmEmail' + QueryParametersParser.toString(query), { state: { email } });
                }
            } catch (err) {
                logService.error(err);
                let messages, title;
                if ((err?.response?.status >= 400 && err?.response?.status < 500) || errorMessageMap(err?.response?.data?.error?.code)) {
                    title = 'Errors';
                    messages = ['Your register attempt has failed. Please try again.'];
                } else {
                    title = 'Oups';
                    messages = [NETWORK_ERROR_MESSAGE];
                }

                messages.push(errorMessageMap(err?.response?.data?.error?.code));

                setErrors([{
                    title,
                    error: err,
                    messages
                }]);
            }

            if (recaptchaRef?.current) {
                recaptchaRef.current.reset();
            }

            if (isMountedRef?.current) {
                setCaptchaResponse(undefined);
                setIsLoading(false);
            }
        })();
    }, [captchaResponse, consentAgree, fieldData, isInQA, isValid, query]);

    const registerFormDom = useMemo(() => <>
        <div className='register--form-columns'>
            {REGISTER_FIELDS.slice(0, 2).map((field, index) => <div key={index} className='register--form-column'>
                <Input key={index}
                    label={field.label}
                    name={field.name}
                    type={field.type}
                    onChange={onChange}
                    value={fieldData[field.name]}
                    errors={fieldErrors[field.name]}
                    isRequired={field.isRequired}
                    showPasswordMeter={field.showPasswordMeter}
                    id={field.name}
                />
            </div>)}
        </div>
        {REGISTER_FIELDS.slice(2).map((field, index) => (
            <Input key={index}
                label={field.label}
                name={field.name}
                type={field.type}
                onChange={onChange}
                value={fieldData[field.name]}
                errors={fieldErrors[field.name]}
                isRequired={field.isRequired}
                showPasswordMeter={field.showPasswordMeter}
                id={field.name}
            />
        )
        )}

        <div className='register--form-checkboxes'>
            {consentDom}
            <Input
                label={'Yes, I would like to receive email communications from OPSWAT.'}
                name={'emailCheckbox'}
                checked={fieldData['emailCheckbox']}
                componentType='checkbox'
                onChange={onChange}
                id='emailCheckbox'
            />
        </div>
        <div className='register--form-captcha'>
            <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_GRC_SITE_KEY}
                onChange={(response) => setCaptchaResponse(response)}
            />
        </div>
        <Button
            fullwidth={true}
            color='primary'
            disabled={!isValid || isLoading}
            className={`form--button ${isLoading && 'is-loading'}`}
            onClick={onSubmit}
        >
            Sign Up
        </Button>
        <p className='switchPageText'>Already have an OPSWAT Account? <Link to={'/login' + (location.search || '')}>Sign in instead</Link></p>

        <p className='register--form-description mt-0'>All the fields marked with “*” are mandatory.</p>
    </>, [consentDom, fieldData, fieldErrors, isLoading, isValid, onChange, onSubmit, location]);

    if (useMARedirect(location?.search, MA_PAGE.REGISTER)) {
        return null;
    }

    return (
        <FormLayout className='register' errors={errors} app={appName} pageTitle='OPSWAT Accounts | Register'>
            {registerFormDom}
        </FormLayout>
    );
};

export default RegisterPage;

RegisterPage.propTypes = {
    location: PropTypes.object
};
