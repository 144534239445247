import PropTypes from 'prop-types';
import './addEntry.scss';

const AddEntry = ({ inputsDom, buttonsDom }) => {
    return <tr className='addNewEntry'>
        <td colSpan={100}>
            <div className='addNewEntryWrapper'>
                <div className='addNewEntryInputs'>
                    {inputsDom}
                </div>
                <div className='addNewEntryButtons'>
                    {buttonsDom}
                </div>
            </div>
        </td>
    </tr>;
};

export default AddEntry;

AddEntry.propTypes = {
    inputsDom: PropTypes.node,
    buttonsDom: PropTypes.node
};
