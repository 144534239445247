import { useEffect, useMemo, useState } from 'react';
import { Button, Columns, Heading } from 'react-bulma-components';
import className from 'classnames';
import Input from '../../input/input';
import ContentLoader from 'react-content-loader';
import { axios } from '../../../services/axios';
import { navigate } from '@reach/router';
import Validator from '../../../services/Validator';
import ProfileSelection from './profileSelection';
import { logService } from '../../../services/LogService';
import { profileService } from '../../../services/profileService';
import SimpleMessages from '../../common/simple-messages/simpleMessages';
import { ERRORS_CODE } from '../../../services/Constant';
import productData from '../../../services/productData';

import './profileInfo.scss';

const INPUT_FIELDS = [
    {
        name: 'firstName',
        label: 'First Name',
        validate: (value) => (!!value && typeof (value) === 'string' && !Validator.checkName(value.trim()).length)
    },
    {
        name: 'lastName',
        label: 'Last Name',
        validate: (value) => (!!value && typeof (value) === 'string' && !Validator.checkName(value.trim()).length)
    },
    {
        name: 'email',
        label: 'Email',
        readonly: true
    },
    {
        name: 'customerSupportId',
        label: 'Customer Support ID',
        validate: (value) => !value || (typeof (value) === 'string' && /^[\d\w-.]*$/.test(value) && value.trim().length <= 50)
    },
    {
        name: 'phoneNumber',
        label: 'Phone',
        validate: (value) => !value || (typeof (value) === 'string' && Validator.isValidPhoneNumber(value)),
        placeholder: 'eg: +14325551212',
        parse: (value) => Validator.makeUpPhoneNumber(value)
    },
    {
        name: 'jobRole',
        label: 'Job Role',
        validate: (value) => !value || (typeof (value) === 'string' && !Validator.checkName(value.trim()).length)
    },
    {
        name: 'company',
        label: 'Company',
        validate: (value) => !value || (typeof (value) === 'string' && !Validator.checkCompany(value.trim()).length)
    },
];

const CHECKBOX_FIELDS = [
    {
        name: 'useCases',
    },
    {
        name: 'privacy',
    }
];

const ProfileInfo = () => {
    const [fieldData, setFieldData] = useState();
    const [validChange, setValidChange] = useState(false);
    const [isChange, setIsChange] = useState(false);
    const [saving, setSaving] = useState(false);
    const [saveResult, setSaveResult] = useState();

    const [userInfo, setUserInfo] = useState();
    const userUpdate = (info) => setUserInfo(info['user']);

    const consentData = ((obj) => {
        const data = obj;

        // temporary deletion of OESIS Cloud consent
        if (data.OESISCloud) {
            delete data.OESISCloud;
        }
        return data;
    })(productData);

    useEffect(() => {
        userUpdate(profileService.getInfo());
        profileService.registerUpdateEvent(userUpdate);
        return () => profileService.unregisterUpdateEvent(userUpdate);
    }, []);

    useEffect(() => {
        if (userInfo) {
            const data = { ...userInfo };
            INPUT_FIELDS.forEach((field) => data['init-' + field.name] = userInfo[field.name] || '');
            CHECKBOX_FIELDS.forEach((field) => {
                if (!userInfo[field.name]) {
                    data[field.name] = {};
                }
                data['init-' + field.name] = { ...data[field.name] };
            });
            setFieldData(data);
        }
    }, [userInfo]);

    useEffect(() => {
        if (fieldData) {
            checkChangeAndValid(fieldData);
        }
    }, [fieldData]);

    const onChange = (evt) => {
        const value = evt.target.value;
        const field = INPUT_FIELDS.find((f) => f.name === evt.target.name);
        if (!field || field.readonly) {
            return;
        }
        const data = {
            ...fieldData,
            [evt.target.name]: value, // Current Value
            [`change-${evt.target.name}`]: value.trim() !== fieldData[`init-${evt.target.name}`], // Is value change or not?
            [`invalid-${evt.target.name}`]: !field.validate(value) // Valid value
        };
        setFieldData(data);
    };

    const handlePrivacyChange = (evt) => {
        const checked = evt.target.checked;

        const data = { ...fieldData };

        data['change-privacy'] = true;
        const privacy = Object.fromEntries(Object.keys(consentData).map((key) => [key, checked]));
        data['privacy'] = privacy;
        setFieldData(data);
    };

    const checkChangeAndValid = (data) => {
        // Check if it is valid to execute change process
        // All value input is valid
        const isValid = Object.keys(data).every((key) => {
            if (key.startsWith('invalid-')) {
                return !data[key];
            }
            return true;
        });
        // And there is at least one value is changed
        const isChange = Object.keys(data).some((key) => {
            if (key.startsWith('change-')) {
                return data[key];
            }
            return false;
        });
        setIsChange(isChange);
        setValidChange(isValid && isChange);
        if (isChange) {
            setSaveResult(undefined);
        }
    };

    const onDiscard = () => {
        const data = {
            ...fieldData,
        };
        Object.keys(data).forEach((key) => {
            if (key.startsWith('init-')) {
                data[key.replace('init-', '')] = data[key];
            } else if (key.startsWith('change-') || key.startsWith('invalid-')) {
                data[key] = false;
            }
        });
        setFieldData(data);
        setIsChange(false);
        setValidChange(false);
    };

    const onSave = () => {
        setSaving(true);
        setSaveResult(undefined);
        (async () => {
            try {
                const changedData = {};
                Object.keys(fieldData).forEach((key) => {
                    if (key.startsWith('change-') && fieldData[key]) {
                        const dataKey = key.replace('change-', '');
                        const field = INPUT_FIELDS.find((f) => f.name === dataKey);
                        let value = field && field.parse ? field.parse(fieldData[dataKey]) : fieldData[dataKey];
                        value = typeof value === 'string' ? value.trim() : value;
                        changedData[dataKey] = value;
                    }
                });
                const response = await axios.post('/profile', changedData);
                if (response?.status === 200) {
                    // Update done
                    const data = {
                        ...fieldData,
                    };
                    // Setup new value
                    Object.keys(data).forEach((key) => {
                        if (key.startsWith('init-')) {
                            data[key] = data[key.replace('init-', '')];
                        } else if (key.startsWith('change-') || key.startsWith('invalid-')) {
                            data[key] = false;
                        }
                    });
                    setIsChange(false);
                    setValidChange(false);
                    setSaveResult(true);
                    // Update to trigger events
                    profileService.updateInfo('user', data);
                }
            } catch (err) {
                if (err?.response?.data?.error?.code === ERRORS_CODE.sessionError.code) {
                    await navigate('/login?redirect=' + encodeURIComponent(window.location.href));
                } else {
                    logService.error(err);
                    setSaveResult(false);
                }
            }

            setSaving(false);
        })();
    };

    const saveResultDOM = useMemo(() => {
        if (saveResult === undefined) {
            return null;
        }

        return <SimpleMessages messageList={[saveResult ? 'Saved.' : 'Error occurred, please try again.']} type={saveResult ? 'success' : 'danger'} />;
    }, [saveResult]);

    const inputLoader = () => <ContentLoader
        height={68}
        width='100%'
        speed={1}
    >
        <rect x='0' y='10' rx='10' ry='10' width={`${20 + Math.random() * 80}%`} height='40' />
    </ContentLoader>;

    return <div className='profileInfo'>
        <Heading renderAs={'h1'} weight={'normal'}>Profile Information</Heading>
        <Columns breakpoint='tablet' variableGap={{ mobile: 0, desktop: 8 }}>
            {INPUT_FIELDS.slice(0, 2).map((field, index) => <Columns.Column key={index} className='is-half'>
                <Heading subtitle id={field.name} renderAs={'h2'} size={6}>{field.label}</Heading>
                {fieldData && <Input
                    name={field.name}
                    id={field.name}
                    inputClass={className({ change: fieldData[`change-${field.name}`], invalid: fieldData[`invalid-${field.name}`] })}
                    value={fieldData[field.name]}
                    onChange={onChange}
                    readOnly={saving}
                />}
                {!fieldData && inputLoader()}
            </Columns.Column>)}
        </Columns>
        {INPUT_FIELDS.slice(2, 7).map((field, index) => <div key={index}>
            <Heading subtitle id={field.name} renderAs={'h2'} size={6}>{field.label}</Heading>
            {fieldData && <Input
                name={field.name}
                id={field.name}
                inputClass={className({ change: fieldData[`change-${field.name}`], invalid: fieldData[`invalid-${field.name}`] })}
                value={fieldData[field.name]}
                onChange={field.readonly ? undefined : onChange}
                readOnly={saving}
                placeholder={field.placeholder}
            />}
            {!fieldData && inputLoader()}
        </div>)}
        <ProfileSelection fieldData={fieldData} handlePrivacyChange={handlePrivacyChange} saving={saving} consentData={consentData} />
        <div className='subscriptions'>
            <Heading subtitle renderAs={'h2'} size={6}>Make Changes to Your Email Subscriptions</Heading>
            <p>
                To update your marketing email subscriptions from OPSWAT, such as our monthly newsletter and product updates,
                please visit <a href='https://www.opswat.com/connect'>OPSWAT Connect</a>.
            </p>
        </div>
        {saveResultDOM}
        <div className='buttons'>
            <Button className='positive' color='info' disabled={!validChange || saving} loading={saving} onClick={onSave}>
                Save changes
            </Button>
            <Button className='negative' disabled={!isChange || saving} onClick={onDiscard}>
                Discard changes
            </Button>
        </div>
    </div>;
};

export default ProfileInfo;
